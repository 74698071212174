import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxMarked({ className }: Props) {
  return (
    <IconBase name="checkbox-marked" w={24} className={className}>
      <rect x="3" y="3" width="18" height="18" rx="4" fill="currentColor" stroke="none"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2071 8.79289C17.5976 9.18342 17.5976 9.81658 17.2071 10.2071L11.1274 16.2868L7.44528 13.8321C6.98576 13.5257 6.86158 12.9048 7.16793 12.4453C7.47429 11.9858 8.09516 11.8616 8.55468 12.1679L10.8726 13.7132L15.7929 8.79289C16.1834 8.40237 16.8166 8.40237 17.2071 8.79289Z" fill="white" stroke="none"/>
    </IconBase>
  )
}

export default CheckboxMarked
