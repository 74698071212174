import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Lock({ className }: Props) {
  return (
    <IconBase name="lock" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 8H17C18.6568 8 20 9.34315 20 11V19C20 20.6569 18.6568 22 17 22H7C5.34315 22 4 20.6569 4 19V11C4 9.34315 5.34315 8 7 8H8V6.11C8 3.90086 9.79086 2.11 12 2.11C14.2091 2.11 16 3.90086 16 6.11V8ZM12 4C10.8661 4.0327 9.972 4.97596 10 6.11V8H14V6.11C14.028 4.97596 13.1339 4.03269 12 4ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V11C6 10.4477 6.44772 10 7 10H17C17.5523 10 18 10.4477 18 11V19ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z" stroke="none"/>
    </IconBase>
  )
}

export default Lock
