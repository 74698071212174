import React, { useState } from 'react';
import { FormError } from '../';
import { RadioUnmarked, RadioMarked, RadioHover } from '../Icons';
import styles from './RadioButton.module.sass'

interface OptionProps {
  id: string;
  label?: string;
  name?: string;
  value?: any;
  checked?: boolean;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

interface Props {
  id: string;
  name?: string;
  value?: any;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  status?: '' | 'success' | 'error';
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: OptionProps[];
}

const FormRadioOption = (props: OptionProps) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onChange = undefined,
  } = props;

  const [hover, setHover] = useState(false)

  const classNames = `
    ${styles.option}
    ${disabled ? styles['option--disabled'] : ''}
    ${className || ''}`;

  return (
    <div className={classNames}>
      <label htmlFor={id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={styles['input-wrap']}>
        <input
          id={id}
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={styles.styledinput}>
          {checked ? <RadioMarked /> : hover && !disabled && !readOnly ? <RadioHover /> : <RadioUnmarked />}
        </span>
        <span className={styles.label}>{label}</span>
      </label>
    </div>
  );
};

const FormRadio = (props: Props) => {
  const {
    id = '',
    name = '',
    value = null,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = undefined,
    options = [],
  } = props;

  const onOptionClick = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly && onChange) onChange(e);
  };

  const classNames = `
    ${styles['form-radio']}
    ${disabled ? styles['form-radio--disabled'] : ''}
    ${status ? styles[`form-radio--${status}`] : ''}
    ${className || ''}`;

  return (
    <div className={classNames}>
      <div className={styles.options}>
        {options?.map((option) => (
          <FormRadioOption
            id={`${id}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={option.value === value}
            onChange={onOptionClick}
            readOnly={readOnly || option.readOnly}
            disabled={disabled || option.disabled}
            key={`formradiooption-${id}-${option.value}`}
          />
        ))}
      </div>
      <FormError
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  );
};

export default FormRadio;
