export function menu(
    state = {
      fetching: true,
      menu: null,
    },
    // @ts-ignore
    action
  ) {
    switch (action.type) {
      case 'SET_MENU': {
        return {
          ...state,
          fetching: false,
          menu: action.payload.data?.menuitems,
        };
      }
      default: {
        return state;
      }
    }
  }
  