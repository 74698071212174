import React from 'react'
import styles from './SimpleMessage.module.sass'


interface Props {
  text: string
  className?: string
}

const SimpleMessage = (props: Props) => {

  const { 
    text = '',
    className = '',
  } = props

  return (
    <div className={`${styles.simpleMessage} ${className ? ` ${className}` : ''}`} dangerouslySetInnerHTML={{ __html: text }} />
  )
}

export default SimpleMessage
