import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Impresa({ idVariant = '', className }: Props) {
  return (
    <IconBase name="impresa" w={32} className={className}>
      <path d="M23.53 6.48H8.47C6.69164 6.48 5.25 7.92164 5.25 9.7V22.3C5.25 24.0784 6.69164 25.52 8.47 25.52H23.53C25.3084 25.52 26.75 24.0784 26.75 22.3V9.7C26.75 7.92164 25.3084 6.48 23.53 6.48Z" fill={`url(#paint0_linear_3746_46153_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.5 21.23V15.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M11.5 21.23V13.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M14.5 21.23V10.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M17.5 21.23V12.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M20.5 21.23V16.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M23.5 21.23V13.77" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46153_${idVariant}`} x1="3.7774" y1="7.33698" x2="26.1734" y2="27.5859" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Impresa
