import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function MenuRight({ className }: Props) {
  return (
    <IconBase name="menu-right" w={24} className={className}>
      <path d="M4 7H20" strokeWidth="2" strokeLinecap="round" fill="none"/>
      <path d="M8 17H20" strokeWidth="2" strokeLinecap="round" fill="none"/>
    </IconBase>
  )
}

export default MenuRight
