import React from 'react'
import { Add } from '@next-nx/shared-ui/icons'
import { FormError } from '@next-nx/shared-ui/atoms'
import styles from './FormFile.module.sass'


interface Props {id: string;
  label?: string
  name?: string
  value?: any
  accept?: string
  multiple?: boolean
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const FormFile = (props: Props) => {

  const { 
    id = '',
    label = '',
    name = '',
    value = '',
    accept = undefined,
    multiple = false,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
  } = props

  const classNames = `
    ${styles.formFile}
    ${disabled ? styles['formFile--disabled'] : ''}
    ${status ? styles[`formFile--${status}`] : ''}
    ${className ? `${className}` : ''}`;

  return (
    <div className={classNames}>
      <label htmlFor={id}>
        <input
          value={value}
          name={name}
          accept={accept}
          multiple={multiple}
          className={styles.input}
          type="file"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
        />
        <span className={styles.styledinput}>
          <Add />
        </span>
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormFile
