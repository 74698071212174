import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Infortuni({ idVariant = '', className }: Props) {
  return (
    <IconBase name="infortuni" w={32} className={className}>
      <path d="M16 14.5C18.8056 14.5 21.08 12.2256 21.08 9.42003C21.08 6.61442 18.8056 4.34003 16 4.34003C13.1944 4.34003 10.92 6.61442 10.92 9.42003C10.92 12.2256 13.1944 14.5 16 14.5Z" fill={`url(#paint0_linear_3746_46159_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M8.12 24.57V27.66H23.88V24.57C23.88 22.4801 23.0498 20.4758 21.572 18.998C20.0942 17.5202 18.0899 16.69 16 16.69C13.9101 16.69 11.9058 17.5202 10.428 18.998C8.95021 20.4758 8.12 22.4801 8.12 24.57V24.57Z" fill={`url(#paint1_linear_3746_46159_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M19.64 22.81H13.96C12.7615 22.81 11.79 23.7815 11.79 24.98V25.49C11.79 26.6885 12.7615 27.66 13.96 27.66H19.64C20.8384 27.66 21.81 26.6885 21.81 25.49V24.98C21.81 23.7815 20.8384 22.81 19.64 22.81Z" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M23.88 27.66V24.57C23.8765 23.4267 23.6243 22.2977 23.1407 21.2617C22.6572 20.2256 21.954 19.3071 21.08 18.57L16.92 27.69L23.88 27.66Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46159_${idVariant}`} x1="10.2241" y1="4.79733" x2="21.9449" y2="14.1819" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3746_46159_${idVariant}`} x1="7.04054" y1="17.1838" x2="19.8836" y2="31.9571" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Infortuni
