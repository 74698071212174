import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function MessageSuccess({ className }: Props) {
  return (
    <IconBase name="message-success" w={24} className={className}>
      <circle cx="12" cy="12" r="10" stroke="none" fill="#E0F2F2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.2072 7.79289C18.5977 8.18342 18.5977 8.81658 18.2072 9.20711L10.5829 16.8313L6.38611 13.7894C5.95016 13.4503 5.87162 12.822 6.21069 12.3861C6.54976 11.9501 7.17804 11.8716 7.61399 12.2106L10.4172 14.1687L16.7929 7.79289C17.1835 7.40237 17.8166 7.40237 18.2072 7.79289Z" stroke="none" fill="#009597" />
    </IconBase>
  )
}

export default MessageSuccess
