import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowUp({ className }: Props) {
  return (
    <IconBase name="arrow-up" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5 19C11.5 19.5523 11.9477 20 12.5 20C13.0523 20 13.5 19.5523 13.5 19L13.5 6.41422L19.2929 12.2071C19.6834 12.5976 20.3166 12.5976 20.7071 12.2071C21.0976 11.8166 21.0976 11.1834 20.7071 10.7929L12.5 2.58579L4.29289 10.7929C3.90237 11.1834 3.90237 11.8166 4.29289 12.2071C4.68342 12.5976 5.31658 12.5976 5.70711 12.2071L11.5 6.41421L11.5 19Z" stroke="none"/>
    </IconBase>
  )
}

export default ArrowUp
