import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Rinnovo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="rinnovo" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3746_46171_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M21.46 18.22C20.9425 19.4839 20.0026 20.5291 18.8006 21.1773C17.5985 21.8256 16.2088 22.0367 14.8684 21.7748C13.5281 21.5129 12.3201 20.7941 11.4505 19.741C10.581 18.6879 10.1037 17.3657 10.1 16" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.57 13.71C11.1015 12.4594 12.0483 11.4306 13.2506 10.7973C14.4529 10.1641 15.837 9.9653 17.1689 10.2345C18.5008 10.5037 19.699 11.2244 20.561 12.2749C21.423 13.3253 21.896 14.6412 21.9 16" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M23.36 15.27L21.9 16.73L20.43 15.27" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M8.64001 16.73L10.1 15.27L11.57 16.73" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46171_${idVariant}`} x1="3.91383" y1="6.3269" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Rinnovo
