import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Profile({ className }: Props) {
  return (
    <IconBase name="profile" w={24} className={className}>
      <path d="M17.9588 16.504C16.5465 15.8241 14.3499 15 12 15C9.65008 15 7.45355 15.8241 6.04122 16.504C4.9956 17.0074 4.36302 18.0495 4.22131 19.2013L4 21H20L19.7787 19.2013C19.637 18.0495 19.0044 17.0074 17.9588 16.504Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Profile
