import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Calendario({ idVariant = '', className }: Props) {
  return (
    <IconBase name="calendario" w={32} className={className}>
      <path d="M6.65002 21.57V10.13C6.66055 9.29621 6.99917 8.50015 7.59252 7.91429C8.18587 7.32842 8.98617 6.99993 9.82002 7H22.18C23.019 7 23.8238 7.3326 24.418 7.92493C25.0122 8.51726 25.3474 9.321 25.35 10.16V24.36C25.3474 25.199 25.0122 26.0027 24.418 26.5951C23.8238 27.1874 23.019 27.52 22.18 27.52H12.88C12.1009 27.5194 11.3494 27.2309 10.77 26.71L7.70002 23.93C7.37062 23.6312 7.10711 23.267 6.92632 22.8607C6.74553 22.4543 6.65143 22.0147 6.65002 21.57V21.57Z" fill={`url(#paint0_linear_3746_46168_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.59 10.75C11.8827 10.75 12.12 10.5216 12.12 10.24C12.12 9.95832 11.8827 9.72998 11.59 9.72998C11.2973 9.72998 11.06 9.95832 11.06 10.24C11.06 10.5216 11.2973 10.75 11.59 10.75Z" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M20.41 10.75C20.7027 10.75 20.94 10.5216 20.94 10.24C20.94 9.95832 20.7027 9.72998 20.41 9.72998C20.1173 9.72998 19.88 9.95832 19.88 10.24C19.88 10.5216 20.1173 10.75 20.41 10.75Z" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M14 18C14.1754 17.6873 14.4181 17.4174 14.7105 17.2099C15.0029 17.0025 15.3378 16.8626 15.6909 16.8004C16.0441 16.7382 16.4066 16.7552 16.7523 16.8503C17.098 16.9453 17.4183 17.116 17.69 17.35C19.9 19.35 14.58 20.91 13.69 23.16H18.23" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M6.65002 13.17H25.35" stroke="#009597" strokeMiterlimit="10" fill="none"/>
      <path d="M11.59 10.24V4.51001" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M20.41 10.24V4.51001" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46168_${idVariant}`} x1="5.3692" y1="7.9236" x2="28.4718" y2="24.7807" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Calendario
