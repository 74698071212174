import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function RadioUnmarked({ className }: Props) {
  return (
    <IconBase name="radio-unmarked" w={24} className={className}>
      <circle cx="12" cy="12" r="10" fill="#EFF1F4" stroke="none" />
    </IconBase>
  )
}

export default RadioUnmarked
