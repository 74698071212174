import React, { useState } from 'react'
import Image from 'next/legacy/image'

interface Props {
  src?: string
  alt?: string
  title?: string
  className?: string
  maxWidth: number
  maxHeight: number
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive'
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  priority?: false | true
  loading?: 'lazy' | 'eager'
}

const WImage = (props: Props) => {
  const {
    src = '',
    alt = '',
    title = '',
    maxWidth = 0,
    maxHeight = 0,
    className = '',
    layout = undefined,
    objectFit = undefined,
    priority = false,
    loading = 'lazy',
  } = props

  const [imgSrc, setImgSrc] = useState(src)
  const fallbackImage = `http://via.placeholder.com/${maxWidth}x${maxHeight}`

  return layout ? (
    <Image
      src={imgSrc || fallbackImage}
      alt={alt}
      title={title}
      layout={layout}
      width={layout !== 'fill' ? maxWidth : undefined}
      height={layout !== 'fill' ? maxHeight : undefined}
      objectFit={objectFit}
      priority={priority}
      loading={loading}
      onError={() => {
        setImgSrc(fallbackImage)
      }}
    />
  ) : (
    <img src={src || fallbackImage} alt={alt} title={title} className={className} />
  )
}

export default WImage
