import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function FilePdf({ idVariant = '', className }: Props) {
  return (
    <IconBase name="file-pdf" w={32} className={className}>
      <mask id={`path-1-inside-1_2078_7771_${idVariant}`} fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3072 3.55472C22.935 3.1987 22.4398 3 21.9248 3H8C6.89543 3 6 3.89543 6 5V27C6 28.1046 6.89543 29 8 29H27C28.1046 29 29 28.1046 29 27V9.85458C29 9.30873 28.7769 8.7866 28.3824 8.4093L23.3072 3.55472Z" stroke="none" fill="none"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3072 3.55472C22.935 3.1987 22.4398 3 21.9248 3H8C6.89543 3 6 3.89543 6 5V27C6 28.1046 6.89543 29 8 29H27C28.1046 29 29 28.1046 29 27V9.85458C29 9.30873 28.7769 8.7866 28.3824 8.4093L23.3072 3.55472Z" stroke="none" fill="none" fill={`url(#paint0_linear_2078_7771_${idVariant})`}/>
      <path d="M28.3824 8.4093L27.6912 9.13194L28.3824 8.4093ZM23.3072 3.55472L22.616 4.27736L23.3072 3.55472ZM8 4H21.9248V2H8V4ZM7 27V5H5V27H7ZM27 28H8V30H27V28ZM28 9.85458V27H30V9.85458H28ZM29.0737 7.68665L23.9984 2.83207L22.616 4.27736L27.6912 9.13194L29.0737 7.68665ZM30 9.85458C30 9.03581 29.6653 8.25261 29.0737 7.68665L27.6912 9.13194C27.8884 9.32059 28 9.58166 28 9.85458H30ZM27 30C28.6569 30 30 28.6569 30 27H28C28 27.5523 27.5523 28 27 28V30ZM5 27C5 28.6569 6.34314 30 8 30V28C7.44772 28 7 27.5523 7 27H5ZM21.9248 4C22.1823 4 22.4299 4.09935 22.616 4.27736L23.9984 2.83207C23.4401 2.29805 22.6973 2 21.9248 2V4ZM8 2C6.34315 2 5 3.34315 5 5H7C7 4.44772 7.44772 4 8 4V2Z" fill="#009597" mask={`url(#path-1-inside-1_2078_7771_${idVariant})`} stroke="none"/>
      <rect x="3" y="8" width="23" height="10" rx="5" fill="#ED1B2F" stroke="none"/>
      <path d="M8.27747 12.8235H8.6978C9.09066 12.8235 9.38462 12.7469 9.57967 12.5937C9.77473 12.4378 9.87225 12.212 9.87225 11.9166C9.87225 11.6183 9.78984 11.3981 9.625 11.2558C9.46291 11.1135 9.20742 11.0424 8.85852 11.0424H8.27747V12.8235ZM11.1621 11.8714C11.1621 12.5171 10.9588 13.0109 10.5522 13.3529C10.1484 13.6949 9.5728 13.8659 8.82555 13.8659H8.27747V16H7V10H8.92445C9.65522 10 10.2102 10.1573 10.5893 10.472C10.9712 10.7839 11.1621 11.2503 11.1621 11.8714Z" fill="white" stroke="none"/>
      <path d="M17.2981 12.9425C17.2981 13.9302 17.0151 14.6867 16.4492 15.212C15.886 15.7373 15.0714 16 14.0055 16H12.2995V10H14.1909C15.1745 10 15.9382 10.2585 16.4821 10.7756C17.0261 11.2927 17.2981 12.015 17.2981 12.9425ZM15.9712 12.9754C15.9712 11.6867 15.3997 11.0424 14.2569 11.0424H13.5769V14.9494H14.125C15.3558 14.9494 15.9712 14.2914 15.9712 12.9754Z" fill="white" stroke="none"/>
      <path d="M19.8036 16H18.5467V10H22V11.0424H19.8036V12.5896H21.8475V13.6279H19.8036V16Z" fill="white" stroke="none"/>
      <defs>
      <linearGradient id={`paint0_linear_2078_7771_${idVariant}`} x1="27.3571" y1="3" x2="6.89759" y2="26.5706" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B2DBDD" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default FilePdf
