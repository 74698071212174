import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'


export const dateFormat = (valore: string) => dayjs(valore, 'YYYY-MM-DD').format('DD/MM/YYYY')
export const datePickerFormat = (valore: string | Date) => dayjs(valore).format("YYYY-MM-DD")
export const numberFormat = (valore: number, rimuoviDecimali?: boolean) => valore ? valore.toFixed(rimuoviDecimali ? 0 : 2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
export const priceFormat = (valore: number, rimuoviDecimali?: boolean) => numberFormat(valore, rimuoviDecimali) + " €"
export const roundNumber = (valore: number, decimali: number = 2) => parseFloat(valore.toFixed(decimali))
export const monthYearFormat = (valore: string, locale?: string|undefined) => {
  return dayjs(valore, 'YYYY-MM-DD')
    .toDate()
    .toLocaleDateString(locale, { year: 'numeric', month: 'long' });
}
export const subtractMonths = (date: Date, valore: number) => {
  return dayjs(date).subtract(valore, "month").toDate()
}

export const isDateValid = (valore: string, format: string) => {
  dayjs.extend(customParseFormat)
  return dayjs(valore, format, true).isValid()
} 

export const getFormFieldArgs = (
  name: string, 
  values: any, 
  errors: any, 
  touched: any,
  space?: "lg" | "md" | "sm" | "none" | "xl" | undefined
): { 
  space?: "lg" | "md" | "sm" | "none" | "xl" | undefined
  status?: '' | 'success' | 'error' | undefined
  errorMessage?: string
} => ({
  space: space || 'lg',
  status: touched[name] && errors[name] ? 'error' : '',
  errorMessage: errors[name]
})

export const isEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const scrollTo = (ref: any, toolbar: boolean, offset: number) => {
  const y = ref.offsetTop - (toolbar ? 80 : 0) - (offset || 0) - 80 // 80 =  altezza header sia desktop che mobile
  window.scrollTo({
    top: y, 
    behavior: 'smooth'
  });
}


declare global {
  interface String {
    toGTMFormat() : string
    capitalize() : string
    replaceAt(index: number, replacement: string) : string
  }
}

String.prototype.toGTMFormat = function() {
  return this.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, '_').toLowerCase()
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.replaceAt = function(index, replacement) {
  return this.substring(0, index) + replacement + this.substring(index + replacement.length)
}