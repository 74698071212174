import React, { useEffect } from 'react'
import { Close } from '@next-nx/shared-ui/icons'
import styles from './Modal.module.sass'

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  closeButton?: boolean
  onCloseClick?: (e?: any) => void
  className?: string
  overlayClassName?: string
  buttons?: any
  children?: any
}

const Modal = (props: Props) => {
  const {
    open = false,
    setOpen = () => undefined,
    closeButton = false,
    onCloseClick = () => undefined,
    className = '',
    overlayClassName = '',
    buttons = undefined,
    children = null,
  } = props

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const close = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return open ? (
    <>
      <div className={`${styles.overlay} ${overlayClassName}`} />
      <div className={`${styles.modal} ${className ? ` ${className}` : ''}`}>
        {closeButton && (
          <button
            type="button"
            className={styles.close}
            onClick={(e) => {
              close(e)
              onCloseClick()
            }}
          >
            <Close />
          </button>
        )}
        <div className={styles.body}>{children}</div>
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>
    </>
  ) : (
    <></>
  )
}

export default Modal
