import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Mail({ className }: Props) {
  return (
    <IconBase name="mail" w={26} h={24} className={className}>
      <path d="M1 6C1 4.89543 1.89543 4 3 4H23C24.1046 4 25 4.89543 25 6V19C25 20.1046 24.1046 21 23 21H3C1.89543 21 1 20.1046 1 19V6Z" strokeWidth="2" strokeLinejoin="round" fill="none"/>
      <path d="M2.42131 5.30287C1.91709 4.84067 2.24409 4 2.9281 4H23.0719C23.7559 4 24.0829 4.84067 23.5787 5.30287L15.0272 13.1418C13.8802 14.1931 12.1198 14.1931 10.9728 13.1418L2.42131 5.30287Z" strokeWidth="2" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Mail
