import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Profilo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="profilo" w={32} className={className}>
      <path d="M16 15C18.7614 15 21 12.7614 21 10C21 7.23858 18.7614 5 16 5C13.2386 5 11 7.23858 11 10C11 12.7614 13.2386 15 16 15Z" fill={`url(#paint0_linear_4163_80153_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M24 26H8C8 23.8783 8.84285 21.8434 10.3431 20.3431C11.8434 18.8429 13.8783 18 16 18C18.1217 18 20.1566 18.8429 21.6569 20.3431C23.1571 21.8434 24 23.8783 24 26Z" fill={`url(#paint1_linear_4163_80153_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_4163_80153_${idVariant}`} x1="10.3151" y1="5.4501" x2="21.8513" y2="14.6869" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_4163_80153_${idVariant}`} x1="6.90411" y1="18.3601" x2="15.4025" y2="31.969" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Profilo
