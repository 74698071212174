import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ProtezioneMezziDiPagamento({ idVariant = '', className }: Props) {
  return (
    <IconBase name="protezione-mezzi-di-pagamento" w={32} className={className}>
      <path d="M19.94 5.00999H12.06C10.4639 5.00999 9.16998 6.30389 9.16998 7.89999V24.09C9.16998 25.6861 10.4639 26.98 12.06 26.98H19.94C21.5361 26.98 22.83 25.6861 22.83 24.09V7.89999C22.83 6.30389 21.5361 5.00999 19.94 5.00999Z" fill={`url(#paint0_linear_3744_46148_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 18.2C18.14 17.54 19.46 14.97 19.33 12.35C18.1313 12.1258 16.9952 11.6448 16 10.94C15.0047 11.6448 13.8687 12.1258 12.67 12.35C12.54 15 13.86 17.54 16 18.2Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.59 22.79H15.41C14.8908 22.79 14.47 23.2086 14.47 23.725C14.47 24.2414 14.8908 24.66 15.41 24.66H16.59C17.1091 24.66 17.53 24.2414 17.53 23.725C17.53 23.2086 17.1091 22.79 16.59 22.79Z" fill="#009597" stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3744_46148_${idVariant}`} x1="8.23437" y1="5.99886" x2="28.9591" y2="16.3162" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default ProtezioneMezziDiPagamento
