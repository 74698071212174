import React from 'react';
import {
  BootstrapOverlayTrigger,
  BootstrapTooltip,
} from '@next-nx/shared-ui/atoms';

export interface Props {
  label: any;
  id: string;
  className?: string;
  pos?: 'top' | 'right' | 'bottom' | 'left';
  size?: string;
  fullWidth?: boolean;
  autoWidth?: boolean;
  children?: any;
}

const Tooltip = (mainProps: Props) => {
  const {
    label = '',
    id = '',
    pos = 'top',
    size = '',
    className = '',
    fullWidth = false,
    autoWidth = false,
    children = null,
  } = mainProps;

  const renderTooltip = (props: any) => (
    <BootstrapTooltip
      id={id}
      className={`tooltip ${size ? `tooltip--${size}` : ''} ${
        className ? ` ${className}` : ''
      }`}
      {...props}
    >
      {label}
    </BootstrapTooltip>
  );

  return (
    <BootstrapOverlayTrigger
      placement={pos}
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
    >
      <span
        className={`tooltip-container ${
          fullWidth ? 'tooltip-container__full' : ''
        } ${autoWidth ? 'tooltip-container__auto' : ''}`}
      >
        {children}
      </span>
    </BootstrapOverlayTrigger>
  );
};

export default Tooltip;
