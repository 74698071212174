import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function SocialLinkedin({ className }: Props) {
  return (
    <IconBase name="social-linkedin" w={40} className={className}>
      <path d="M15.4647 14.4825H22.49V17.4124C22.6805 17.1811 22.7549 17.1011 22.8179 17.0123C24.8055 14.209 27.5707 13.5093 30.7325 14.1232C33.8356 14.7255 35.6929 16.7723 36.5105 19.7967C36.792 20.823 36.945 21.8811 36.9659 22.9462C37.0189 27.165 36.9931 31.3837 36.9945 35.5952C36.9945 35.7131 36.9788 35.8309 36.9674 35.9953H29.9506V35.2286C29.9506 31.6907 29.9664 28.1528 29.9377 24.609C29.9368 23.8102 29.8406 23.0145 29.6513 22.2392C29.007 19.7094 26.2246 18.7478 24.1525 20.3161C23.6244 20.6963 23.197 21.2033 22.9082 21.7918C22.6194 22.3804 22.4782 23.0323 22.4972 23.6896C22.5058 27.5185 22.4972 31.3474 22.4972 35.1821V36.0011H15.4647V14.4825Z" stroke="none"/>
      <path d="M4.50153 14.4796H11.4595V35.988H4.50153V14.4796Z" stroke="none"/>
      <path d="M7.95821 11.4718C5.17873 11.4718 3.39162 9.15291 4.19209 6.57947C4.82646 4.54283 7.23363 3.48086 9.49759 4.25188C9.95635 4.40763 10.38 4.65468 10.7437 4.97858C11.1075 5.30249 11.404 5.69677 11.6161 6.1384C11.8282 6.58004 11.9516 7.06017 11.979 7.55077C12.0064 8.04137 11.9374 8.53261 11.7759 8.9958C11.2489 10.548 9.82838 11.4718 7.95821 11.4718Z" stroke="none"/>
    </IconBase>
  )
}

export default SocialLinkedin
