import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Help({ className }: Props) {
  return (
    <IconBase name="help" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V10.1213C13 10.4187 12.8819 10.7039 12.6716 10.9142L11.2929 12.2929C10.9024 12.6834 10.9024 13.3166 11.2929 13.7071C11.6834 14.0976 12.3166 14.0976 12.7071 13.7071L14.0858 12.3284C14.6711 11.7431 15 10.9491 15 10.1213V10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10V10.5C9 11.0523 9.44772 11.5 10 11.5C10.5523 11.5 11 11.0523 11 10.5V10Z" stroke="none"/>
    </IconBase>
  )
}

export default Help
