import React from 'react';
import { FormError } from '../';
import { Check, Error } from '../Icons';
import styles from './FormInput.module.sass'

interface Props {
  type?: 'text' | 'number' | 'email' | 'tel';
  placeholder?: string;
  name?: string;
  value?: string | number;
  step?: string | number;
  min?: string | number;
  size?: 'lg' | 'md';
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  status?: '' | 'success' | 'error';
  bgVariant?: '' | 'white'
  errorMessage?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = (props: Props) => {
  const {
    type = 'text',
    placeholder = '',
    name = '',
    value = '',
    step = '',
    min = '',
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    bgVariant = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
  } = props;

  const classNames = `
    ${styles.formInput}
    ${bgVariant ? styles[`formInput--bg-${bgVariant}`] : ''}
    ${size ? styles[`formInput--${size}`] : ''}
    ${status ? styles[`formInput--${status}`] : ''}
    ${className || ''}`;

  const localRightIcon = status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const optional = {
    ...(!!min && { min: min }),
    ...(!!step && { step:step }),
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        <input
          type={type}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          autoComplete="off"
          {...optional}
        />
      </div>
      <FormError
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  );
};

export default FormInput;
