import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Kasko({ idVariant = '', className }: Props) {
  return (
    <IconBase name="kasko" w={32} className={className}>
      <path d="M16.22 28.3199C23.47 26.0799 27.95 17.3699 27.5 8.45993C20.33 6.96993 16.22 3.67993 16.22 3.67993C16.22 3.67993 12.1 6.95993 4.94001 8.45993C4.49001 17.3599 8.97001 26.0799 16.22 28.3199Z" fill={`url(#paint0_linear_4967_100478_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.22 25.8599C22.02 24.0699 25.61 17.0999 25.25 9.9699C19.52 8.7799 16.22 6.1499 16.22 6.1499C16.22 6.1499 12.93 8.7799 7.19 9.9699C6.83 17.0999 10.42 24.0699 16.22 25.8599Z" stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.22 11.1599C13.55 11.1599 11.38 13.3299 11.38 15.9999C11.38 18.6699 13.55 20.8399 16.22 20.8399C18.89 20.8399 21.06 18.6699 21.06 15.9999C21.06 13.3299 18.89 11.1599 16.22 11.1599Z" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M16.22 14.7998C15.56 14.7998 15.02 15.3398 15.02 15.9998C15.02 16.6598 15.56 17.1998 16.22 17.1998C16.88 17.1998 17.42 16.6598 17.42 15.9998C17.42 15.3398 16.88 14.7998 16.22 14.7998Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M11.38 16H21.06" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M16.22 20.84V16" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <defs>
      <linearGradient id={`paint0_linear_4967_100478_${idVariant}`} x1="3.35954" y1="4.78897" x2="31.163" y2="25.2274" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Kasko
