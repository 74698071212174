import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Casa({ idVariant = '', className }: Props) {
  return (
    <IconBase name="casa" w={32} className={className}>
      <path d="M24.45 15.48H26.86C26.9047 15.479 26.9482 15.465 26.9851 15.4396C27.0219 15.4143 27.0506 15.3787 27.0676 15.3374C27.0846 15.296 27.0891 15.2505 27.0807 15.2066C27.0722 15.1626 27.0511 15.1221 27.02 15.09L16.16 4.23C16.1394 4.20821 16.1146 4.19085 16.0871 4.17898C16.0596 4.16712 16.03 4.161 16 4.161C15.97 4.161 15.9404 4.16712 15.9129 4.17898C15.8854 4.19085 15.8606 4.20821 15.84 4.23L4.99999 15.09C4.96733 15.1207 4.94484 15.1608 4.93555 15.2046C4.92626 15.2485 4.93062 15.2942 4.94803 15.3356C4.96544 15.3769 4.99507 15.4119 5.03296 15.436C5.07084 15.46 5.11517 15.4719 5.15999 15.47H7.55999V26.14C7.55999 26.3632 7.60397 26.5843 7.6894 26.7906C7.77483 26.9968 7.90005 27.1842 8.05791 27.3421C8.21577 27.4999 8.40318 27.6252 8.60943 27.7106C8.81569 27.796 9.03675 27.84 9.25999 27.84H22.75C23.1991 27.8374 23.629 27.6571 23.9456 27.3386C24.2623 27.02 24.44 26.5891 24.44 26.14V15.48H24.45Z" fill={`url(#paint0_linear_3746_46152_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.34 22H14.66C14.2789 22 13.97 22.3089 13.97 22.69V27.15C13.97 27.5311 14.2789 27.84 14.66 27.84H17.34C17.721 27.84 18.03 27.5311 18.03 27.15V22.69C18.03 22.3089 17.721 22 17.34 22Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46152_${idVariant}`} x1="3.41339" y1="5.22678" x2="30.279" y2="25.3522" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Casa
