import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function SupportoLegale({ idVariant = '', className }: Props) {
  return (
    <IconBase name="supporto-legale" w={32} className={className}>
      <path d="M12.87 16.55C12.87 18.78 11.38 20.6 9.54002 20.6C7.70002 20.6 6.21002 18.78 6.21002 16.55H12.87Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M6.21002 16.5499L9.54002 9.09985L12.87 16.5499" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M25.79 16.55C25.79 18.78 24.3 20.6 22.46 20.6C20.62 20.6 19.13 18.78 19.13 16.55H25.79Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M19.13 16.5499L22.46 9.09985L25.79 16.5499" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M16.79 25.1H20.3C20.5652 25.1 20.8196 25.2053 21.0071 25.3928C21.1946 25.5804 21.3 25.8347 21.3 26.1V27.5599C21.3 27.8252 21.1946 28.0795 21.0071 28.2671C20.8196 28.4546 20.5652 28.5599 20.3 28.5599H11.7C11.4348 28.5599 11.1804 28.4546 10.9929 28.2671C10.8054 28.0795 10.7 27.8252 10.7 27.5599V26.09C10.7 25.8247 10.8054 25.5704 10.9929 25.3828C11.1804 25.1953 11.4348 25.09 11.7 25.09H15.23V8.65995H5.56C5.50841 8.66133 5.45708 8.65218 5.40914 8.63307C5.3612 8.61396 5.31765 8.58528 5.28116 8.54879C5.24467 8.51229 5.21599 8.46875 5.19688 8.42081C5.17777 8.37287 5.16862 8.32154 5.17 8.26995V6.81995C5.16862 6.76836 5.17777 6.71703 5.19688 6.66909C5.21599 6.62115 5.24467 6.57761 5.28116 6.54112C5.31765 6.50462 5.3612 6.47595 5.40914 6.45683C5.45708 6.43772 5.50841 6.42857 5.56 6.42995H15.22V3.88995C15.22 3.77326 15.2664 3.66134 15.3489 3.57882C15.4314 3.49631 15.5433 3.44995 15.66 3.44995H16.32C16.4367 3.44995 16.5486 3.49631 16.6311 3.57882C16.7136 3.66134 16.76 3.77326 16.76 3.88995V6.39995H26.4C26.4516 6.39857 26.5029 6.40772 26.5509 6.42683C26.5988 6.44595 26.6423 6.47462 26.6788 6.51112C26.7153 6.54761 26.744 6.59115 26.7631 6.63909C26.7822 6.68703 26.7914 6.73836 26.79 6.78995V8.26995C26.7914 8.32154 26.7822 8.37287 26.7631 8.42081C26.744 8.46875 26.7153 8.51229 26.6788 8.54879C26.6423 8.58528 26.5988 8.61396 26.5509 8.63307C26.5029 8.65218 26.4516 8.66133 26.4 8.65995H16.77V25.1H16.79Z" fill={`url(#paint0_linear_3932_47581_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47581_${idVariant}`} x1="3.68902" y1="4.58015" x2="31.4339" y2="23.7075" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default SupportoLegale
