import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { Check, ChevronDown, Error } from '../Icons';
import { FormError } from '../';
import styles from './FormSelect.module.sass'

interface Option {
  label: string;
  value: number | string;
  selected?: boolean;
}
export interface Props {
  placeholder?: string;
  name?: string;
  value?: number | string;
  size?: 'lg' | 'md' | 'sm';
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string;
  disabled?: boolean;
  status?: null | 'success' | 'error' | string
  variant?: '' | 'dropdown' | 'date'
  errorMessage?: string;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  onChange?: (e: { target: { name: string, value?: number | string, type: string }}) => void;
  options: Option[];
}

const FormSelectDropdownIndicator = () => (
  <div className="react-select__dropdown-indicator">
    <ChevronDown />
  </div>
);

const FormSelect = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = null,
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    disabled = false,
    status = '',
    variant = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
    options = [],
  } = props;

  const classNames = ` 
    ${styles.formSelect}
    ${styles[`formSelect--${size}`]}
    ${variant ? styles[`formSelect--${variant}`] : ''}
    ${status ? styles[`formSelect--${status}`] : ''}
    ${className || ''}`;

  const localRightIcon = status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const reactSelectClassNames = ` 
    react-select
    react-select--${size}
    ${status ? `react-select--${status}` : ''}
    ${leftIcon ? 'react-select--left-icon' : ''}
    ${localRightIcon ? 'react-select--right-icon' : ''}
  `;

  const localOnChange = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (onChange) onChange({
      target: {
        value: newValue?.value,
        name: name,
        type: 'react-select'
      }
    })
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        <Select
          className={reactSelectClassNames}
          classNamePrefix="react-select"
          closeMenuOnSelect
          isClearable={false}
          isSearchable={false}
          isDisabled={disabled || false}
          instanceId={name}
          name={name}
          placeholder={placeholder}
          components={{ DropdownIndicator: FormSelectDropdownIndicator }}
          onChange={localOnChange}
          onFocus={onFocus}
          onBlur={onBlur}
          options={options}
          value={
            options?.find((option) => option.selected) ||
            options?.find((option) => option.value === value)
          }
        />
      </div>
      <FormError
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  );
};

export default FormSelect;
