import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ProtezioneMobile({ idVariant = '', className }: Props) {
  return (
    <IconBase name="protezione-mobile" w={32} className={className}>
      <path d="M24.3 5.37H8.10997C6.51387 5.37 5.21997 6.66389 5.21997 8.26V17.24C5.21997 18.8361 6.51387 20.13 8.10997 20.13H24.3C25.8961 20.13 27.19 18.8361 27.19 17.24V8.26C27.19 6.66389 25.8961 5.37 24.3 5.37Z" fill={`url(#paint0_linear_3744_46147_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.32 20.13H14.08V24.84H18.32V20.13Z" fill={`url(#paint1_linear_3744_46147_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.04 24.84H10.37C9.85084 24.84 9.42999 25.2608 9.42999 25.78V26.26C9.42999 26.7791 9.85084 27.2 10.37 27.2H22.04C22.5591 27.2 22.98 26.7791 22.98 26.26V25.78C22.98 25.2608 22.5591 24.84 22.04 24.84Z" fill={`url(#paint2_linear_3744_46147_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.1999 16.38C18.3399 15.72 19.6599 13.16 19.5299 10.53C18.3312 10.3058 17.1952 9.82479 16.1999 9.12C15.2047 9.82479 14.0687 10.3058 12.8699 10.53C12.7399 13.16 14.0599 15.72 16.1999 16.38Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3744_46147_${idVariant}`} x1="3.71518" y1="6.03434" x2="20.8998" y2="26.515" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3744_46147_${idVariant}`} x1="13.7895" y1="20.342" x2="19.0722" y2="24.1496" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_3744_46147_${idVariant}`} x1="8.50191" y1="24.9462" x2="9.66091" y2="30.2743" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default ProtezioneMobile
