import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Personalizzazione({ idVariant = '', className }: Props) {
  return (
    <IconBase name="personalizzazione" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3746_46163_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M19.06 22.22C19.06 22.22 14.81 9.35997 13.55 9.78997C12.25 10.44 19.06 22.22 19.06 22.22Z" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M18.88 10.14C19.3026 10.1132 19.7254 10.19 20.1116 10.3638C20.4978 10.5376 20.8357 10.8031 21.0959 11.1373C21.3561 11.4714 21.5308 11.864 21.6047 12.281C21.6786 12.698 21.6495 13.1268 21.52 13.53C20.39 16.93 15.87 12.4 12.85 12.78C12.114 12.9129 11.4542 13.3159 11 13.91C10.6216 14.4658 10.4193 15.1226 10.4193 15.795C10.4193 16.4673 10.6216 17.1242 11 17.68C11.3065 18.1057 11.7008 18.4608 12.1562 18.7213C12.6116 18.9818 13.1176 19.1416 13.64 19.19" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46163_${idVariant}`} x1="3.91383" y1="6.3269" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Personalizzazione
