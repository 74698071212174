import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Semplice({ idVariant = '', className }: Props) {
  return (
    <IconBase name="semplice" w={32} className={className}>
      <path d="M13.62 10.6901C15.1719 10.6901 16.43 9.43199 16.43 7.88007C16.43 6.32815 15.1719 5.07007 13.62 5.07007C12.0681 5.07007 10.81 6.32815 10.81 7.88007C10.81 9.43199 12.0681 10.6901 13.62 10.6901Z" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M13.62 12.37C16.0998 12.37 18.11 10.3598 18.11 7.88001C18.11 5.40026 16.0998 3.39001 13.62 3.39001C11.1402 3.39001 9.13 5.40026 9.13 7.88001C9.13 10.3598 11.1402 12.37 13.62 12.37Z" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M13.21 28.6101L12.77 26.2601C12.77 26.2601 9.58999 16.3701 9.19999 15.0001C8.80999 13.6301 8.91999 13.4801 9.80999 13.5501C10.7 13.6201 11.2 14.6501 11.2 14.6501L12.6 17.5201V7.42007C12.5998 7.28444 12.6272 7.15018 12.6806 7.02548C12.734 6.90078 12.8121 6.78823 12.9103 6.69469C13.0086 6.60114 13.1248 6.52856 13.2519 6.48135C13.3791 6.43414 13.5145 6.41329 13.65 6.42007C14.71 6.42007 14.65 7.20007 14.65 7.20007V16.8001C14.65 17.0001 15.12 16.9901 15.12 16.8001V11.8001C15.12 11.8001 15.01 10.8701 16.17 10.8001C17.33 10.7301 17.31 11.6501 17.31 11.6501V16.8001C17.31 17.0901 17.77 17.0301 17.77 16.8001V13.1401C17.77 13.1401 17.77 12.2301 18.91 12.2301C20.05 12.2301 19.99 13.0801 19.99 13.0801V16.8501C19.99 17.0901 20.45 17.0501 20.45 16.8501V15.0601C20.45 15.0601 20.45 14.3001 21.45 14.3101C22.45 14.3201 22.45 14.7801 22.56 15.0001C22.7062 17.4746 22.7062 19.9556 22.56 22.4301C22.35 24.6401 20.63 25.6001 20.27 28.6101H13.21Z" fill={`url(#paint0_linear_3932_47574_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47574_${idVariant}`} x1="8.04899" y1="7.41765" x2="28.8774" y2="17.7008" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Semplice
