import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

const BRAND = '';
const DOMAIN = '';

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, additionalMetaTags = null }) => {
  const { title = '', description = '', image = '' } = seo || {};

  const router = useRouter();

  // TODO: default static image
  const ogImage = image;

  const og = {
    locale: 'it_IT',
    site_name: BRAND,
    url: DOMAIN + router?.asPath,
    title,
    description,
    images: ogImage ? [{ url: DOMAIN + ogImage }] : [],
    type: 'website',
  };

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={additionalMetaTags}
      openGraph={og}
    />
  );
};

export default MetaSeo;
