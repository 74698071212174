import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Auto({ idVariant = '', className }: Props) {
  return (
    <IconBase name="auto" w={32} className={className}>
      <path d="M26.47 10.77V24.77C26.47 25.128 26.3993 25.4826 26.262 25.8132C26.1247 26.1439 25.9235 26.4442 25.6698 26.6969C25.4162 26.9496 25.1152 27.1497 24.784 27.2858C24.4528 27.4219 24.0981 27.4913 23.74 27.49H8.26003C7.90199 27.4913 7.54722 27.4219 7.21606 27.2858C6.8849 27.1497 6.58387 26.9496 6.33023 26.6969C6.0766 26.4442 5.87535 26.1439 5.73803 25.8132C5.60071 25.4826 5.53003 25.128 5.53003 24.77V7.23C5.53003 6.87196 5.60071 6.51744 5.73803 6.18678C5.87535 5.85612 6.0766 5.55583 6.33023 5.30312C6.58387 5.05042 6.8849 4.85027 7.21606 4.71417C7.54722 4.57806 7.90199 4.50868 8.26003 4.51H19.8C20.4702 4.51226 21.1168 4.75741 21.62 5.2L25.56 8.74C25.8467 8.99471 26.0761 9.30738 26.2329 9.65732C26.3898 10.0073 26.4706 10.3865 26.47 10.77V10.77Z" fill={`url(#paint0_linear_3746_46157_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.07001 21.97H23.93" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 24.19H20.84" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16 8.81C15.0428 8.81 14.107 9.09386 13.3111 9.62568C12.5151 10.1575 11.8948 10.9134 11.5285 11.7978C11.1621 12.6822 11.0663 13.6554 11.253 14.5942C11.4398 15.5331 11.9008 16.3955 12.5776 17.0724C13.2545 17.7493 14.1169 18.2102 15.0558 18.397C15.9947 18.5838 16.9678 18.4879 17.8522 18.1216C18.7366 17.7552 19.4925 17.1349 20.0243 16.339C20.5562 15.543 20.84 14.6073 20.84 13.65C20.84 12.3663 20.3301 11.1353 19.4224 10.2276C18.5148 9.31992 17.2837 8.81 16 8.81V8.81Z" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      <path d="M16 12.45C15.7627 12.45 15.5307 12.5204 15.3334 12.6522C15.136 12.7841 14.9822 12.9715 14.8914 13.1908C14.8006 13.4101 14.7768 13.6513 14.8231 13.8841C14.8694 14.1169 14.9837 14.3307 15.1515 14.4985C15.3193 14.6664 15.5332 14.7807 15.7659 14.827C15.9987 14.8733 16.24 14.8495 16.4593 14.7587C16.6785 14.6678 16.866 14.514 16.9978 14.3167C17.1297 14.1194 17.2 13.8873 17.2 13.65C17.2 13.3318 17.0736 13.0265 16.8486 12.8015C16.6235 12.5764 16.3183 12.45 16 12.45V12.45Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M11.16 13.65H20.84" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      <path d="M16.0001 18.49V13.65" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46157_${idVariant}`} x1="4.09578" y1="5.54431" x2="29.9674" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Auto
