import React, { useState, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FormError } from '../';
import {
  CheckboxUnmarked,
  CheckboxMarked,
  RadioUnmarked,
  RadioMarked,
  RadioHover,
  CheckboxHover,
} from '../Icons';
import styles from './FormCheckbox.module.sass'

interface Props {
  id: string;
  label?: string;
  name?: string;
  value?:
    | (DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      > &
        string)
    | number
    | readonly string[];
  className?: string;
  checked?: boolean;
  radioStyle?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  status?: '' | 'success' | 'error';
  errorMessage?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const FormCheckbox = (props: Props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    className = '',
    checked = false,
    radioStyle = false,
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = undefined,
    onFocus = undefined,
    onChange = undefined,
  } = props;

  const [hover, setHover] = useState(false)

  const classNames = `
    ${styles.formCheckbox}
    ${disabled ? styles['formCheckbox--disabled'] : ''}
    ${status ? styles[`formCheckbox--${status}`] : ''}
    ${className ? `${className}` : ''}`;

  return (
    <div className={classNames}>
      <label htmlFor={id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <input
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
        />
        <span className={styles.styledinput}>
          {radioStyle ? (
            checked ? (
              <RadioMarked />
            ) : (
              hover && !disabled && !readOnly ? <RadioHover /> : <RadioUnmarked />
            )
          ) : checked ? (
            <CheckboxMarked />
          ) : (
            hover && !disabled && !readOnly ? <CheckboxHover /> : <CheckboxUnmarked />
          )}
        </span>
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  );
};

export default FormCheckbox;
