import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Documenti({ idVariant = '', className }: Props) {
  return (
    <IconBase name="documenti" w={32} className={className}>
      <path d="M26.72 9.73002V21.73C26.72 22.2605 26.5093 22.7692 26.1342 23.1442C25.7591 23.5193 25.2504 23.73 24.72 23.73H11.31C10.7796 23.73 10.2709 23.5193 9.89578 23.1442C9.52071 22.7692 9.31 22.2605 9.31 21.73V6.65002C9.31 6.11959 9.52071 5.61088 9.89578 5.23581C10.2709 4.86074 10.7796 4.65002 11.31 4.65002H21.31C21.8022 4.65378 22.2758 4.83893 22.64 5.17002L26.08 8.25002C26.2835 8.43879 26.4456 8.66787 26.5558 8.92267C26.6659 9.17747 26.7219 9.45242 26.72 9.73002V9.73002Z" fill={`url(#paint0_linear_3746_46161_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.42 16.6901H24.61" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.42 14.66H24.61" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.42 12.6301H24.61" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.99 18.73H22.04" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.99 10.6H22.04" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M22.69 13.32V25.38C22.69 25.9105 22.4793 26.4192 22.1042 26.7942C21.7292 27.1693 21.2204 27.38 20.69 27.38H7.26001C6.72958 27.38 6.22087 27.1693 5.8458 26.7942C5.47072 26.4192 5.26001 25.9105 5.26001 25.38V10.22C5.26001 9.6896 5.47072 9.18089 5.8458 8.80582C6.22087 8.43074 6.72958 8.22003 7.26001 8.22003H17.26C17.7475 8.22349 18.2169 8.40485 18.58 8.73003L22 11.85C22.2114 12.0332 22.3821 12.2586 22.5009 12.5119C22.6198 12.7651 22.6842 13.0404 22.69 13.32V13.32Z" fill={`url(#paint1_linear_3746_46161_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.39001 20.3H20.58" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M7.39001 18.26H20.58" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M7.39001 16.23H20.58" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M9.96002 22.33H18.01" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M9.96002 14.2H18.01" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46161_${idVariant}`} x1="8.11753" y1="5.50881" x2="29.6073" y2="21.2093" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3746_46161_${idVariant}`} x1="4.06617" y1="9.08242" x2="25.6261" y2="24.7863" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Documenti
