import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Rate({ idVariant = '', className }: Props) {
  return (
    <IconBase name="rate" w={32} className={className}>
      <path d="M6.65002 21.57V10.13C6.66055 9.29621 6.99917 8.50015 7.59252 7.91429C8.18587 7.32842 8.98617 6.99993 9.82002 7H22.18C23.019 7 23.8238 7.3326 24.418 7.92493C25.0122 8.51726 25.3474 9.321 25.35 10.16V24.36C25.3474 25.199 25.0122 26.0027 24.418 26.5951C23.8238 27.1874 23.019 27.52 22.18 27.52H12.88C12.1009 27.5194 11.3494 27.2309 10.77 26.71L7.70002 23.93C7.37062 23.6312 7.10711 23.267 6.92632 22.8607C6.74553 22.4543 6.65143 22.0147 6.65002 21.57V21.57Z" fill={`url(#paint0_linear_3746_46164_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.59 10.75C11.8827 10.75 12.12 10.5216 12.12 10.24C12.12 9.95832 11.8827 9.72998 11.59 9.72998C11.2973 9.72998 11.06 9.95832 11.06 10.24C11.06 10.5216 11.2973 10.75 11.59 10.75Z" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M20.41 10.75C20.7027 10.75 20.94 10.5216 20.94 10.24C20.94 9.95832 20.7027 9.72998 20.41 9.72998C20.1173 9.72998 19.88 9.95832 19.88 10.24C19.88 10.5216 20.1173 10.75 20.41 10.75Z" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M6.65002 13.17H25.35" stroke="#009597" strokeMiterlimit="10" fill="none"/>
      <path d="M11.59 10.24V4.51001" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M20.41 10.24V4.51001" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M19.09 23.09C18.5763 23.6085 17.9211 23.964 17.2064 24.1119C16.4916 24.2599 15.7491 24.1937 15.0718 23.9217C14.3945 23.6496 13.8125 23.1839 13.3986 22.5827C12.9847 21.9815 12.7573 21.2716 12.745 20.5418C12.7326 19.812 12.9358 19.0948 13.3291 18.4799C13.7223 17.865 14.2882 17.3798 14.9559 17.085C15.6236 16.7902 16.3635 16.6989 17.0828 16.8225C17.8022 16.9461 18.4691 17.2792 19 17.78" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.4901 19.65H17.1401" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.4901 21.28H17.1401" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46164_${idVariant}`} x1="5.3692" y1="7.9236" x2="28.4718" y2="24.7807" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Rate
