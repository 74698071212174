import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Franchigia({ idVariant = '', className }: Props) {
  return (
    <IconBase name="franchigia" w={32} className={className}>
      <path d="M26.47 10.77V24.77C26.47 25.1281 26.3993 25.4826 26.262 25.8132C26.1247 26.1439 25.9235 26.4442 25.6698 26.6969C25.4162 26.9496 25.1152 27.1498 24.784 27.2859C24.4528 27.422 24.0981 27.4913 23.74 27.49H8.26003C7.90199 27.4913 7.54722 27.422 7.21606 27.2859C6.8849 27.1498 6.58387 26.9496 6.33023 26.6969C6.0766 26.4442 5.87535 26.1439 5.73803 25.8132C5.60071 25.4826 5.53003 25.1281 5.53003 24.77V7.23003C5.53003 6.87199 5.60071 6.51747 5.73803 6.18681C5.87535 5.85616 6.0766 5.55586 6.33023 5.30315C6.58387 5.05045 6.8849 4.8503 7.21606 4.7142C7.54722 4.5781 7.90199 4.50871 8.26003 4.51003H19.8C20.4702 4.51229 21.1168 4.75744 21.62 5.20003L25.56 8.74003C25.8467 8.99475 26.0761 9.30741 26.2329 9.65735C26.3898 10.0073 26.4706 10.3865 26.47 10.77V10.77Z" fill={`url(#paint0_linear_3746_46166_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.07001 21.97H23.93" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 24.1901H20.84" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M19.53 16.84C18.9233 17.4455 18.1515 17.8588 17.3112 18.0281C16.4709 18.1973 15.5993 18.1152 14.8054 17.7918C14.0116 17.4684 13.3306 16.9182 12.8477 16.21C12.3649 15.5017 12.1015 14.6669 12.0905 13.8097C12.0795 12.9526 12.3215 12.1112 12.7861 11.3909C13.2506 10.6705 13.9173 10.103 14.7026 9.75946C15.4879 9.41589 16.3571 9.31143 17.2015 9.45915C18.0459 9.60687 18.828 10.0002 19.45 10.59" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.58 12.8H17.23" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.58 14.7201H17.23" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46166_${idVariant}`} x1="4.09578" y1="5.54434" x2="29.9674" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Franchigia
