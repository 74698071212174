import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function FondoPensione({ idVariant = '', className }: Props) {
  return (
    <IconBase name="fondo-pensione" w={32} className={className}>
      <path d="M26.47 10.77V24.77C26.47 25.128 26.3993 25.4826 26.262 25.8132C26.1247 26.1439 25.9235 26.4442 25.6698 26.6969C25.4162 26.9496 25.1152 27.1497 24.784 27.2858C24.4528 27.4219 24.0981 27.4913 23.74 27.49H8.26003C7.90199 27.4913 7.54722 27.4219 7.21606 27.2858C6.8849 27.1497 6.58387 26.9496 6.33023 26.6969C6.0766 26.4442 5.87535 26.1439 5.73803 25.8132C5.60071 25.4826 5.53003 25.128 5.53003 24.77V7.23C5.53003 6.87196 5.60071 6.51744 5.73803 6.18678C5.87535 5.85612 6.0766 5.55583 6.33023 5.30312C6.58387 5.05042 6.8849 4.85027 7.21606 4.71417C7.54722 4.57806 7.90199 4.50868 8.26003 4.51H19.8C20.4702 4.51226 21.1168 4.75741 21.62 5.2L25.56 8.74C25.8467 8.99471 26.0761 9.30738 26.2329 9.65732C26.3898 10.0073 26.4706 10.3865 26.47 10.77V10.77Z" fill={`url(#paint0_linear_3746_46154_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.07001 21.97H23.93" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 24.19H20.84" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.27 10.73C13.0355 10.5212 12.857 10.2571 12.7508 9.96162C12.6446 9.66616 12.6141 9.34881 12.6621 9.03853C12.7101 8.72825 12.835 8.43493 13.0254 8.18533C13.2159 7.93572 13.4658 7.7378 13.7524 7.6096C14.039 7.48141 14.3532 7.42704 14.6662 7.45144C14.9792 7.47585 15.2811 7.57826 15.5444 7.74933C15.8076 7.9204 16.0239 8.15468 16.1733 8.43078C16.3228 8.70689 16.4007 9.01603 16.4 9.32999C16.3992 9.53493 16.3619 9.73809 16.29 9.93" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M17.66 10.13C17.2136 10.0062 16.7533 9.93901 16.29 9.93H16.19C15.1684 9.92338 14.166 10.2078 13.3 10.75C12.7601 11.0716 12.3101 11.5243 11.992 12.0663C11.6738 12.6082 11.4977 13.2218 11.48 13.85C11.4979 14.4518 11.6632 15.04 11.9613 15.563C12.2595 16.0861 12.6814 16.528 13.19 16.85L12.91 17.66C12.8771 17.7585 12.864 17.8625 12.8713 17.9661C12.8786 18.0697 12.9063 18.1708 12.9527 18.2637C12.9992 18.3565 13.0635 18.4393 13.1419 18.5074C13.2204 18.5754 13.3115 18.6272 13.41 18.66L13.88 18.82C14.0777 18.8842 14.2926 18.869 14.4793 18.7776C14.6659 18.6861 14.8097 18.5256 14.88 18.33L15.11 17.64C15.4631 17.7044 15.8211 17.7378 16.18 17.74C16.4408 17.7383 16.7012 17.7216 16.96 17.69L17.17 18.33C17.2404 18.5256 17.3841 18.6861 17.5708 18.7776C17.7574 18.869 17.9724 18.8842 18.17 18.82L18.64 18.66C18.7386 18.6272 18.8297 18.5754 18.9082 18.5074C18.9866 18.4393 19.0509 18.3565 19.0974 18.2637C19.1438 18.1708 19.1715 18.0697 19.1788 17.9661C19.1861 17.8625 19.1729 17.7585 19.14 17.66L18.94 17C19.6947 16.564 20.2903 15.8983 20.64 15.1V15.1H20.76C20.8638 15.1 20.9665 15.0796 21.0624 15.0399C21.1582 15.0002 21.2453 14.942 21.3187 14.8686C21.392 14.7953 21.4502 14.7082 21.4899 14.6123C21.5296 14.5165 21.55 14.4137 21.55 14.31V13.71C21.5514 13.6054 21.5319 13.5016 21.4928 13.4046C21.4537 13.3076 21.3957 13.2193 21.3222 13.1449C21.2487 13.0705 21.1612 13.0114 21.0647 12.9711C20.9682 12.9307 20.8646 12.91 20.76 12.91V12.91C20.6471 12.5159 20.4679 12.1439 20.23 11.81C19.9841 11.4671 19.6873 11.1636 19.35 10.91C19.85 10.22 20.01 9.52 19.7 9.23C19.39 8.94 18.38 9.28 17.64 10.08" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.63 11.2C10.63 11.2 10 12.76 11 13.13L11.57 13.35" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M19.21 13.34C19.3426 13.34 19.45 13.2325 19.45 13.1C19.45 12.9675 19.3426 12.86 19.21 12.86C19.0775 12.86 18.97 12.9675 18.97 13.1C18.97 13.2325 19.0775 13.34 19.21 13.34Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46154_${idVariant}`} x1="4.09578" y1="5.54431" x2="29.9674" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default FondoPensione
