/* eslint-disable unicorn/no-object-as-default-parameter */
export function utente(
    state = {
      utente: null,
    },
    // @ts-ignore
    action
  ) {
    switch (action.type) {
      case 'SET_CURRENT_USER': {
        return {
          ...state,
          utente: action.payload.data?.me,
        }
      }
  
      case 'REMOVE_CURRENT_USER': {
        return {
          ...state,
          utente: null,
        }
      }
  
      default: {
        return state
      }
    }
  }
  