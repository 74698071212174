import TagManager from 'react-gtm-module'
import replace from 'lodash/replace'
import { VITA } from './settings'

const GTM_PAGE_DEFAULT = '404'
const GTM_ID_VITA = 'GTM-P7WCMQG'
const GTM_ID_ASSICURAZIONI = 'GTM-TX2KV34'

export const initGTMdataLayer = (site) => {
  TagManager.initialize({
    gtmId: site === VITA ? GTM_ID_VITA : GTM_ID_ASSICURAZIONI,
    dataLayerName: 'dataLayer',
  })
}

export const sendGTMData = (data) => {
  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

export const getGaranziaDetails = (garanzia, site, quotazione, category) => {
  return {
    name: garanzia.titolo?.toGTMFormat() || '',
    id: garanzia.codice?.toGTMFormat() || '',
    price: garanzia.importoLordoAnnuoScontato
      ? garanzia.importoLordoAnnuoScontato?.toFixed(2)
      : garanzia.importoLordoAnnuo
      ? garanzia.importoLordoAnnuo?.toFixed(2)
      : garanzia.premioLordoAnnuo?.toFixed(2),
    brand: `ca_${site}`.toGTMFormat(),
    category: category ? category.toGTMFormat() : '',
    variant: quotazione?.prodotto?.titolo?.toGTMFormat() || '',
  }
}

//TODO
// const getGTMProps = (paginaCorrente, router) => {
//   let page = paginaCorrente?.pagina?.chiave || GTM_PAGE_DEFAULT
//   let channel = 'corporate'
//   let firstLevelCategory = ''
//   let secondLevelCategory = ''
//   let thirdLevelCategory = ''

//   let pageType = 'corporate'

//   if (page.includes('homepage')) pageType = 'home'
//   if (page.includes('categoria')) pageType = 'listing'
//   if (page.includes('linea')) pageType = 'listing'
//   if (page.includes('prodotto')) pageType = 'product'
//   if (page.includes('checkout')) pageType = 'checkout'
//   if (page.includes('cart')) pageType = 'checkout'
//   if (page.includes('checkout-success')) pageType = 'thankyou'
//   if (page.includes('404')) pageType = '404'
//   if (page.includes('myaccount')) pageType = 'my_account'
//   if (page.includes('centri')) pageType = 'store_locator'
//   if (page.includes('trattamenti')) pageType = 'trattamenti'
//   if (page.includes('wishlist')) pageType = 'wishlist'

//   if (
//     page.includes('categoria') ||
//     page.includes('prodotto') ||
//     page.includes('linea') ||
//     page.includes('checkout')
//   )
//     channel = 'store'

//   if (page.includes('categoria') && router?.asPath) {
//     let parts = router?.asPath.split('/')
//     firstLevelCategory = parts[parts.indexOf('prodotti') + 1] || ''
//     secondLevelCategory = parts[parts.indexOf('prodotti') + 2] || ''
//     thirdLevelCategory = parts[parts.indexOf('prodotti') + 3] || ''
//   }

//   currentPage = page.includes('cart') ? 'cart' : pageType
//   return {
//     pageType,
//     channel,
//     firstLevelCategory,
//     secondLevelCategory,
//     thirdLevelCategory,
//   }
// }

// const getUtenteProps = (utente, numProdottiCart, numProdottiWishlist) => {
//   let login = utente ? 'logged' : 'not_logged'
//   let userID = utente ? utente?.pk : 'not_logged'
//   let registrationDate = utente ? utente?.dataRegistrazione : 'not_logged'
//   let wishListTotalItems = numProdottiWishlist
//   let cartTotalProducts = numProdottiCart

//   return {
//     login,
//     userID,
//     registrationDate,
//     wishListTotalItems,
//     cartTotalProducts,
//   }
// }

function uniformData(param) {
  // toLower + standardizzazione parametri
  return replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()
}

function uniformDataWithDash(param) {
  // toLower + standardizzazione parametri ma mantenimento del '-'
  return replace(param, / /g, '_').toLowerCase()
}

export const trackingGAevent = (id, category, action, label) => {
  let data = {
    event: 'GAevent',
    eventID: id,
    eventCategory: uniformData(category),
    eventAction: uniformData(action),
    eventLabel: [].indexOf(id) < 0 ? uniformData(label) : uniformDataWithDash(label),
  }

  sendGTMData(data)
}

export const updateGTMDataLayer = (paginaCorrente, utente, router) => {
  //TODO
  //const gtmProps = getGTMProps(paginaCorrente, router)
  //const utenteProps = getUtenteProps(utente, numProdottiCart, numProdottiWishlist)

  sendGTMData({
    event: 'wSetup',
    //...gtmProps,
    //...utenteProps,
  })

  sendGTMData({
    event: 'virtual_pageview',
    pagePath: router?.asPath,
  })
}
