import styles from './MainTitle.module.sass'

interface Props {
  label?: string
  variant?: '' | 'light'
  className?: string
}

const IntroTitle = (props: Props) => {

  const { 
    label = '',
    variant = '',
    className = '',
  } = props

  return label ? (
    <h1 className={`${styles.mainTitle} ${variant ? styles[`mainTitle--${variant}`] : ''} ${className}`} dangerouslySetInnerHTML={{ __html: label }} />
  ) : <></>
}

export default IntroTitle
