import { usePagination } from 'libs/shared-ui/hooks'
import { DOTS } from 'libs/shared-ui/hooks/usePagination'
import { ChevronLeft, ChevronRight } from '../Icons'
import styles from './Pagination.module.sass'


interface Props {
  className?: string
  onPageChange?: any
  totalCount?: number
  siblingCount?: number
  currentPage?: number
  pageSize?: number
}

const Pagination = (props: Props) => {

  const { 
    className = '',
    onPageChange = () => {},
    totalCount = 0,
    siblingCount = 1,
    currentPage = 1,
    pageSize = 1,
  } = props

  const paginationRange = usePagination({
    totalCount,
    siblingCount,
    currentPage,
    pageSize
  });

  if (currentPage === 0 || paginationRange == undefined || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return paginationRange.length > 0 ? (
    <div className={`${styles.pagination} ${className || ''}`}>
      {currentPage > 1 ? (
        <a 
          className={styles.prev}
          onClick={() => onPrevious()}
        >
          <ChevronLeft />
        </a>
      ) : (
        <span className={`${styles.prev} ${styles['prev--disabled']}`}><ChevronLeft /></span>
      )}
      {paginationRange.map((item, index) => item === DOTS ? (<a 
          className={`${styles.item}`}
          key={index}
        >
          {item}
        </a>) : (
        <a 
          key={index} 
          onClick={() => onPageChange(item)}
          className={`${styles.item} ${currentPage === item ? styles['item--active'] : ''}`}
        >
          {item}
        </a>
      ))}
      {currentPage < lastPage ? (
        <a 
          className={styles.next}
          onClick={() => onNext()}
        >
          <ChevronRight />
        </a>
      ) : (
        <span className={`${styles.next} ${styles['next--disabled']}`}><ChevronRight /></span>
      )}
    </div>
  ) : <></>
}

export default Pagination
