import React from 'react';
import IconBase from '../IconBase/IconBase';

interface Props {
  className?: string;
}

function Loader({ className }: Props) {
  return (
    <IconBase name="loader" w={84} h={84} className={className}>
      <path d="M76 42C76 23.2223 60.7777 8 42 8" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round"/>
    </IconBase>
  );
}

export default Loader;
