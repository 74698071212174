import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Risparmio({ idVariant = '', className }: Props) {
  return (
    <IconBase name="risparmio" w={32} className={className}>
      <path d="M13 12.02C15.0987 12.02 16.8 10.3187 16.8 8.22001C16.8 6.12133 15.0987 4.42001 13 4.42001C10.9013 4.42001 9.20001 6.12133 9.20001 8.22001C9.20001 10.3187 10.9013 12.02 13 12.02Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M19.36 9.84001C18.3814 9.5795 17.3728 9.44838 16.36 9.45001C11.1 9.45001 6.83002 13 6.83002 17.38C6.86355 18.6036 7.19649 19.8004 7.79967 20.8656C8.40286 21.9308 9.25792 22.8318 10.29 23.49L9.73002 25.13C9.60775 25.528 9.64342 25.9578 9.82963 26.3302C10.0158 26.7026 10.3383 26.989 10.73 27.13L11.73 27.45C12.1279 27.5802 12.5611 27.5482 12.9355 27.361C13.3099 27.1738 13.5954 26.8464 13.73 26.45L14.2 25.06C14.912 25.1962 15.6351 25.2665 16.36 25.27C16.9077 25.2858 17.4557 25.2624 18 25.2L18.44 26.49C18.5746 26.8864 18.8601 27.2138 19.2345 27.401C19.609 27.5882 20.0421 27.6202 20.44 27.49L21.44 27.17C21.8349 27.0332 22.1605 26.7474 22.3474 26.3737C22.5343 26 22.5675 25.5679 22.44 25.17L22 23.82C23.5098 22.9395 24.7005 21.6017 25.4 20H25.48H25.71C26.1344 20 26.5413 19.8314 26.8414 19.5314C27.1414 19.2313 27.31 18.8244 27.31 18.4V17.17C27.31 16.7447 27.1418 16.3367 26.842 16.0351C26.5422 15.7334 26.1353 15.5627 25.71 15.56V15.56C25.4829 14.7674 25.1209 14.0198 24.64 13.35C24.1442 12.6522 23.5439 12.0349 22.86 11.52C23.86 10.12 24.2 8.70001 23.57 8.12001C22.94 7.54001 20.9 8.22001 19.4 9.83001" fill={`url(#paint0_linear_3746_46155_${idVariant})`} stroke="none"/>
      <path d="M19.36 9.84001C18.3814 9.5795 17.3728 9.44838 16.36 9.45001C11.1 9.45001 6.83002 13 6.83002 17.38C6.86355 18.6036 7.19649 19.8004 7.79967 20.8656C8.40286 21.9308 9.25792 22.8318 10.29 23.49L9.73002 25.13C9.60775 25.528 9.64342 25.9578 9.82963 26.3302C10.0158 26.7026 10.3383 26.989 10.73 27.13L11.73 27.45C12.1279 27.5802 12.5611 27.5482 12.9355 27.361C13.3099 27.1738 13.5954 26.8464 13.73 26.45L14.2 25.06C14.912 25.1962 15.6351 25.2665 16.36 25.27C16.9077 25.2858 17.4557 25.2624 18 25.2L18.44 26.49C18.5746 26.8864 18.8601 27.2138 19.2345 27.401C19.609 27.5882 20.0421 27.6202 20.44 27.49L21.44 27.17C21.8349 27.0332 22.1605 26.7474 22.3474 26.3737C22.5343 26 22.5675 25.5679 22.44 25.17L22 23.82C23.5098 22.9395 24.7005 21.6017 25.4 20H25.48H25.71C26.1344 20 26.5413 19.8314 26.8414 19.5314C27.1414 19.2313 27.31 18.8244 27.31 18.4V17.17C27.31 16.7447 27.1418 16.3367 26.842 16.0351C26.5422 15.7334 26.1353 15.5627 25.71 15.56V15.56C25.4829 14.7674 25.1209 14.0198 24.64 13.35C24.1442 12.6522 23.5439 12.0349 22.86 11.52C23.86 10.12 24.2 8.70001 23.57 8.12001C22.94 7.54001 20.9 8.22001 19.4 9.83001" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M5.13003 12C5.13003 12 3.79003 15.14 5.78003 15.9L6.93003 16.34" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M22.49 16.36C22.7606 16.36 22.98 16.1406 22.98 15.87C22.98 15.5994 22.7606 15.38 22.49 15.38C22.2194 15.38 22 15.5994 22 15.87C22 16.1406 22.2194 16.36 22.49 16.36Z" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46155_${idVariant}`} x1="5.42728" y1="8.80015" x2="28.2863" y2="27.8728" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Risparmio
