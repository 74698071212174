import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Persona({ idVariant = '', className }: Props) {
  return (
    <IconBase name="persona" w={32} className={className}>
      <path d="M26.75 15.3301L16 27.1701C16 27.1701 5.8 16.0001 5.25 15.3301C4.30083 14.1446 3.75436 12.6873 3.69 11.1701C3.70486 9.68181 4.24282 8.24622 5.20967 7.11472C6.17652 5.98322 7.51062 5.22793 8.97834 4.98112C10.4461 4.73432 11.9538 5.01173 13.2376 5.76478C14.5213 6.51783 15.4992 7.69852 16 9.10005C16.4916 7.68359 17.47 6.48761 18.761 5.72512C20.052 4.96264 21.5717 4.68316 23.0495 4.93646C24.5273 5.18976 25.8672 5.9594 26.8306 7.10829C27.7939 8.25718 28.3182 9.71073 28.31 11.2101C28.3166 12.7289 27.7609 14.1965 26.75 15.3301Z" fill={`url(#paint0_linear_3743_45718_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M23.32 7.33008C24.1803 7.76994 24.9025 8.43867 25.4071 9.26264C25.9117 10.0866 26.1792 11.0339 26.18 12.0001" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45718_${idVariant}`} x1="2.00369" y1="5.85028" x2="28.1938" y2="28.9763" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Persona
