import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Moto({ idVariant = '', className }: Props) {
  return (
    <IconBase name="moto" w={32} className={className}>
      <path d="M26.47 10.77V24.77C26.47 25.128 26.3993 25.4826 26.262 25.8132C26.1247 26.1439 25.9235 26.4442 25.6698 26.6969C25.4162 26.9496 25.1152 27.1497 24.784 27.2858C24.4528 27.4219 24.0981 27.4913 23.74 27.49H8.26003C7.90199 27.4913 7.54722 27.4219 7.21606 27.2858C6.8849 27.1497 6.58387 26.9496 6.33023 26.6969C6.0766 26.4442 5.87535 26.1439 5.73803 25.8132C5.60071 25.4826 5.53003 25.128 5.53003 24.77V7.23C5.53003 6.87196 5.60071 6.51744 5.73803 6.18678C5.87535 5.85612 6.0766 5.55583 6.33023 5.30312C6.58387 5.05042 6.8849 4.85027 7.21606 4.71417C7.54722 4.57806 7.90199 4.50868 8.26003 4.51H19.8C20.4702 4.51226 21.1168 4.75741 21.62 5.2L25.56 8.74C25.8467 8.99471 26.0761 9.30738 26.2329 9.65732C26.3898 10.0073 26.4706 10.3865 26.47 10.77V10.77Z" fill={`url(#paint0_linear_3746_46158_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.07001 21.97H23.93" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 24.19H20.84" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.0001 14.52C15.652 14.52 15.3117 14.6232 15.0222 14.8166C14.7328 15.01 14.5072 15.2849 14.374 15.6065C14.2408 15.9281 14.206 16.282 14.2739 16.6234C14.3418 16.9648 14.5094 17.2784 14.7555 17.5245C15.0017 17.7707 15.3153 17.9383 15.6567 18.0062C15.9981 18.0741 16.352 18.0393 16.6736 17.906C16.9952 17.7728 17.27 17.5473 17.4634 17.2578C17.6568 16.9684 17.7601 16.6281 17.7601 16.28C17.7601 16.0489 17.7145 15.82 17.6261 15.6065C17.5376 15.393 17.408 15.1989 17.2446 15.0355C17.0811 14.8721 16.8871 14.7424 16.6736 14.654C16.46 14.5655 16.2312 14.52 16.0001 14.52V14.52Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M8.47003 12.05C8.47003 12.05 11.84 10.35 11.72 13.99C11.6 17.63 16 16.4 16 16.4" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M23.5301 12.05C23.5301 12.05 20.1601 10.35 20.2801 13.99C20.4001 17.63 16.0001 16.4 16.0001 16.4" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46158_${idVariant}`} x1="4.09578" y1="5.54431" x2="29.9674" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Moto
