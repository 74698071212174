import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowDown({ className }: Props) {
  return (
    <IconBase name="arrow-down" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 5C12.5 4.44772 12.0523 4 11.5 4C10.9477 4 10.5 4.44772 10.5 5L10.5 17.5858L4.70711 11.7929C4.31658 11.4024 3.68342 11.4024 3.29289 11.7929C2.90237 12.1834 2.90237 12.8166 3.29289 13.2071L11.5 21.4142L19.7071 13.2071C20.0976 12.8166 20.0976 12.1834 19.7071 11.7929C19.3166 11.4024 18.6834 11.4024 18.2929 11.7929L12.5 17.5858L12.5 5Z" stroke="none"/>
    </IconBase>
  )
}

export default ArrowDown
