import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Comodo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="comodo" w={32} className={className}>
      <path d="M4.72998 21.09L6.78998 18.67C6.78998 18.67 8.12998 16.28 12.44 16.24C14.7103 16.3101 16.9689 15.8871 19.06 15C19.06 15 21.11 14.26 20.59 16.27C20.07 18.28 16.44 18.39 16.44 18.39C16.44 18.39 15.9 18.68 16.44 18.97C17.7825 19.271 19.169 19.3219 20.53 19.12C22.18 18.81 25.36 16.76 25.36 16.76C25.36 16.76 27.42 15.76 27.92 16.85C28.42 17.94 24.07 20.2 24.07 20.2C22.5908 21.1421 20.9267 21.756 19.19 22C16.63 22.25 12.78 22.25 12.78 22.25C12.0347 22.2469 11.3175 22.5338 10.78 23.05C9.85998 23.93 8.89998 24.81 8.89998 24.81L4.72998 21.09Z" fill={`url(#paint0_linear_3932_47552_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M16.67 13.0901C17.8519 13.0901 18.81 12.1319 18.81 10.9501C18.81 9.76817 17.8519 8.81006 16.67 8.81006C15.4881 8.81006 14.53 9.76817 14.53 10.9501C14.53 12.1319 15.4881 13.0901 16.67 13.0901Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47552_${idVariant}`} x1="3.13888" y1="15.3145" x2="12.9136" y2="33.5993" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Comodo
