import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function AttiVandalici({ idVariant = '', className }: Props) {
  return (
    <IconBase name="atti-vandalici" w={24} className={className}>
      <path d="M10.6271 21.2407H14.2496C14.7446 21.2407 15.1496 20.8357 15.1496 20.3407V10.1932C15.1496 9.9832 15.0746 9.7807 14.9321 9.6157L12.2546 6.5782V5.1982C12.2546 4.7932 11.9321 4.4707 11.5271 4.4707H10.5746C10.1696 4.4707 9.84715 4.7932 9.84715 5.1982V6.5782L7.16965 9.6157C7.02715 9.7732 6.95215 9.9757 6.95215 10.1932V20.3407C6.95215 20.8357 7.35715 21.2407 7.85215 21.2407H10.8521" fill={`url(#paint0_linear_925_23438_${idVariant})`} stroke="none"/>
      <path d="M10.6271 21.2407H14.2496C14.7446 21.2407 15.1496 20.8357 15.1496 20.3407V10.1932C15.1496 9.9832 15.0746 9.7807 14.9321 9.6157L12.2546 6.5782V5.1982C12.2546 4.7932 11.9321 4.4707 11.5271 4.4707H10.5746C10.1696 4.4707 9.84715 4.7932 9.84715 5.1982V6.5782L7.16965 9.6157C7.02715 9.7732 6.95215 9.9757 6.95215 10.1932V20.3407C6.95215 20.8357 7.35715 21.2407 7.85215 21.2407H10.8521" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.0947 20.0325H12.9822C13.4772 20.0325 13.8822 19.6275 13.8822 19.1325V11.6475" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M7.08691 9.86328H14.9994" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M9.84668 6.58496H12.2617" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15 4.30543L18.8925 2.94043" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15.1494 7.03516L18.8919 8.40766" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15.6592 5.64062H18.8917" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_925_23438_${idVariant}`} x1="6.39068" y1="5.22552" x2="19.8486" y2="10.4928" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default AttiVandalici
