import React from 'react';
import IconBase from '../IconBase/IconBase';

interface Props {
  className?: string;
}

function ArrowDown({ className }: Props) {
  return (
    <IconBase name="chevron-down" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.2318 9.35984C5.58537 8.93556 6.21593 8.87824 6.64021 9.2318L12 13.6983L17.3598 9.2318C17.7841 8.87824 18.4147 8.93556 18.7682 9.35984C19.1218 9.78412 19.0645 10.4147 18.6402 10.7682L12 16.3017L5.35984 10.7682C4.93556 10.4147 4.87824 9.78412 5.2318 9.35984Z" stroke="none" />
    </IconBase>
  );
}

export default ArrowDown;
