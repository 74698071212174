import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Light({ idVariant = '', className }: Props) {
  return (
    <IconBase name="light" w={32} className={className}>
      <path d="M19.85 19C22.59 20.43 22.93 22.86 21.85 24.94C21.02 26.64 19.77 27.16 17.72 26.94C15.67 26.72 8.57003 24.06 6.14003 16.89C4.8799 13.0052 5.00357 8.80395 6.49003 5C6.49003 5 5.70003 12.47 19.85 19Z" fill={`url(#paint0_linear_3743_45728_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.21 20.14C13.1758 22.3049 15.5734 24.0338 18.2482 25.2154C20.9231 26.3969 23.8158 27.0049 26.74 27" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45728_${idVariant}`} x1="4.10321" y1="5.98966" x2="27.4573" y2="20.5689" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Light
