import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function SocialFacebook({ className }: Props) {
  return (
    <IconBase name="social-facebook" w={41} h={40} className={className}>
      <path d="M28.532 4C28.6891 4.29711 28.9766 4.58996 28.9766 4.88992C29.0225 6.35558 28.9766 7.82408 29.0047 9.29685C29.0136 9.94225 28.7083 10.1811 28.0637 10.1825C26.9537 10.1825 25.8407 10.1939 24.7366 10.2607C23.6844 10.3247 23.1287 10.8293 23.0783 11.8244C23.0176 13.0243 23.0635 14.2283 23.0635 15.5206H27.5094C28.7054 15.5206 28.8076 15.6201 28.8076 16.7829C28.8076 18.2045 28.8076 19.6261 28.8076 21.0477C28.8076 21.9007 28.615 22.0698 27.711 22.0713C26.186 22.0713 24.6611 22.0713 23.0442 22.0713V22.8787C23.0442 26.8777 23.0561 30.8766 23.025 34.8741C23.025 35.2508 22.7449 35.6247 22.5952 36H16.5191C16.0197 35.7725 16.039 35.3432 16.0404 34.9068C16.0404 30.9263 16.0404 26.9459 16.0404 22.9654V22.0698C14.6474 22.0698 13.3447 22.0698 12.0391 22.0698C11.1648 22.0698 11.0121 21.9206 11.0092 21.0747C11.0092 19.5337 11.0225 17.9941 11.0003 16.4546C10.9899 15.785 11.2804 15.512 11.9739 15.5248C13.3077 15.5476 14.6281 15.5248 16.0553 15.5248C16.0553 14.1345 16.0345 12.8139 16.0553 11.4946C16.1294 7.41892 18.5865 4.72928 22.7671 4.11799C22.8606 4.09403 22.9507 4.05918 23.0353 4.01422L28.532 4Z" stroke="none"/>
    </IconBase>
  )
}

export default SocialFacebook
