import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Bambini({ idVariant = '', className }: Props) {
  return (
    <IconBase name="bambini" w={32} className={className}>
      <path d="M13.21 15.96C15.8775 15.96 18.04 13.7976 18.04 11.13C18.04 8.46251 15.8775 6.30005 13.21 6.30005C10.5425 6.30005 8.38 8.46251 8.38 11.13C8.38 13.7976 10.5425 15.96 13.21 15.96Z" fill={`url(#paint0_linear_3932_47580_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M20.71 25.55H5.71002C5.71002 23.5609 6.5002 21.6533 7.90672 20.2467C9.31324 18.8402 11.2209 18.05 13.21 18.05C15.1991 18.05 17.1068 18.8402 18.5133 20.2467C19.9198 21.6533 20.71 23.5609 20.71 25.55V25.55Z" fill={`url(#paint1_linear_3932_47580_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M22.25 19.2901C24.0339 19.2901 25.48 17.844 25.48 16.0601C25.48 14.2762 24.0339 12.8301 22.25 12.8301C20.4661 12.8301 19.02 14.2762 19.02 16.0601C19.02 17.844 20.4661 19.2901 22.25 19.2901Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M27.26 25.7H17.26C17.26 24.3739 17.7868 23.1021 18.7245 22.1644C19.6622 21.2267 20.9339 20.7 22.26 20.7C23.5861 20.7 24.8579 21.2267 25.7955 22.1644C26.7332 23.1021 27.26 24.3739 27.26 25.7V25.7Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47580_${idVariant}`} x1="7.71836" y1="6.73484" x2="18.8623" y2="15.6576" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3932_47580_${idVariant}`} x1="4.68262" y1="18.3876" x2="12.6498" y2="31.146" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Bambini
