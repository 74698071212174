import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Cristalli({ idVariant = '', className }: Props) {
  return (
    <IconBase name="cristalli" w={24} className={className}>
      <path d="M12.3227 8.25742C10.0202 8.27992 7.4027 8.15992 4.8452 7.73242L2.8877 15.1499C2.8877 15.1499 7.4402 16.5749 12.3227 16.5749H12.1127C16.9952 16.5749 21.5477 15.1499 21.5477 15.1499L19.5902 7.73242C17.0327 8.15992 14.4152 8.27992 12.1127 8.25742" fill={`url(#paint0_linear_420_107055_${idVariant})`} stroke="none"/>
      <path d="M12.3227 8.25742C10.0202 8.27992 7.4027 8.15992 4.8452 7.73242L2.8877 15.1499C2.8877 15.1499 7.4402 16.5749 12.3227 16.5749H12.1127C16.9952 16.5749 21.5477 15.1499 21.5477 15.1499L19.5902 7.73242C17.0327 8.15992 14.4152 8.27992 12.1127 8.25742" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M9.53223 15.2403L11.9997 9.59277" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.7432 13.822L13.9807 11.002" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M18.3456 8.1748L18.7356 9.58481L20.1456 9.97481L18.7356 10.3648L18.3456 11.7823L17.9556 10.3648L16.5381 9.97481L17.9556 9.58481L18.3456 8.1748Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M5.1825 14.7754L5.5725 16.1929L6.9825 16.5754L5.5725 16.9654L5.1825 18.3829L4.7925 16.9654L3.375 16.5754L4.7925 16.1929L5.1825 14.7754Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M16.2005 5.97754L16.4405 6.84004L17.303 7.08004L16.4405 7.31254L16.2005 8.17504L15.968 7.31254L15.1055 7.08004L15.968 6.84004L16.2005 5.97754Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_420_107055_${idVariant}`} x1="4.2227" y1="8.90992" x2="21.1052" y2="14.9999" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F5FAFA" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEEEF" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Cristalli
