/* eslint-disable @nx/nx/enforce-module-boundaries */
import { MenuType } from '@next-nx/utils/types'
import { useSelector } from 'react-redux'

export default function useFindMenu(chiave: string, menu: MenuType | null = null): MenuType | null {
  const { menu: reduxMenu } = useSelector((state: any) => state?.menu)

  const findMenu: any = (menu: any, chiave: string) => {
    for (const item of menu?.children?.edges || menu?.edges || []) {
      if (item.node.key === chiave) return item.node

      if (item.node?.children) {
        const newItem: object | null = findMenu(item.node.children, chiave)
        if (newItem) return newItem
      }
    }
    return null
  }

  return menu ? findMenu(menu, chiave) : findMenu(reduxMenu, chiave)
}
