import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Cam({ idVariant = '', className }: Props) {
  return (
    <IconBase name="cam" w={32} className={className}>
      <path d="M16 15.4199C21.23 15.4199 25.48 19.6699 25.48 24.8999V25.6899C25.48 27.0199 24.4 28.0999 23.07 28.0999H8.93002C7.60002 28.0999 6.52002 27.0199 6.52002 25.6899V24.8999C6.52002 19.6699 10.77 15.4199 16 15.4199V15.4199Z" fill={`url(#paint0_linear_4885_95984_${idVariant})`} stroke="#009598" strokeMiterlimit="10"/>
      <path d="M16 19.8299C20.4017 19.8299 23.97 16.2616 23.97 11.8599C23.97 7.45818 20.4017 3.88989 16 3.88989C11.5983 3.88989 8.03003 7.45818 8.03003 11.8599C8.03003 16.2616 11.5983 19.8299 16 19.8299Z" fill={`url(#paint1_linear_4885_95984_${idVariant})`} stroke="#009598" strokeMiterlimit="10"/>
      <path d="M19.42 8.97981C19.42 7.091 17.8888 5.55981 16 5.55981C14.1112 5.55981 12.58 7.091 12.58 8.97981V13.4698C12.58 15.3586 14.1112 16.8898 16 16.8898C17.8888 16.8898 19.42 15.3586 19.42 13.4698V8.97981Z" stroke="#009598" strokeMiterlimit="10"/>
      <path d="M16 14.1899C17.32 14.1899 18.39 13.1199 18.39 11.7999C18.39 10.48 17.32 9.40991 16 9.40991C14.6801 9.40991 13.61 10.48 13.61 11.7999C13.61 13.1199 14.6801 14.1899 16 14.1899Z" fill="#F01526"/>
      <defs>
      <linearGradient id={`paint0_linear_4885_95984_${idVariant}`} x1="6.51002" y1="21.7599" x2="25.49" y2="21.7599" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F5FAFA"/>
      <stop offset="0.56" stopColor="#DCEEEF"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_4885_95984_${idVariant}`} x1="7.20003" y1="5.17989" x2="24.33" y2="18.1799" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F5FAFA"/>
      <stop offset="0.56" stopColor="#DCEEEF"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Cam
