import React from 'react'
import { MessageInfo } from '../Icons'
import styles from './FormLegend.module.sass'

interface Props {
  label: string
  className?: string
}

const FormLegend = (props: Props) => {
  const { label = '', className = '' } = props

  return <p className={`${styles.formLegend} ${className || ''}`}>
    <span className={styles.icon}><MessageInfo /></span>
    <span className={styles.label}>{label}</span>
  </p>
}

export default FormLegend
