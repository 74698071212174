import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LogoVita({ idVariant = '', className }: Props) {
  return (
    <IconBase name="logo-vita" w={278} h={33} className={className}>
      <g clipPath={`url(#clip0_1541:3710_${idVariant})`}>
      <path d="M65.9109 4.32383C68.1076 4.32383 70.4041 4.94978 72.3012 6.24984L70.8534 8.0314C69.3057 7.02024 68.0576 6.44244 66.1106 6.44244C62.7657 6.44244 60.1198 8.46475 60.1198 11.4019C60.1198 14.3391 62.7657 16.3614 66.1106 16.3614C68.1575 16.3614 69.5054 15.7354 71.1529 14.6761L72.6007 16.4577C70.7036 17.7577 68.2573 18.48 65.9609 18.48C61.318 18.48 56.9746 15.8317 56.9746 11.3538C56.9746 6.82764 61.3679 4.22753 65.9609 4.22753L65.9109 4.32383Z" fill="#256F58" stroke="none"/>
      <path d="M82.6859 11.4018C84.8326 11.4018 86.2305 10.5351 86.2305 9.04248C86.2305 7.59798 84.8826 6.77942 82.636 6.77942H78.1928V11.4018H82.636H82.6859ZM75.1475 4.56451H82.8856C86.4801 4.56451 89.3757 5.96087 89.3757 8.99433C89.3757 11.3537 87.5785 12.6056 85.1322 13.1353L89.825 18.3355H86.2305L81.987 13.5205H78.1928V18.3355H75.1475V4.56451Z" fill="#256F58" stroke="none"/>
      <path d="M102.255 0.616138L99.9086 3.02365H97.3126L99.1098 0.616138H102.305H102.255ZM92.3701 14.339V4.51631H105.3V6.73122H95.4155V10.0536H104.502V12.2203H95.4155V14.339C95.4155 15.5427 96.0145 16.1205 97.2127 16.1205H105.6V18.3354H96.5138C93.9177 18.3354 92.3701 16.8428 92.3701 14.339Z" fill="#256F58" stroke="none"/>
      <path d="M115.535 16.1687C119.03 16.1687 121.326 14.2909 121.326 11.4981C121.326 8.70543 118.98 6.77942 115.535 6.77942H111.941V16.1687H115.535ZM108.896 4.56451H115.336C120.827 4.56451 124.522 7.30907 124.522 11.45C124.522 15.5909 120.827 18.3355 115.336 18.3355H108.896V4.56451Z" fill="#256F58" stroke="none"/>
      <path d="M130.413 4.56451H127.367V18.3836H130.413V4.56451Z" fill="#256F58" stroke="none"/>
      <path d="M147.137 4.56451V6.77942H141.596V18.3836H138.55V6.77942H133.059V4.56451H147.137Z" fill="#256F58" stroke="none"/>
      <path d="M163.961 12.8945L161.166 7.40534L158.37 12.8945H164.011H163.961ZM165.06 15.0612H157.172L155.474 18.3836H152.329L159.718 4.61262H162.863L170.252 18.3836H166.757L165.06 15.0612Z" fill="#256F58" stroke="none"/>
      <path d="M179.837 4.32382C182.033 4.32382 184.33 4.94978 186.227 6.24983L184.779 8.03139C183.232 7.02024 181.984 6.44243 180.037 6.44243C176.692 6.44243 174.046 8.41659 174.046 11.4019C174.046 14.3391 176.392 16.4095 179.937 16.4095C181.534 16.4095 182.982 15.9762 183.98 15.3502V12.702H179.687V10.5834H187.076V16.3614C185.378 17.5651 182.932 18.5281 179.837 18.5281C174.445 18.5281 170.851 15.4465 170.851 11.4019C170.851 6.87579 175.244 4.27567 179.837 4.27567V4.32382Z" fill="#256F58" stroke="none"/>
      <path d="M197.959 11.4018C200.106 11.4018 201.504 10.5351 201.504 9.04248C201.504 7.59798 200.156 6.77942 197.909 6.77942H193.466V11.4018H197.909H197.959ZM190.421 4.56451H198.159C201.754 4.56451 204.649 5.96087 204.649 8.99433C204.649 11.3537 202.852 12.6056 200.406 13.1353L205.098 18.3355H201.504L197.26 13.5205H193.466V18.3355H190.421V4.56451Z" fill="#256F58" stroke="none"/>
      <path d="M211.239 4.56451H208.193V18.3836H211.239V4.56451Z" fill="#256F58" stroke="none"/>
      <path d="M223.32 4.32383C225.517 4.32383 227.813 4.94978 229.71 6.24984L228.263 8.0314C226.715 7.02024 225.467 6.44244 223.52 6.44244C220.175 6.44244 217.529 8.46475 217.529 11.4019C217.529 14.3391 220.175 16.3614 223.52 16.3614C225.567 16.3614 226.915 15.7354 228.562 14.6761L230.01 16.4577C228.113 17.7577 225.667 18.48 223.37 18.48C218.727 18.48 214.384 15.8317 214.384 11.3538C214.384 6.82764 218.777 4.22753 223.37 4.22753L223.32 4.32383Z" fill="#256F58" stroke="none"/>
      <path d="M233.804 11.4501C233.804 14.2428 236.201 16.4095 239.646 16.4095C243.14 16.4095 245.487 14.1946 245.487 11.4501C245.487 8.65734 243.09 6.49058 239.646 6.49058C236.151 6.49058 233.804 8.70549 233.804 11.4501ZM230.609 11.4501C230.609 7.54989 234.354 4.32382 239.646 4.32382C244.937 4.32382 248.682 7.54989 248.682 11.4501C248.682 15.3502 244.937 18.5763 239.646 18.5763C234.354 18.5763 230.609 15.3502 230.609 11.4501Z" fill="#256F58" stroke="none"/>
      <path d="M263.309 18.3836H251.178V4.56451H254.223V16.1687H263.309V18.3836Z" fill="#256F58" stroke="none"/>
      <path d="M264.807 14.3872V4.56451H277.737V6.77942H267.852V10.1018H276.938V12.2686H267.852V14.3872C267.852 15.5909 268.451 16.1687 269.649 16.1687H278.036V18.3836H268.95C266.354 18.3836 264.807 16.891 264.807 14.3872Z" fill="#256F58" stroke="none"/>
      <path d="M57.6738 22.6208H60.3198L64.3137 30.8064L68.3575 22.6208H70.7039L65.3621 32.9731H63.0157L57.6738 22.6208Z" fill="#256F58" stroke="none"/>
      <path d="M75.0974 22.6208H72.751V32.9731H75.0974V22.6208Z" fill="#256F58" stroke="none"/>
      <path d="M88.0272 22.6208V24.2579H83.8835V32.9731H81.587V24.2579H77.4434V22.6208H88.0272Z" fill="#256F58" stroke="none"/>
      <path d="M96.265 28.8804L94.1682 24.7876L92.0714 28.8804H96.3149H96.265ZM97.1137 30.5175H91.1727L89.8747 33.0213H87.5283L93.1198 22.669H95.4662L101.058 33.0213H98.4117L97.1137 30.5175Z" fill="#256F58" stroke="none"/>
      <path d="M42.6463 29.3618H0.510742V32.9731H42.6463V29.3618Z" fill="#256F58" stroke="none"/>
      <path d="M27.3208 9.5721L30.1664 16.4576L35.2087 13.9056L30.8654 4.61262H24.5251L15.6387 20.5985C15.6387 20.5985 20.0319 20.4541 22.8277 18.4317C23.5266 18.0465 25.5235 12.9908 27.3707 9.5721H27.3208Z" fill="#256F58" stroke="none"/>
      <path d="M35.1584 13.8575L30.1161 16.4094C25.8227 18.5281 21.729 20.4059 19.5323 20.9837C13.7412 22.5727 6.65203 23.5838 4.10592 21.4171C1.70959 19.3948 7.25111 10.824 17.6352 8.89801C19.3326 8.51281 20.3311 8.60911 20.3311 8.65726C20.3311 8.65726 19.6322 5.72009 19.083 5.09414C18.2842 4.17929 17.5354 3.98669 16.3871 4.13114C14.1406 4.27559 4.50531 6.73125 0.761043 14.4353C-1.33575 18.817 1.45997 23.2468 3.25722 24.5468C4.10592 25.1246 9.5476 29.8434 22.1783 25.0765C34.6092 20.4059 45.7422 13.0871 45.7422 13.0871V8.17576C45.7422 8.17576 40.8497 10.8722 35.1584 13.8093V13.8575Z" fill="#2E9095" stroke="none"/>
      <path d="M31.9131 20.8875C32.3624 21.9468 33.7103 24.9803 34.0598 25.8951C34.5091 26.8581 35.6074 26.8581 35.6074 26.8581H41.3986L37.1051 18.2392C35.5076 19.1059 33.7603 20.0208 31.9131 20.8875Z" fill="#256F58" stroke="none"/>
      <path d="M45.7919 6.29786C45.7919 6.29786 39.152 9.95727 37.6044 10.5351C36.1566 11.0647 35.6574 10.3425 35.4577 10.1017C35.0583 9.52392 34.4592 7.69421 34.3594 7.40531C34.4093 7.40531 34.7588 7.50161 35.6075 7.26086C36.6059 7.02011 45.742 2.06063 45.7919 2.10878V6.29786Z" fill="#ED3239" stroke="none"/>
      </g>
      <defs>
      <clipPath id={`clip0_1541:3710_${idVariant}`}>
      <rect width="278" height="33" fill="white" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default LogoVita
