import React, { useEffect, useState } from 'react'
import useChatbot from 'libs/shared-ui/hooks/useChatbot'

export interface ChatBotContextValue {
  openChatBot(i: any): void;
  chatBotVisible: boolean | null;
  setChatBotVisible(i: boolean): void;
}

export const ChatBotContext = React.createContext<ChatBotContextValue>({
  openChatBot: () => {console.log('openchatbot is null')},
  chatBotVisible: false,
  setChatBotVisible: () => {}
})
 
interface Props {
  children?: any
}

const ChatBotProvider = (props: Props) : any => {
  const {
    openChatBot,
    chatBotVisible,
    setChatBotVisible
  } = useChatbot()

  return (
    <ChatBotContext.Provider value={{ openChatBot, chatBotVisible, setChatBotVisible }}>
      {props.children}
    </ChatBotContext.Provider>
  )
}

export default ChatBotProvider