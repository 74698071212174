import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Innovativo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="innovativo" w={32} className={className}>
      <path d="M20.85 19.3798C20.85 23.9398 13.1 27.8898 13.1 27.8898C13.1 27.8898 16.3 24.2498 15.73 21.5998C15.16 18.9498 20.85 19.3798 20.85 19.3798Z" fill={`url(#paint0_linear_3932_47578_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M13.18 11.71C8.61998 11.71 4.66998 19.46 4.66998 19.46C4.66998 19.46 8.30998 16.26 10.96 16.83C13.61 17.4 13.18 11.71 13.18 11.71Z" fill={`url(#paint1_linear_3932_47578_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M13 19.5799C15.1 21.6799 15.75 21.5799 15.75 21.5799C17.5816 21.1701 19.3189 20.4168 20.87 19.3599C27.76 14.5799 27.34 5.21991 27.34 5.21991C27.34 5.21991 18 4.81991 13.18 11.7099C12.1372 13.264 11.3975 15.0011 11 16.8299C11 16.8299 10.88 17.4799 13 19.5799Z" fill={`url(#paint2_linear_3932_47578_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M5 27.6099C14.67 23.3499 11.53 20.9999 11.53 20.9999C11.53 20.9999 9.21 17.8899 5 27.6099Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeLinejoin="round"/>
      <path d="M19.49 16.1098C21.0916 16.1098 22.39 14.8114 22.39 13.2098C22.39 11.6082 21.0916 10.3098 19.49 10.3098C17.8883 10.3098 16.59 11.6082 16.59 13.2098C16.59 14.8114 17.8883 16.1098 19.49 16.1098Z" fill="#ED1B2F" stroke="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47578_${idVariant}`} x1="12.5692" y1="19.7382" x2="22.1684" y2="26.7165" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3932_47578_${idVariant}`} x1="4.08534" y1="12.0588" x2="13.1758" y2="20.0753" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_3932_47578_${idVariant}`} x1="9.87912" y1="5.95217" x2="28.7534" y2="21.047" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Innovativo
