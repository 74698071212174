import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Sinistro({ idVariant = '', className }: Props) {
  return (
    <IconBase name="sinistro" w={32} className={className}>
      <path d="M26.47 10.77V24.77C26.47 25.128 26.3993 25.4826 26.262 25.8132C26.1247 26.1439 25.9235 26.4442 25.6698 26.6969C25.4162 26.9496 25.1152 27.1497 24.784 27.2858C24.4528 27.4219 24.0981 27.4913 23.74 27.49H8.26003C7.90199 27.4913 7.54722 27.4219 7.21606 27.2858C6.8849 27.1497 6.58387 26.9496 6.33023 26.6969C6.0766 26.4442 5.87535 26.1439 5.73803 25.8132C5.60071 25.4826 5.53003 25.128 5.53003 24.77V7.23C5.53003 6.87196 5.60071 6.51744 5.73803 6.18678C5.87535 5.85612 6.0766 5.55583 6.33023 5.30312C6.58387 5.05042 6.8849 4.85027 7.21606 4.71417C7.54722 4.57806 7.90199 4.50868 8.26003 4.51H19.8C20.4702 4.51226 21.1168 4.75741 21.62 5.2L25.56 8.74C25.8467 8.99471 26.0761 9.30738 26.2329 9.65732C26.3898 10.0073 26.4706 10.3865 26.47 10.77V10.77Z" fill={`url(#paint0_linear_3745_46150_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 19.12C18.8222 19.12 21.11 16.8322 21.11 14.01C21.11 11.1878 18.8222 8.89999 16 8.89999C13.1778 8.89999 10.89 11.1878 10.89 14.01C10.89 16.8322 13.1778 19.12 16 19.12Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M14.3 13.38C14.4325 13.38 14.54 13.2725 14.54 13.14C14.54 13.0074 14.4325 12.9 14.3 12.9C14.1674 12.9 14.06 13.0074 14.06 13.14C14.06 13.2725 14.1674 13.38 14.3 13.38Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M17.7 13.38C17.8326 13.38 17.94 13.2725 17.94 13.14C17.94 13.0074 17.8326 12.9 17.7 12.9C17.5675 12.9 17.46 13.0074 17.46 13.14C17.46 13.2725 17.5675 13.38 17.7 13.38Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M14.41 16.66C14.5699 16.3794 14.8013 16.146 15.0805 15.9838C15.3598 15.8215 15.677 15.736 16 15.736C16.323 15.736 16.6403 15.8215 16.9195 15.9838C17.1988 16.146 17.4301 16.3794 17.59 16.66" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M8.07001 21.97H23.93" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 24.19H20.84" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3745_46150_${idVariant}`} x1="4.09578" y1="5.54431" x2="29.9674" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Sinistro
