import React from 'react';
import IconBase from '../IconBase/IconBase';

interface Props {
  className?: string;
}

function EyeShow({ className }: Props) {
  return (
    <IconBase name="eye-show" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.3333C15.6819 18.3333 19.0152 16.1111 22 11.6667C19.0152 7.22222 15.6819 5 12 5C8.3181 5 4.98477 7.22222 2 11.6667C4.98477 16.1111 8.3181 18.3333 12 18.3333Z"
        strokeWidth="2"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M11.9999 15.303C14.0082 15.303 15.6363 13.6749 15.6363 11.6666C15.6363 9.65833 14.0082 8.03027 11.9999 8.03027C9.99158 8.03027 8.36353 9.65833 8.36353 11.6666C8.36353 13.6749 9.99158 15.303 11.9999 15.303Z"
        strokeWidth="2"
        strokeLinecap="square"
        fill="none"
      />
    </IconBase>
  );
}

export default EyeShow;
