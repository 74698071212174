import React from 'react';
import IconBase from '../IconBase/IconBase';

interface Props {
  className?: string;
}

function Close({ className }: Props) {
  return (
    <IconBase name="close" w={24} className={className}>
      <path
        d="M20.1119 4.11194L4.11196 20.1119"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20.1119 20.1119L4.11196 4.11196"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </IconBase>
  );
}

export default Close;
