import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Bancomat({ idVariant = '', className }: Props) {
  return (
    <IconBase name="bancomat" w={32} className={className}>
      <rect x="5" y="8" width="22" height="16" rx="2" fill="#009597" stroke="none"/>
      <rect x="6" y="9" width="20" height="14" rx="1" fill={`url(#bancomat_paint0_linear_4390_86844_${idVariant})`} stroke="none"/>
      <rect x="5" y="12" width="22" height="2" fill="#ED1B2F" stroke="none"/>
      <defs>
      <linearGradient id={`bancomat_paint0_linear_4390_86844_${idVariant}`} x1="4.63014" y1="9.63014" x2="21.0332" y2="28.3924" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Bancomat
