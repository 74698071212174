import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowLeft({ className }: Props) {
  return (
    <IconBase name="arrow-left" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7929 19.7071C11.1834 20.0976 11.8166 20.0976 12.2071 19.7071C12.5976 19.3166 12.5976 18.6834 12.2071 18.2929L6.41421 12.5L19 12.5C19.5523 12.5 20 12.0523 20 11.5C20 10.9477 19.5523 10.5 19 10.5L6.41421 10.5L12.2071 4.70711C12.5976 4.31658 12.5976 3.68342 12.2071 3.29289C11.8166 2.90237 11.1834 2.90237 10.7929 3.29289L2.58579 11.5L10.7929 19.7071Z" stroke="none"/>
    </IconBase>
  )
}

export default ArrowLeft
