import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Collisione({ idVariant = '', className }: Props) {
  return (
    <IconBase name="collisione" w={32} className={className}>
      <path d="M28.16 17.1299L25.5 11.5499C25.25 11.0299 24.72 10.6899 24.14 10.6899H17.39C16.81 10.6899 16.28 11.0199 16.03 11.5499L14.48 14.8099L18.28 22.0399H25.56V23.2499C25.56 23.6499 25.88 23.9699 26.28 23.9699H27.67C28.07 23.9699 28.39 23.6499 28.39 23.2499V18.2099C28.39 17.8399 28.31 17.4699 28.15 17.1299H28.16ZM16.46 17.1099C16.12 17.1099 15.89 16.7599 16.03 16.4499L17.43 13.0299C17.57 12.6999 17.9 12.4899 18.25 12.4899H23.28C23.64 12.4899 23.96 12.6999 24.1 13.0299L25.5 16.4499C25.64 16.7599 25.41 17.1099 25.07 17.1099H16.45H16.46ZM25.14 20.6799C24.47 20.6799 23.92 20.1299 23.92 19.4599C23.92 18.7899 24.47 18.2399 25.14 18.2399C25.81 18.2399 26.36 18.7899 26.36 19.4599C26.36 20.1299 25.81 20.6799 25.14 20.6799Z" fill={`url(#paint0_linear_4967_100613_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M18.62 20.23L15.96 14.65C15.71 14.13 15.18 13.79 14.6 13.79H7.85998C7.27998 13.79 6.74998 14.12 6.49998 14.65L3.83998 20.23C3.67998 20.57 3.59998 20.93 3.59998 21.31V26.35C3.59998 26.75 3.91998 27.07 4.31998 27.07H5.70998C6.10998 27.07 6.42998 26.75 6.42998 26.35V25.14H16.03V26.35C16.03 26.75 16.35 27.07 16.75 27.07H18.14C18.54 27.07 18.86 26.75 18.86 26.35V21.31C18.86 20.94 18.78 20.57 18.62 20.23ZM6.85998 23.78C6.18998 23.78 5.63998 23.23 5.63998 22.56C5.63998 21.89 6.18998 21.34 6.85998 21.34C7.52998 21.34 8.07998 21.89 8.07998 22.56C8.07998 23.23 7.52998 23.78 6.85998 23.78ZM6.91998 20.21C6.57998 20.21 6.34998 19.86 6.48998 19.55L7.88998 16.13C8.02998 15.8 8.35998 15.59 8.70998 15.59H13.74C14.1 15.59 14.42 15.8 14.56 16.13L15.96 19.55C16.1 19.86 15.87 20.21 15.53 20.21H6.91998ZM15.6 23.78C14.93 23.78 14.38 23.23 14.38 22.56C14.38 21.89 14.93 21.34 15.6 21.34C16.27 21.34 16.82 21.89 16.82 22.56C16.82 23.23 16.27 23.78 15.6 23.78Z" fill={`url(#paint1_linear_4967_100613_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M12.58 11.5099L7.60999 9.13989" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.01 8.79998L12.77 3.97998" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.21 9.64009L10.16 6.59009" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_4967_100613_${idVariant}`} x1="13.5272" y1="11.2877" x2="28.9875" y2="24.2536" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_4967_100613_${idVariant}`} x1="2.55477" y1="14.3878" x2="18.2006" y2="28.7828" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Collisione
