/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useMeQuery as useMeQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni';
import { useMeQuery as useMeQueryVita } from '@next-nx/shared-graphql/vita';
import { useSite } from '.';
import { VITA } from '../utils/settings';

export default function useUtente() {
  const router = useRouter();
  const site = useSite();
  const useMeQuery =
    site === VITA
      ? useMeQueryVita
      : useMeQueryAssicurazioni;
  const { data, loading, refetch } = useMeQuery();
  const utente = useSelector((state: any) => state?.utente?.utente);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) dispatch({ type: 'SET_CURRENT_USER', payload: { data } });
  }, [data]);

  useEffect(() => {
    refetch();
  }, [router?.asPath]);

  const removeCurrentUser = () => {
    dispatch({ type: 'REMOVE_CURRENT_USER' });
  };

  return {
    utente: utente || (data?.me && { ...data?.me }),
    loading,
    refetchUtente: refetch,
    removeCurrentUser,
  };
}
