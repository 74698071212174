import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ProtezioneViviSmart({ idVariant = '', className }: Props) {
  return (
    <IconBase name="protezione-vivi-smart" w={32} className={className}>
      <path d="M18.67 21.58V23.19C18.67 23.3673 18.6351 23.5428 18.5673 23.7066C18.4994 23.8704 18.4 24.0193 18.2746 24.1446C18.1493 24.27 18.0004 24.3694 17.8366 24.4373C17.6729 24.5051 17.4973 24.54 17.32 24.54H14.68C14.5027 24.54 14.3272 24.5051 14.1634 24.4373C13.9996 24.3694 13.8508 24.27 13.7254 24.1446C13.6001 24.0193 13.5006 23.8704 13.4328 23.7066C13.3649 23.5428 13.33 23.3673 13.33 23.19V21.58C12.2818 21.1006 11.3819 20.3479 10.7249 19.4008C10.068 18.4537 9.67808 17.3472 9.59623 16.1975C9.51438 15.0477 9.74358 13.8971 10.2598 12.8665C10.776 11.8359 11.5601 10.9633 12.5299 10.3402C13.4996 9.7172 14.6192 9.36674 15.7712 9.32565C16.9231 9.28455 18.0648 9.55435 19.0765 10.1067C20.0882 10.6591 20.9325 11.4736 21.5208 12.4648C22.1091 13.456 22.4197 14.5874 22.42 15.74C22.4013 16.9662 22.0402 18.1627 21.3776 19.1946C20.715 20.2265 19.7772 21.0527 18.67 21.58V21.58Z" fill={`url(#paint0_linear_3744_46146_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.25 26.13H18.75" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M14.1 27.71H17.9" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16 4.29001V7.42001" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M21.01 6.24002L19.75 8.24002" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.99 6.24002L12.25 8.24002" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3744_46146_${idVariant}`} x1="8.70056" y1="10.0065" x2="25.3919" y2="21.2823" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default ProtezioneViviSmart
