import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxMixed({ className }: Props) {
  return (
    <IconBase name="checkbox-mixed" w={24} className={className}>
      <rect x="3" y="3" width="18" height="18" rx="4" fill="#EFF1F4" stroke="none"/>
      <rect x="8" y="11" width="8" height="2" rx="1" fill="currentColor" stroke="none"/>
    </IconBase>
  )
}

export default CheckboxMixed
