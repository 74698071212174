import React from 'react';
import { MessageError } from '../Icons';
import styles from './FormError.module.sass'

interface Props {
  message?: string | string[];
  visible?: boolean;
  className?: string;
}

const FormError = (props: Props) => {
  const { message = '', visible = false, className = '' } = props;

  return visible && message ? (
    <div className={`${styles.formError} ${className || ''}`}>
      <span className={styles.icon}><MessageError /></span>
      <span dangerouslySetInnerHTML={{__html: message as string }} />
    </div>
  ) : (
    <></>
  );
};

export default FormError;
