import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Add({ className }: Props) {
  return (
    <IconBase name="add" w={24} className={className}>
      <path d="M12.1119 3.0643V21.1595" strokeWidth="2" strokeLinecap="round" stroke="currentColor"/>
      <path d="M21.1595 12.1119H3.06431" strokeWidth="2" strokeLinecap="round" stroke="currentColor"/>
    </IconBase>
  )
}

export default Add
