import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Intestatario({ idVariant = '', className }: Props) {
  return (
    <IconBase name="Intestatario" w={32} className={className}>
      <path d="M16 15C18.7614 15 21 12.7614 21 10C21 7.23858 18.7614 5 16 5C13.2386 5 11 7.23858 11 10C11 12.7614 13.2386 15 16 15Z" fill="url(#paint0_linear_257_81995)" stroke="#009597" strokeLinejoin="round"/>
      <path d="M24 26H8C8 23.8783 8.84285 21.8434 10.3431 20.3431C11.8434 18.8429 13.8783 18 16 18C18.1217 18 20.1566 18.8429 21.6569 20.3431C23.1571 21.8434 24 23.8783 24 26Z" fill="url(#paint1_linear_257_81995)" stroke="#009597" strokeLinejoin="round"/>
      <path d="M15.85 21C15.0885 21 14.3442 21.2258 13.7111 21.6488C13.0779 22.0719 12.5845 22.6732 12.2931 23.3767C12.0017 24.0802 11.9254 24.8543 12.074 25.6011C12.2225 26.3479 12.5892 27.0339 13.1276 27.5724C13.6661 28.1108 14.3521 28.4775 15.0989 28.626C15.8457 28.7746 16.6198 28.6983 17.3233 28.4069C18.0268 28.1155 18.6281 27.6221 19.0512 26.9889C19.4742 26.3558 19.7 25.6115 19.7 24.85C19.7 23.8289 19.2944 22.8497 18.5724 22.1276C17.8503 21.4056 16.8711 21 15.85 21V21Z" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M15.8499 23.9004C15.662 23.9004 15.4783 23.9562 15.3221 24.0605C15.1659 24.1649 15.0441 24.3133 14.9722 24.4869C14.9003 24.6605 14.8815 24.8515 14.9182 25.0358C14.9548 25.2201 15.0453 25.3893 15.1782 25.5222C15.311 25.6551 15.4803 25.7455 15.6646 25.7822C15.8488 25.8188 16.0399 25.8 16.2135 25.7281C16.387 25.6562 16.5354 25.5345 16.6398 25.3782C16.7442 25.222 16.7999 25.0383 16.7999 24.8504C16.8012 24.7253 16.7776 24.6012 16.7303 24.4853C16.683 24.3695 16.6131 24.2642 16.5246 24.1757C16.4362 24.0872 16.3309 24.0173 16.215 23.97C16.0992 23.9228 15.975 23.8991 15.8499 23.9004Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M12 24.8496H19.7" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M15.8501 28.7096V24.8496" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <defs>
      <linearGradient id="paint0_linear_257_81995" x1="10.3151" y1="5.4501" x2="21.8513" y2="14.6869" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_257_81995" x1="6.90411" y1="18.3601" x2="15.4025" y2="31.969" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Intestatario
