import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function RadioMarked({ className }: Props) {
  return (
    <IconBase name="radio-marked" w={24} className={className}>
      <circle cx="12" cy="12" r="10" fill="currentColor" stroke="none"/>
      <circle cx="12" cy="12" r="5" fill="white" stroke="none"/>
    </IconBase>
  )
}

export default RadioMarked
