export const ASSICURAZIONI = "ASSICURAZIONI"
export const VITA = "VITA"
export const BANCA = "BANCA"
export const ASSICURAZIONI_SITE_ID = 1
export const VITA_SITE_ID = 2
export const BANCA_SITE_ID = 3
export const SITES_ID = {
    ASSICURAZIONI: ASSICURAZIONI_SITE_ID,
    VITA: VITA_SITE_ID,
    BANCA: BANCA_SITE_ID
}

export const MAGAZINE_LISTING_ITEMS_PER_PAGE = 15
export const SEARCH_LISTING_ITEMS_PER_PAGE = 15
export const SEARCH_OVERLAY_ITEMS_PER_PAGE = 4
export const FASCICOLI_LISTING_ITEMS_PER_PAGE = 15
export const FONDI_LISTING_ITEMS_PER_PAGE = 18


export const RES_SM_MIN = 768