import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Documento({ idVariant = '', className }: Props) {
  return (
    <IconBase name="documento" w={32} className={className}>
      <path d="M26.47 10.62V25.09C26.47 25.7265 26.2172 26.337 25.7671 26.7871C25.317 27.2372 24.7065 27.49 24.07 27.49H7.93003C7.29351 27.49 6.68306 27.2372 6.23297 26.7871C5.78289 26.337 5.53003 25.7265 5.53003 25.09V6.91001C5.53003 6.27349 5.78289 5.66304 6.23297 5.21295C6.68306 4.76287 7.29351 4.51001 7.93003 4.51001H19.93C20.5199 4.51156 21.0889 4.72848 21.53 5.12001L25.67 8.84001C25.9221 9.06291 26.1238 9.33693 26.2617 9.64385C26.3997 9.95076 26.4707 10.2835 26.47 10.62V10.62Z" fill={`url(#paint0_linear_3746_46170_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.07001 19H23.93" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M8.07001 16.5601H23.93" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M8.07001 14.11H23.93" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 21.4501H20.84" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M11.16 11.67H20.84" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46170_${idVariant}`} x1="4.09578" y1="5.54433" x2="29.9673" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Documento
