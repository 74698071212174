import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Sharing({ idVariant = '', className }: Props) {
  return (
    <IconBase name="sharing" w={32} className={className}>
      <g clipPath={`url(#clip0_3743_45804_${idVariant})`}>
      <path d="M21.69 20.96C22.4027 20.4084 23.2557 20.0677 24.1523 19.9765C25.0488 19.8853 25.953 20.0473 26.7621 20.4441C27.5712 20.8408 28.2529 21.4565 28.7298 22.2211C29.2067 22.9858 29.4597 23.8688 29.46 24.77H22.73" fill={`url(#paint0_linear_3743_45804_${idVariant})`} stroke="none"/>
      <path d="M21.69 20.96C22.4027 20.4084 23.2557 20.0677 24.1523 19.9765C25.0488 19.8853 25.953 20.0473 26.7621 20.4441C27.5712 20.8408 28.2529 21.4565 28.7298 22.2211C29.2067 22.9858 29.4597 23.8688 29.46 24.77H22.73" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M16 16.16C18.3914 16.16 20.33 14.2214 20.33 11.83C20.33 9.43861 18.3914 7.5 16 7.5C13.6086 7.5 11.67 9.43861 11.67 11.83C11.67 14.2214 13.6086 16.16 16 16.16Z" fill={`url(#paint1_linear_3743_45804_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M24.72 17.85C26.3713 17.85 27.71 16.5113 27.71 14.86C27.71 13.2087 26.3713 11.87 24.72 11.87C23.0686 11.87 21.73 13.2087 21.73 14.86C21.73 16.5113 23.0686 17.85 24.72 17.85Z" fill={`url(#paint2_linear_3743_45804_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M7.31001 17.81C8.96134 17.81 10.3 16.4713 10.3 14.82C10.3 13.1687 8.96134 11.83 7.31001 11.83C5.65868 11.83 4.32001 13.1687 4.32001 14.82C4.32001 16.4713 5.65868 17.81 7.31001 17.81Z" fill={`url(#paint3_linear_3743_45804_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M9.27 24.77H2.5C2.4997 23.8653 2.75402 22.9788 3.23388 22.2119C3.71374 21.4449 4.39976 20.8285 5.21348 20.4331C6.0272 20.0377 6.93578 19.8793 7.83529 19.9761C8.7348 20.0728 9.58893 20.4207 10.3 20.98" fill={`url(#paint4_linear_3743_45804_${idVariant})`} stroke="none"/>
      <path d="M9.27 24.77H2.5C2.4997 23.8653 2.75402 22.9788 3.23388 22.2119C3.71374 21.4449 4.39976 20.8285 5.21348 20.4331C6.0272 20.0377 6.93578 19.8793 7.83529 19.9761C8.7348 20.0728 9.58893 20.4207 10.3 20.98" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M22.73 24.77H9.27002C9.27002 22.9851 9.97907 21.2733 11.2412 20.0112C12.5033 18.749 14.2151 18.04 16 18.04C17.7849 18.04 19.4967 18.749 20.7588 20.0112C22.021 21.2733 22.73 22.9851 22.73 24.77V24.77Z" fill={`url(#paint5_linear_3743_45804_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M15.98 20.92C15.2185 20.92 14.4742 21.1458 13.8411 21.5688C13.2079 21.9919 12.7145 22.5932 12.4231 23.2967C12.1317 24.0001 12.0554 24.7743 12.204 25.5211C12.3525 26.2679 12.7192 26.9539 13.2576 27.4923C13.7961 28.0308 14.4821 28.3975 15.2289 28.546C15.9757 28.6946 16.7498 28.6183 17.4533 28.3269C18.1568 28.0355 18.7581 27.5421 19.1812 26.9089C19.6042 26.2758 19.83 25.5314 19.83 24.77C19.83 23.7489 19.4244 22.7696 18.7024 22.0476C17.9804 21.3256 17.0011 20.92 15.98 20.92V20.92Z" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      <path d="M15.98 23.82C15.7921 23.82 15.6085 23.8757 15.4522 23.9801C15.296 24.0845 15.1742 24.2329 15.1023 24.4065C15.0304 24.58 15.0116 24.7711 15.0483 24.9553C15.0849 25.1396 15.1754 25.3089 15.3083 25.4418C15.4411 25.5746 15.6104 25.6651 15.7947 25.7017C15.979 25.7384 16.17 25.7196 16.3436 25.6477C16.5172 25.5758 16.6655 25.454 16.7699 25.2978C16.8743 25.1416 16.93 24.9579 16.93 24.77C16.9314 24.6449 16.9077 24.5207 16.8604 24.4049C16.8132 24.289 16.7432 24.1837 16.6548 24.0953C16.5663 24.0068 16.461 23.9369 16.3452 23.8896C16.2293 23.8423 16.1052 23.8187 15.98 23.82V23.82Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M12.13 24.77H19.83" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      <path d="M15.98 28.63V24.77" stroke="#ED1B2F" strokeMiterlimit="10" fill="none"/>
      </g>
      <defs>
      <linearGradient id={`paint0_linear_3743_45804_${idVariant}`} x1="21.1578" y1="20.1686" x2="26.673" y2="27.2898" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3743_45804_${idVariant}`} x1="11.0768" y1="7.88978" x2="21.0672" y2="15.8888" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_3743_45804_${idVariant}`} x1="21.3204" y1="12.1392" x2="28.219" y2="17.6628" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint3_linear_3743_45804_${idVariant}`} x1="3.91042" y1="12.0992" x2="10.8091" y2="17.6228" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint4_linear_3743_45804_${idVariant}`} x1="1.96575" y1="20.1654" x2="7.48045" y2="27.3085" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint5_linear_3743_45804_${idVariant}`} x1="8.3481" y1="18.3429" x2="15.4973" y2="29.7914" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <clipPath id={`clip0_3743_45804_${idVariant}`}>
      <rect width="27.96" height="22.13" fill="white" transform="translate(2 7)" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Sharing
