import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Flessibile({ idVariant = '', className }: Props) {
  return (
    <IconBase name="flessibile" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3932_47555_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M16 24.09C16 24.09 11.2 15.67 19.39 10.29" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M17.15 9.18994L20.04 9.89994L19.33 12.7999" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16 24.09C16 24.09 20.8 15.67 12.61 10.29" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M14.85 9.18994L11.96 9.89994L12.67 12.7999" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47555_${idVariant}`} x1="3.91383" y1="6.3269" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Flessibile
