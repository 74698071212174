import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Minus({ className }: Props) {
  return (
    <IconBase name="minus" w={24} className={className}>
      <rect x="5" y="11" width="14" height="2" rx="1" stroke="none"/>
    </IconBase>
  )
}

export default Minus
