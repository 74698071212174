import React, { ReactNode } from 'react'
import styles from './Tab.module.sass'

export interface TriggerProps {
  icon?: ReactNode
  label?: string
  size?: 'md' | 'lg'
  active?: boolean
  bgColor?: '' | 'primary'
  onClick?: () => void
}

export interface Props {
  active?: boolean
  banner?: string
  className?: string
  children?: ReactNode
}

const TabTrigger = (props: TriggerProps) => {
  const { icon = null, label = '', size = 'md', active = false, bgColor = '', onClick = undefined} = props

  return (
    <button
      type="button"
      className={`${styles.tabTrigger} ${bgColor ? styles[`tabTrigger--${bgColor}`] : ''} ${size ? styles[`tabTrigger--${size}`] : ''} ${active ? styles["tabTrigger--active"] : ''}`}
      onClick={onClick}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.label}>{label}</span>}
    </button>
  )
}

const Tab = (props: Props) => {
  const { active = false, banner = "", className = '', children = null } = props

  return active ? (
    <div className={`${styles.tab} ${active ? styles["tab--active"] : ''} ${className}`}>
      {!!banner && <div className={styles.banner}><span className={styles.bannerContent}>{banner}</span></div> }
      {children}
    </div>
  ) : (
    <></>
  )
}

Tab.Trigger = TabTrigger

export default Tab
