import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function LogoAssicurazioni({ className }: Props) {
  return (
    <IconBase name="logo-assicurazioni" w={278} h={33} className={className}>
      <path d="M64.5595 3.75871C66.7115 3.75871 68.9613 4.39332 70.8199 5.71136L69.4015 7.51757C67.8853 6.49242 66.6626 5.90663 64.7551 5.90663C61.4782 5.90663 58.886 7.95692 58.886 10.9347C58.886 13.9125 61.4782 15.9628 64.7551 15.9628C66.7604 15.9628 68.0809 15.3282 69.6949 14.2542L71.1133 16.0604C69.2548 17.3785 66.8582 18.1107 64.6084 18.1107C60.0598 18.1107 55.8047 15.4258 55.8047 10.8859C55.8047 6.29716 60.1087 3.66107 64.6084 3.66107L64.5595 3.75871Z" fill="#256F58"/>
      <path d="M81.3853 10.9351C83.4884 10.9351 84.8579 10.0564 84.8579 8.54307C84.8579 7.07857 83.5373 6.2487 81.3364 6.2487H76.9835V10.9351H81.3364H81.3853ZM74 4.00314H81.581C85.1024 4.00314 87.9392 5.41882 87.9392 8.49425C87.9392 10.8862 86.1784 12.1555 83.7819 12.6925L88.3793 17.9646H84.8579L80.7006 13.083H76.9835V17.9646H74V4.00314Z" fill="#256F58"/>
      <path d="M100.997 0L98.6978 2.44082H96.1545L97.9153 0H101.045H100.997ZM91.3125 13.9127V3.95413H103.98V6.19968H94.296V9.56801H103.197V11.7648H94.296V13.9127C94.296 15.1331 94.8829 15.7189 96.0567 15.7189H104.273V17.9644H95.372C92.8287 17.9644 91.3125 16.4511 91.3125 13.9127Z" fill="#256F58"/>
      <path d="M114.399 15.7679C117.823 15.7679 120.073 13.864 120.073 11.0327C120.073 8.20135 117.774 6.2487 114.399 6.2487H110.878V15.7679H114.399ZM107.895 4.00314H114.204C119.584 4.00314 123.203 6.78568 123.203 10.9839C123.203 15.1821 119.584 17.9646 114.204 17.9646H107.895V4.00314Z" fill="#256F58"/>
      <path d="M126.383 4.00314V18.0134H129.366V4.00314H126.383Z" fill="#256F58"/>
      <path d="M146.091 6.2487H140.711V18.0134H137.728V6.2487H132.348V4.00314H146.091V6.2487Z" fill="#256F58"/>
      <path d="M163.452 12.448L160.714 6.88293L157.975 12.448H163.501H163.452ZM164.577 14.6447H156.85L155.187 18.0131H152.105L159.344 4.05157H162.425L169.664 18.0131H166.24L164.577 14.6447Z" fill="#256F58"/>
      <path d="M179.351 3.75893C181.503 3.75893 183.752 4.39355 185.611 5.71159L184.193 7.51779C182.676 6.49265 181.454 5.90685 179.546 5.90685C176.269 5.90685 173.677 7.90832 173.677 10.9349C173.677 13.9127 175.976 16.0118 179.448 16.0118C181.013 16.0118 182.432 15.5725 183.41 14.9379V12.253H179.204V10.1051H186.442V15.963C184.779 17.1834 182.383 18.1598 179.351 18.1598C174.068 18.1598 170.547 15.0355 170.547 10.9349C170.547 6.3462 174.851 3.71011 179.351 3.71011V3.75893Z" fill="#256F58"/>
      <path d="M197.542 10.9351C199.645 10.9351 201.014 10.0564 201.014 8.54307C201.014 7.07857 199.694 6.2487 197.493 6.2487H193.14V10.9351H197.493H197.542ZM190.156 4.00314H197.737C201.259 4.00314 204.095 5.41882 204.095 8.49425C204.095 10.8862 202.335 12.1555 199.938 12.6925L204.536 17.9646H201.014L196.857 13.083H193.14V17.9646H190.156V4.00314Z" fill="#256F58"/>
      <path d="M207.961 4.00314V18.0134H210.944V4.00314H207.961Z" fill="#256F58"/>
      <path d="M223.173 3.75871C225.325 3.75871 227.575 4.39332 229.433 5.71136L228.015 7.51757C226.499 6.49242 225.276 5.90663 223.368 5.90663C220.043 5.90663 217.499 7.95692 217.499 10.9347C217.499 13.9125 220.091 15.9628 223.368 15.9628C225.374 15.9628 226.694 15.3282 228.308 14.2542L229.727 16.0604C227.868 17.3785 225.471 18.1107 223.222 18.1107C218.673 18.1107 214.418 15.4258 214.418 10.8859C214.418 6.29716 218.722 3.66107 223.222 3.66107L223.173 3.75871Z" fill="#256F58"/>
      <path d="M233.884 10.9834C233.884 13.8147 236.232 16.0115 239.607 16.0115C243.03 16.0115 245.329 13.7659 245.329 10.9834C245.329 8.15202 242.981 5.95528 239.607 5.95528C236.183 5.95528 233.884 8.20084 233.884 10.9834ZM230.754 10.9834C230.754 7.02925 234.422 3.75854 239.607 3.75854C244.791 3.75854 248.459 7.02925 248.459 10.9834C248.459 14.9375 244.791 18.2082 239.607 18.2082C234.422 18.2082 230.754 14.9375 230.754 10.9834Z" fill="#256F58"/>
      <path d="M251.246 18.0134V4.00314H254.23V15.7679H263.131V18.0134H251.246Z" fill="#256F58"/>
      <path d="M265.039 13.9617V4.00314H277.707V6.2487H268.023V9.61703H276.924V11.8138H268.023V13.9617C268.023 15.1821 268.609 15.7679 269.783 15.7679H278V18.0134H269.099C266.555 18.0134 265.039 16.5001 265.039 13.9617Z" fill="#256F58"/>
      <path d="M65.0474 28.6549L62.9932 24.5056L60.939 28.6549H65.0963H65.0474ZM65.8789 30.3147H60.0587L58.787 32.8531H56.4883L61.9661 22.3576H64.2649L69.7427 32.8531H67.1505L65.8789 30.3147Z" fill="#256F58"/>
      <path d="M70.3302 31.3399L71.4062 29.8266C72.7756 30.8518 74.6342 31.2911 76.2482 31.2911C77.9111 31.2911 79.2806 30.803 79.2806 30.0219C79.2806 29.192 77.7644 28.8991 76.0036 28.5086C73.6071 28.0204 70.7214 27.3858 70.7214 25.2867C70.7214 23.3341 73.1669 22.1136 75.9547 22.1136C77.8622 22.1136 79.8675 22.6506 81.2859 23.627L80.2099 25.1403C79.036 24.2616 77.422 23.7734 75.9547 23.7734C74.4385 23.7734 73.0202 24.2616 73.0202 24.9938C73.0202 25.6772 74.3896 25.9701 76.0526 26.3119C78.498 26.8 81.5304 27.4346 81.5304 29.729C81.5304 31.7793 79.036 32.9997 76.1993 32.9997C73.9984 32.9997 71.8463 32.3651 70.2812 31.3399H70.3302Z" fill="#256F58"/>
      <path d="M83.4395 31.3399L84.5155 29.8266C85.885 30.8518 87.7436 31.2911 89.3576 31.2911C91.0205 31.2911 92.39 30.803 92.39 30.0219C92.39 29.192 90.8738 28.8991 89.113 28.5086C86.7165 28.0204 83.8308 27.3858 83.8308 25.2867C83.8308 23.3341 86.2763 22.1136 89.0641 22.1136C90.9716 22.1136 92.9769 22.6506 94.3952 23.627L93.3192 25.1403C92.1454 24.2616 90.5314 23.7734 89.0641 23.7734C87.5479 23.7734 86.1295 24.2616 86.1295 24.9938C86.1295 25.6772 87.499 25.9701 89.1619 26.3119C91.6074 26.8 94.6398 27.4346 94.6398 29.729C94.6398 31.7793 92.1454 32.9997 89.3087 32.9997C87.1077 32.9997 84.9557 32.3651 83.3906 31.3399H83.4395Z" fill="#256F58"/>
      <path d="M97.375 22.3092V32.8047H99.6248V22.3092H97.375Z" fill="#256F58"/>
      <path d="M108.48 22.1136C110.094 22.1136 111.806 22.6018 113.175 23.5781L112.099 24.945C110.974 24.1639 110.045 23.7246 108.578 23.7246C106.083 23.7246 104.176 25.2867 104.176 27.5323C104.176 29.7778 106.132 31.3399 108.578 31.3399C110.094 31.3399 111.072 30.8518 112.295 30.0707L113.371 31.4376C111.952 32.4627 110.192 32.9997 108.48 32.9997C105.056 32.9997 101.828 30.9982 101.828 27.5323C101.828 24.1151 105.056 22.1136 108.48 22.1136Z" fill="#256F58"/>
      <path d="M115.229 28.0695V22.3092H117.478V28.216C117.478 30.071 118.799 31.3402 120.755 31.3402C122.712 31.3402 124.032 30.071 124.032 28.216V22.3092H126.233V28.0695C126.233 30.9985 124.032 33 120.706 33C117.381 33 115.18 31.0473 115.18 28.0695H115.229Z" fill="#256F58"/>
      <path d="M134.451 27.4835C136.065 27.4835 137.092 26.8 137.092 25.6773C137.092 24.5545 136.114 23.9687 134.451 23.9687H131.174V27.4835H134.451ZM128.924 22.2601H134.646C137.287 22.2601 139.439 23.3341 139.439 25.6285C139.439 27.4347 138.119 28.3622 136.309 28.8015L139.733 32.7556H137.092L133.962 29.0944H131.125V32.7556H128.875V22.2601H128.924Z" fill="#256F58"/>
      <path d="M148.586 28.6549L146.532 24.5056L144.478 28.6549H148.635H148.586ZM149.418 30.3147H143.598L142.326 32.8531H140.027L145.505 22.3576H147.804L153.282 32.8531H150.69L149.418 30.3147Z" fill="#256F58"/>
      <path d="M154.016 32.8047V31.2914L161.156 23.969H154.211V22.3092H164.14V23.8225L156.999 31.145H164.287V32.8047H154.016Z" fill="#256F58"/>
      <path d="M166.73 22.3092V32.8047H168.98V22.3092H166.73Z" fill="#256F58"/>
      <path d="M173.527 27.5323C173.527 29.6314 175.288 31.3399 177.831 31.3399C180.375 31.3399 182.135 29.6802 182.135 27.5323C182.135 25.4332 180.375 23.7246 177.831 23.7246C175.288 23.7246 173.527 25.3843 173.527 27.5323ZM171.18 27.5323C171.18 24.5545 173.968 22.1136 177.831 22.1136C181.744 22.1136 184.483 24.5545 184.483 27.5323C184.483 30.5101 181.695 32.9509 177.831 32.9509C173.919 32.9509 171.18 30.5101 171.18 27.5323Z" fill="#256F58"/>
      <path d="M188.789 32.8047H186.539V22.3092H188.789L195.245 29.7781V22.3092H197.495V32.8047H195.245L188.789 25.3358V32.8047Z" fill="#256F58"/>
      <path d="M200.477 22.3092V32.8047H202.775V22.3092H200.477Z" fill="#256F58"/>
      <path d="M0.488281 29.1434V32.8046H41.7678V29.1434H0.488281Z" fill="#256F58"/>
      <path d="M26.7534 9.07966L29.5412 16.0604L34.4811 13.4731L30.2259 4.05157H24.0145L15.3086 20.2586C15.3086 20.2586 19.6126 20.1122 22.3515 18.0619C23.0363 17.6713 24.9926 12.5456 26.8023 9.07966H26.7534Z" fill="#256F58"/>
      <path d="M34.4307 13.4248L29.4909 16.012C25.2847 18.16 21.2741 20.0638 19.1221 20.6496C13.4486 22.2605 6.50349 23.2857 4.00912 21.089C1.66147 19.0387 7.09041 10.3494 17.2636 8.3967C18.9265 8.00617 19.9047 8.10381 19.9047 8.15262C19.9047 8.15262 19.2199 5.17482 18.6819 4.54021C17.8994 3.6127 17.1657 3.41743 16.0408 3.56388C13.8399 3.71033 4.40039 6.19997 0.732188 14.0106C-1.32201 18.4529 1.41692 22.944 3.17766 24.262C4.00912 24.8478 9.34024 29.6318 21.7143 24.799C33.8927 20.0638 44.7995 12.6437 44.7995 12.6437V7.66446C44.7995 7.66446 40.0064 10.3982 34.4307 13.376V13.4248Z" fill="#2E9095"/>
      <path d="M31.25 20.552C31.6902 21.626 33.0107 24.7014 33.3531 25.6289C33.7933 26.6052 34.8693 26.6052 34.8693 26.6052H40.5428L36.3366 17.8671C34.7715 18.7458 33.0596 19.6733 31.25 20.552Z" fill="#256F58"/>
      <path d="M44.8487 5.76034C44.8487 5.76034 38.3437 9.47038 36.8275 10.0562C35.4092 10.5932 34.9201 9.86091 34.7244 9.61683C34.3332 9.03103 33.7463 7.17602 33.6484 6.88312C33.6973 6.88312 34.0397 6.98075 34.8712 6.73667C35.8494 6.49258 44.7998 1.46451 44.8487 1.51332V5.76034Z" fill="#ED3239"/>
    </IconBase>
  )
}

export default LogoAssicurazioni
