import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function CyberRisk({ idVariant = '', className }: Props) {
  return (
    <IconBase name="cyber-risk" w={32} className={className}>
      <path d="M10.97 20.48H5.42999" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M4.34002 21.36C4.82603 21.36 5.22002 20.966 5.22002 20.48C5.22002 19.994 4.82603 19.6 4.34002 19.6C3.85401 19.6 3.46002 19.994 3.46002 20.48C3.46002 20.966 3.85401 21.36 4.34002 21.36Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.30999 26.91C6.796 26.91 7.18999 26.516 7.18999 26.03C7.18999 25.544 6.796 25.15 6.30999 25.15C5.82398 25.15 5.42999 25.544 5.42999 26.03C5.42999 26.516 5.82398 26.91 6.30999 26.91Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.13 15.45C4.61601 15.45 5.01 15.056 5.01 14.57C5.01 14.084 4.61601 13.69 4.13 13.69C3.64399 13.69 3.25 14.084 3.25 14.57C3.25 15.056 3.64399 15.45 4.13 15.45Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.13001 17.55H6.80001L3.95001 14.57" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.32 22.68H9.38998L6.53998 25.85" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M21.03 20.48H26.57" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M27.66 21.36C28.146 21.36 28.54 20.966 28.54 20.48C28.54 19.994 28.146 19.6 27.66 19.6C27.174 19.6 26.78 19.994 26.78 20.48C26.78 20.966 27.174 21.36 27.66 21.36Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.69 26.91C26.176 26.91 26.57 26.516 26.57 26.03C26.57 25.544 26.176 25.15 25.69 25.15C25.204 25.15 24.81 25.544 24.81 26.03C24.81 26.516 25.204 26.91 25.69 26.91Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.87 15.45C28.356 15.45 28.75 15.056 28.75 14.57C28.75 14.084 28.356 13.69 27.87 13.69C27.384 13.69 26.99 14.084 26.99 14.57C26.99 15.056 27.384 15.45 27.87 15.45Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.88 17.55H25.2L28.05 14.57" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M18.68 22.68H22.61L25.46 25.85" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.09 24.06C21.68 22.33 25.09 15.63 24.78 8.77C21.6531 8.1803 18.6895 6.92528 16.09 5.09C13.4935 6.92397 10.5335 8.17893 7.41002 8.77C7.06002 15.63 10.51 22.33 16.09 24.06Z" fill={`url(#paint0_linear_3744_46145_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.09 21.35C20.09 20.12 22.55 15.35 22.3 10.43C20.0671 10.003 17.9504 9.10656 16.09 7.79999C14.2304 9.1079 12.1133 10.0045 9.88 10.43C9.64 15.33 12.1 20.12 16.09 21.35Z" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3744_46145_${idVariant}`} x1="6.19295" y1="5.94383" x2="27.5975" y2="21.6761" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default CyberRisk
