import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Conveniente({ idVariant = '', className }: Props) {
  return (
    <IconBase name="conveniente" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3746_46165_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M22.44 12.63L14.94 20.13L10.57 15.77" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46165_${idVariant}`} x1="3.91383" y1="6.3269" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Conveniente
