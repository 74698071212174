import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Furto({ idVariant = '', className }: Props) {
  return (
    <IconBase name="furto" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3932_47575_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M23.82 13.74C23.9146 14.7962 23.7854 15.8605 23.4408 16.8633C23.0962 17.8662 22.5439 18.7851 21.82 19.56L16 17L23.82 13.74Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.15 17L8.32997 13.74C8.2366 14.7961 8.36641 15.86 8.71097 16.8627C9.05553 17.8654 9.60714 18.7844 10.33 19.56L16.15 17Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47575_${idVariant}`} x1="3.91383" y1="6.3269" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Furto
