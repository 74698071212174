import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Beni({ idVariant = '', className }: Props) {
  return (
    <IconBase name="beni" w={32} className={className}>
      <path d="M25.5001 7.78003L21.9901 11.3" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M18.7 14.59L8.30005 24.98" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M10.05 15.09C10.0722 14.0269 9.87898 12.9703 9.48198 11.9838C9.08499 10.9973 8.49244 10.1014 7.74004 9.35001C7.33456 8.94869 6.88888 8.59013 6.41004 8.28001H6.35004C8.86597 6.0212 12.1523 4.8131 15.5322 4.90449C18.9121 4.99588 22.1283 6.37981 24.5185 8.77129C26.9087 11.1628 28.2909 14.3797 28.3805 17.7597C28.4701 21.1396 27.2602 24.4253 25 26.94V26.87C24.6855 26.3858 24.3201 25.9365 23.91 25.53C23.1622 24.7778 22.2699 24.1845 21.287 23.7858C20.304 23.3871 19.2506 23.1913 18.19 23.21V23.21C18.2139 22.1335 18.0175 21.0634 17.6128 20.0656C17.2082 19.0677 16.6038 18.1632 15.8368 17.4074C15.0697 16.6516 14.1563 16.0606 13.1526 15.6707C12.1489 15.2808 11.0761 15.1002 10 15.14L10.05 15.09Z" fill={`url(#paint0_linear_3743_45721_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M8.30003 25C7.80275 25.4973 7.12829 25.7766 6.42503 25.7766C5.72177 25.7766 5.04731 25.4973 4.55003 25C4.05275 24.5027 3.77338 23.8283 3.77338 23.125C3.77338 22.4217 4.05275 21.7473 4.55003 21.25" fill="white" stroke="none"/>
      <path d="M8.30003 25C7.80275 25.4973 7.12829 25.7766 6.42503 25.7766C5.72177 25.7766 5.04731 25.4973 4.55003 25C4.05275 24.5027 3.77338 23.8283 3.77338 23.125C3.77338 22.4217 4.05275 21.7473 4.55003 21.25" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10 15.14C10 15.14 15.8 7.52003 24.36 8.93003" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M18.14 23.3C18.14 23.3 25.77 17.48 24.36 8.92999" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45721_${idVariant}`} x1="4.84078" y1="5.89169" x2="30.2657" y2="26.244" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Beni
