import React from 'react'
import { PDF } from '@next-nx/shared-ui/icons'
import styles from './PdfLink.module.sass'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  name?: string
  url?: string
  className?: string
}

const PdfLink = (props: Props) => {

  const {
    name = '',
    url = '',
    className = '',
  } = props

  return url ? (
    <a className={`${styles.pdfLink} ${className ? ` ${className}` : ''}`} href={url} download onClick={() => sendGTMData({
      event: "GAevent",
      eventCategory: 'allegati',
      eventAction: name?.toGTMFormat(),
      eventLabel: 'download'
    })}>
      <span className={styles.icon}><PDF /></span>
      {!!name && <span className={styles.label}>{name}</span>}
    </a>
  ) : <></>
}

export default PdfLink
