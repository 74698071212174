import React, { ReactNode } from 'react'
import Link from 'next/link'
import { Loader } from '../Icons'
import styles from './Chip.module.sass'

interface Props {
  label?: string
  labelHtml?: string
  variant?: 'dark' | 'light' | 'blue' | 'neutral'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  weight?: 'bold' | 'regular'
  active?: boolean
  responsive?: boolean
  disabled?: boolean
  leftIcon?: ReactNode // Se c'è solo l'icona senza label, si usa leftIcon come unica icona (non supportato il caso con 2 icone senza label)
  rightIcon?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
}

const Chip = (props: Props) => {
  const {
    label = '',
    labelHtml = '',
    variant = 'dark',
    size = 'md',
    weight = 'bold',
    active = false,
    responsive = false,
    disabled = false,
    leftIcon = undefined,
    rightIcon = undefined,
    onClick = undefined,
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
  } = props

  const classeNames = `
    ${styles.chip}
    ${styles[`chip--${variant}`]}
    ${styles[`chip--${size}`]}
    ${styles[`chip--fw-${weight}`]}
    ${onClick || href ? '' : styles[`chip--no-effects`]}
    ${active ? styles[`chip--active`] : ''}
    ${responsive ? styles['chip--resposive'] : ''}
    ${loading ? styles['chip--loading'] : ''}
    ${className ? ` ${className}` : ''}`

  const content = (
    <>
      {/* se non c'è la label, considero la leftIcon come l'unica icona */}
      {leftIcon && (
        <span className={`${styles.icon} ${label ? styles['icon--left'] : ''}`}>{leftIcon}</span>
      )}
      {label && <span className={styles.label}>{label}</span>}
      {labelHtml && !label && (
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: labelHtml }} />
      )}
      {rightIcon && <span className={`${styles.icon} ${styles['icon--right']}`}>{rightIcon}</span>}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : undefined}
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {content}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a className={classeNames} onClick={onClick}>
          {content}
        </a>
      </Link>
    )
  ) : (
    <button type="button" className={classeNames} disabled={disabled} onClick={onClick}>
      {content}
    </button>
  )
}

export default Chip
