import axios from 'axios'

const ENV_API_HOST = process.env.API_HOST

export const API_HOST = ENV_API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`
export const ADMIN_API_PREFIX = '/d/admin/api/'
export const ADMIN_API_FULL_PATH = `${API_HOST}${ADMIN_API_PREFIX}`

export const GRAPHENE_HOST = API_HOST
export const GRAPHENE_PREFIX = '/d/graphql/'
export const GRAPHENE_FULL_PATH = `${GRAPHENE_HOST}${GRAPHENE_PREFIX}`
export const GRAPEHENE_GET_PREFIX = '/d/graphql-get/'
export const GRAPHENE_GET_FULL_PATH = `${GRAPHENE_HOST}${GRAPEHENE_GET_PREFIX}`

export const graphene = axios.create({
  baseURL: GRAPHENE_FULL_PATH,
  withCredentials: true,
})

export const graphene_get = axios.create({
  baseURL: GRAPHENE_GET_FULL_PATH,
  withCredentials: true,
})

export const api = axios.create({
  baseURL: API_FULL_PATH,
  withCredentials: true,
})

export const adminApi = axios.create({
  baseURL: ADMIN_API_FULL_PATH,
  withCredentials: true,
})
