import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function FAQ({ idVariant = '', className }: Props) {
  return (
    <IconBase name="f-a-q" w={32} className={className}>
      <path d="M16 26.63C21.8708 26.63 26.63 21.8708 26.63 16C26.63 10.1292 21.8708 5.37 16 5.37C10.1292 5.37 5.37 10.1292 5.37 16C5.37 21.8708 10.1292 26.63 16 26.63Z" fill={`url(#paint0_linear_3746_46151_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M12.74 12.32C12.9738 11.7518 13.3576 11.2578 13.8505 10.8909C14.3433 10.5239 14.9266 10.2979 15.538 10.2368C16.1494 10.1757 16.7659 10.282 17.3216 10.5442C17.8773 10.8064 18.3512 11.2147 18.6928 11.7254C19.0344 12.2361 19.2308 12.8301 19.2609 13.4438C19.291 14.0575 19.1538 14.6678 18.8638 15.2096C18.5739 15.7513 18.1422 16.2041 17.6149 16.5194C17.0876 16.8348 16.4844 17.0009 15.87 17V19.12" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15.86 21.75C16.0202 21.75 16.15 21.6202 16.15 21.46C16.15 21.2998 16.0202 21.17 15.86 21.17C15.6998 21.17 15.57 21.2998 15.57 21.46C15.57 21.6202 15.6998 21.75 15.86 21.75Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46151_${idVariant}`} x1="3.91383" y1="6.32691" x2="28.4398" y2="25.9643" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default FAQ
