import React, { Component } from 'react'
import { useTrans } from '@next-nx/hooks'
import { Close } from '@next-nx/shared-ui/icons'
import styles from './NotificationBar.module.sass'

interface Props {
  label: string
  icon?: React.ReactElement
  variant?: 'alert' | 'success'
  size?: 'sm' | 'lg'
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const NotificationBar = (props: Props) => {
  const {
    label = '',
    icon = null,
    variant = 'alert',
    size = 'lg',
    onClose = undefined,
  } = props;

  const t = useTrans()

  return (
    <div className={`${styles.notificationBar} ${styles[`notificationBar--${variant}`]} ${styles[`notificationBar--${size}`]}`}>
      {!!icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{label}</span>
      {!!onClose && (
        <button
          className={styles.close}
          type="button"
          aria-label={t("Chiudi")}
          onClick={onClose}
        >
          <Close />
        </button>
      )}
    </div>
  );
};

export default NotificationBar;
