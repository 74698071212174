import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronUp({ className }: Props) {
  return (
    <IconBase name="chevron-up" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7682 15.9419C18.4146 16.3662 17.7841 16.4235 17.3598 16.07L12 11.6034L6.64016 16.07C6.21588 16.4235 5.58532 16.3662 5.23175 15.9419C4.87819 15.5176 4.93551 14.8871 5.35979 14.5335L12 9.00002L18.6402 14.5335C19.0644 14.8871 19.1218 15.5176 18.7682 15.9419Z" stroke="none" />
    </IconBase>
  )
}

export default ChevronUp
