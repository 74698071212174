import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Bike({ idVariant = '', className }: Props) {
  return (
    <IconBase name="bike" w={32} className={className}>
      <path d="M8.11 23.76C10.9322 23.76 13.22 21.4722 13.22 18.65C13.22 15.8278 10.9322 13.54 8.11 13.54C5.28782 13.54 3 15.8278 3 18.65C3 21.4722 5.28782 23.76 8.11 23.76Z" fill={`url(#paint0_linear_3744_46144_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.89 23.76C26.7122 23.76 29 21.4722 29 18.65C29 15.8278 26.7122 13.54 23.89 13.54C21.0679 13.54 18.78 15.8278 18.78 18.65C18.78 21.4722 21.0679 23.76 23.89 23.76Z" fill={`url(#paint1_linear_3744_46144_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.89 8.22998L18.86 9.91998L23.89 18.84" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M20 12.21L16.19 18.84H8.53003L12.19 12.21L16.05 18.58" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M9.66998 11.6H14.16" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3744_46144_${idVariant}`} x1="2.3" y1="14" x2="14.09" y2="23.44" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3744_46144_${idVariant}`} x1="18.08" y1="14" x2="29.87" y2="23.44" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Bike
