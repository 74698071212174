import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxHover({ className }: Props) {
  return (
    <IconBase name="checkbox-hover" w={24} className={className}>
      <rect x="3.5" y="3.5" width="17" height="17" rx="3.5" fill="#EFF1F4" stroke="currentColor" />
    </IconBase>
  )
}

export default CheckboxHover
