import React from 'react'
import { FormCheckbox } from '@next-nx/shared-ui/atoms'


interface Props {
  label: string
  value: string
  selection: string|null|undefined
  handleTipologiaFilter: (v: string|null|undefined) => void 
  className?: string
}

const FiltroValorizzazioni = (props: Props) => {

  const { 
    label = '',
    value = '',
    selection = [],
    handleTipologiaFilter = () => undefined,
    className = '',
  } = props

  return (
    <div className={className}>
      <FormCheckbox 
        id={value}
        radioStyle
        name={value}
        label={label}
        checked={selection === value}
        onChange={(e) => handleTipologiaFilter(e?.target?.checked ? value : null)}
      />
    </div>
  )
}

export default FiltroValorizzazioni
