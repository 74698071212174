/* eslint-disable @typescript-eslint/ban-ts-comment */
import App, { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '../client'
import Head from 'next/head'
import './styles.css'
import '../../../libs/shared-ui/styles/global.sass'
import '../../../libs/pagebuilder/src/lib/styles/global.sass'
import { fetchTraduzioni, setPagina } from '@next-nx/shared-containers'
import { wrapper } from '../store'
import { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useUtente } from '@next-nx/hooks'
import { Loader } from '@next-nx/shared-ui/atoms'
import { isEmpty } from '@next-nx/utils/safe'
import { api, graphene } from '@next-nx/utils'
import { MENU_GET } from '@next-nx/shared-graphql'
import ChatBotProvider from 'libs/shared-ui/contexts/chatBotContext'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { initGTMdataLayer, updateGTMDataLayer } from 'libs/shared-ui/utils/gtm'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../public/nprogress.css'
import { REDIRECTS, CHECK_REVALIDATE } from '@next-nx/utils/endpoints'

interface CustomAppProps {
  cookies?: any
}

const PageWrapper = ({ loginRequired = false, pageProps, children }) => {
  const router = useRouter()
  const { pagina } = useSelector((state: any) => state?.pagina)
  const { utente, loading } = useUtente()
  const dispatch = useDispatch()

  useEffect(() => {
    initGTMdataLayer(ASSICURAZIONI)
  }, [])

  useEffect(() => {
    if (!loading && !isEmpty(pagina)) {
      updateGTMDataLayer(pagina, utente, router)
    }
  }, [utente, loading, pagina])

  useEffect(() => {
    if (isEmpty(pageProps)) {
      dispatch(setPagina({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      dispatch(setPagina(pageProps))
    }
  }, [router.asPath])

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (!loading) {
        // pagina permessa a utente autenticato -> login
        if (loginRequired && !loading && !utente) router.replace(`/login?next=${router.asPath}`)

        // utente social che non ha completato la registrazione
        // if (utente && !utente.completed && !router.asPath.includes('/registrazione/complete')) {
        //  router.push('/registrazione/complete')
        // }
      }
    }, 300)

    return () => clearTimeout(checkUtente)
  }, [loginRequired, utente, loading])

  useEffect(() => {
    if (pageProps?.revalidate)
      handleCheckRevalidate(router.asPath.split('?')?.[0], pageProps.revalidate)
  }, [pageProps?.revalidate])

  const handleCheckRevalidate = async (path, revalidate) => {
    await api.get(CHECK_REVALIDATE, { params: { path, revalidate } })
  }

  return loginRequired ? loading ? <Loader /> : utente ? children : <Loader /> : children
}

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function CustomApp({ Component, pageProps, cookies }: AppProps & CustomAppProps) {
  const router = useRouter()
  const apolloStore = useApollo(pageProps, cookies)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTraduzioni(router.locale, false))
  }, [])

  return (
    <ApolloProvider client={apolloStore}>
      <ChatBotProvider>
        <PageWrapper
          // @ts-ignore
          loginRequired={Component.loginRequired || false}
          pageProps={pageProps}
        >
          <Component {...pageProps} />
        </PageWrapper>
      </ChatBotProvider>
    </ApolloProvider>
  )
}

export default wrapper.withRedux(CustomApp)
