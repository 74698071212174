import React, { ReactNode } from 'react'
import styles from './FormLayout.module.sass'

interface Props {
  space?: 'sm' | 'md'
  flexFrom?: 'sm' | 'md' | 'lg' // mediaquery dalla quale i campi iniziano a essere in flex
  className?: string,
  children?: ReactNode
}

const FormLayout = ({ 
  space = 'md', 
  flexFrom = 'md', 
  className = '', 
  children 
}: Props) => (
  <div className={`${styles.formLayout} ${styles[`formLayout--space-${space}`]} ${styles[`formLayout--flexFrom-${flexFrom}`]} ${className}`}>
    {children}
  </div>
)

export default FormLayout
