import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function MessageError({ className }: Props) {
  return (
    <IconBase name="message-error" w={24} className={className}>
      <path d="M11.1427 2.42782C11.5311 1.78085 12.4689 1.78085 12.8573 2.42782L22.3488 18.2352C22.749 18.9017 22.2689 19.75 21.4915 19.75H2.50853C1.73109 19.75 1.251 18.9017 1.65121 18.2352L11.1427 2.42782Z" stroke="none" fill="white" />
      <path d="M11.1427 2.42782C11.5311 1.78085 12.4689 1.78085 12.8573 2.42782L22.3488 18.2352C22.749 18.9017 22.2689 19.75 21.4915 19.75H2.50853C1.73109 19.75 1.251 18.9017 1.65121 18.2352L11.1427 2.42782Z" stroke="none" fill="currentColor" opacity={.2} />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" fill="currentColor" stroke="none"/>
    </IconBase>
  )
}

export default MessageError
