import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Assistenza({ idVariant = '', className }: Props) {
  return (
    <IconBase name="assistenza" w={32} className={className}>
      <path d="M10.08 13.88C11.1404 13.88 12 13.0204 12 11.96C12 10.8996 11.1404 10.04 10.08 10.04C9.01965 10.04 8.16003 10.8996 8.16003 11.96C8.16003 13.0204 9.01965 13.88 10.08 13.88Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M21.89 13.88C22.9504 13.88 23.81 13.0204 23.81 11.96C23.81 10.8996 22.9504 10.04 21.89 10.04C20.8296 10.04 19.97 10.8996 19.97 11.96C19.97 13.0204 20.8296 13.88 21.89 13.88Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M16 16.66C18.9326 16.66 21.31 14.2826 21.31 11.35C21.31 8.41736 18.9326 6.03999 16 6.03999C13.0674 6.03999 10.69 8.41736 10.69 11.35C10.69 14.2826 13.0674 16.66 16 16.66Z" fill={`url(#paint0_linear_3745_46149_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M9.46002 11.35C9.46002 10.4912 9.62918 9.64072 9.95785 8.84725C10.2865 8.05378 10.7682 7.33281 11.3755 6.72552C11.9828 6.11822 12.7038 5.63649 13.4973 5.30783C14.2907 4.97916 15.1412 4.81 16 4.81C16.8589 4.81 17.7093 4.97916 18.5028 5.30783C19.2962 5.63649 20.0172 6.11822 20.6245 6.72552C21.2318 7.33281 21.7135 8.05378 22.0422 8.84725C22.3709 9.64072 22.54 10.4912 22.54 11.35" stroke="#ED1B2F" strokeLinejoin="round" fill="none"/>
      <path d="M24.24 27.19H7.76001C7.76001 26.1079 7.97314 25.0364 8.38724 24.0367C8.80134 23.037 9.40829 22.1286 10.1734 21.3634C10.9386 20.5983 11.847 19.9913 12.8467 19.5772C13.8464 19.1631 14.9179 18.95 16 18.95C17.0821 18.95 18.1536 19.1631 19.1533 19.5772C20.153 19.9913 21.0614 20.5983 21.8266 21.3634C22.5917 22.1286 23.1987 23.037 23.6128 24.0367C24.0269 25.0364 24.24 26.1079 24.24 27.19V27.19Z" fill={`url(#paint1_linear_3745_46149_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M10.08 13.88H13.3" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3745_46149_${idVariant}`} x1="9.96261" y1="6.518" x2="22.2141" y2="16.3275" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3745_46149_${idVariant}`} x1="6.63124" y1="19.3209" x2="15.3845" y2="33.338" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Assistenza
