import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function AssistenzaGreen({ idVariant = '', className }: Props) {
  return (
    <IconBase name="assistenza-green" w={32} className={className}>
      <path d="M16 27.6201C21.8708 27.6201 26.63 22.8609 26.63 16.9901C26.63 11.1193 21.8708 6.36011 16 6.36011C10.1292 6.36011 5.37 11.1193 5.37 16.9901C5.37 22.8609 10.1292 27.6201 16 27.6201Z" fill={`url(#paint0_linear_4967_100544_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M12.02 22.4703C12.02 22.4703 16.54 20.3103 20.1 22.8003" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15.76 17.4102C15.76 17.4102 16.27 14.6702 13.41 13.9302C13.41 13.9302 12.36 16.5202 15.76 17.4102Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M15.8 21.2802V17.4202" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15.86 17.3202C15.86 17.3202 15 12.6902 19.84 11.4402C19.84 11.4402 21.61 15.8202 15.86 17.3202Z" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_4967_100544_${idVariant}`} x1="3.91383" y1="7.31702" x2="28.4398" y2="26.9544" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default AssistenzaGreen
