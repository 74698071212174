import React from 'react'
import { FormError } from '../'
import styles from './FormToggle.module.sass'

interface Props {
  id: string
  label?: string
  labelPos?: 'left' | 'right'
  name?: string
  value?: string | number | readonly string[]
  className?: string
  checked?: boolean
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormToggle = (props: Props) => {
  const {
    id = '',
    label = '',
    labelPos = 'left',
    name = '',
    value = '',
    className = '',
    checked = false,
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    ${styles.formToggle} 
    ${disabled ? styles['formToggle--disabled'] : ''}
    ${checked ? styles['formToggle--checked'] : ''}
    ${status ? styles[`formToggle--${status}`] : ''}
    ${labelPos ? styles[`formToggle--${labelPos}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
        />
        <span className={styles.styledInput} />
        <span className={styles.label}>{label}</span>
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormToggle
