import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Medico({ idVariant = '', className }: Props) {
  return (
    <IconBase name="medico" w={32} className={className}>
      <path d="M16 26.8301C21.8708 26.8301 26.63 22.0709 26.63 16.2001C26.63 10.3293 21.8708 5.57007 16 5.57007C10.1292 5.57007 5.37 10.3293 5.37 16.2001C5.37 22.0709 10.1292 26.8301 16 26.8301Z" fill={`url(#paint0_linear_3932_47579_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M17.09 10.3901C17.09 10.3901 19.52 10.3901 18.92 12.5101C18.349 14.1348 17.3875 15.5943 16.12 16.7601C15.9758 16.9257 15.795 17.0553 15.5919 17.1387C15.3887 17.222 15.169 17.2568 14.95 17.2401V17.2401C14.7309 17.2584 14.5107 17.2243 14.3073 17.1409C14.1039 17.0575 13.9232 16.927 13.78 16.7601C12.5227 15.5897 11.5686 14.1311 11 12.5101C10.41 10.3701 12.83 10.3901 12.83 10.3901" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M15 17.2402C15 17.2402 15 17.1602 15 19.2402C15 24.9902 21.38 24.9902 21.38 19.2402V17.2402" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M21.33 17.3902C21.6614 17.3902 21.93 17.1216 21.93 16.7902C21.93 16.4588 21.6614 16.1902 21.33 16.1902C20.9986 16.1902 20.73 16.4588 20.73 16.7902C20.73 17.1216 20.9986 17.3902 21.33 17.3902Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.18 10.82C13.4175 10.82 13.61 10.6274 13.61 10.39C13.61 10.1525 13.4175 9.95996 13.18 9.95996C12.9425 9.95996 12.75 10.1525 12.75 10.39C12.75 10.6274 12.9425 10.82 13.18 10.82Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.72 10.82C16.9575 10.82 17.15 10.6274 17.15 10.39C17.15 10.1525 16.9575 9.95996 16.72 9.95996C16.4825 9.95996 16.29 10.1525 16.29 10.39C16.29 10.6274 16.4825 10.82 16.72 10.82Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47579_${idVariant}`} x1="3.91383" y1="6.52698" x2="28.4398" y2="26.1644" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Medico
