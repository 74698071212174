import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function MessageInfo({ className }: Props) {
  return (
    <IconBase name="message-info" w={24} className={className}>
      <circle cx="12" cy="12" r="10" fill="white" stroke="none"/>
      <circle cx="12" cy="12" r="10" fill="currentColor" opacity={.2} stroke="none"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9ZM12 10C11.4477 10 11 10.4477 11 11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V11C13 10.4477 12.5523 10 12 10Z" fill="currentColor" stroke="none"/>
    </IconBase>
  )
}

export default MessageInfo
