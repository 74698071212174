import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxUnmarked({ className }: Props) {
  return (
    <IconBase name="checkbox-unmarked" w={24} className={className}>
      <rect x="3" y="3" width="18" height="18" rx="4" fill="#EFF1F4" stroke="none"/>
    </IconBase>
  )
}

export default CheckboxUnmarked
