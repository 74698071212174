import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function PlusOptional({ className }: Props) {
  return (
    <IconBase name="plus-optional" w={24} className={className}>
      <circle cx="12" cy="12" r="10" stroke="none" fill="#EAE0F2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.112 6.99998C13.112 6.44769 12.6642 5.99998 12.112 5.99998C11.5597 5.99998 11.112 6.44769 11.112 6.99998V11.1119H7C6.44771 11.1119 6 11.5596 6 12.1119C6 12.6642 6.44771 13.1119 7 13.1119H11.112V17.2239C11.112 17.7762 11.5597 18.2239 12.112 18.2239C12.6642 18.2239 13.112 17.7762 13.112 17.2239V13.1119H17.2239C17.7762 13.1119 18.2239 12.6642 18.2239 12.1119C18.2239 11.5596 17.7762 11.1119 17.2239 11.1119H13.112V6.99998Z" stroke="none" fill="#A849D5"/>
    </IconBase>
  )
}

export default PlusOptional

