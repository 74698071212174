import { useEffect, useState } from "react"
const CHATBOT_URL = process.env.CHATBOT_URL;


export default function useChatbot() : any {
    const [chatBotVisible, setChatBotVisible] = useState<boolean>(false)

    const openChatBot = (i : any) => {
        console.log('if chatbotUrl is null bot not visible', i)
        if (i.chatbotUrl) {
          setChatBotVisible(true)
          document.getElementById('xenioowebchat').innerHTML = ""
          xenioowebchat.EmbedChatLanding({
            container: 'xenioowebchat',
            landingUrl: CHATBOT_URL + i.chatbotUrl,
          })
        }
      }


    return {
        openChatBot,
        chatBotVisible,
        setChatBotVisible
    }
}
