import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Sicurezza({ idVariant = '', className }: Props) {
  return (
    <IconBase name="sicurezza" w={32} className={className}>
      <path d="M16 28.32C23.25 26.08 27.73 17.32 27.28 8.45999C23.2206 7.69437 19.3735 6.06413 16 3.67999C12.6265 6.06413 8.77937 7.69437 4.71998 8.45999C4.26998 17.36 8.74998 26.08 16 28.32Z" fill={`url(#paint0_linear_3743_45731_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 25.48C21.58 23.76 25 17.05 24.69 10.2C21.563 9.61032 18.5994 8.3553 16 6.52002C13.4005 8.3553 10.4369 9.61032 7.30996 10.2C6.99996 17.05 10.42 23.76 16 25.48Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45731_${idVariant}`} x1="3.13951" y1="4.78903" x2="30.9429" y2="25.2275" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Sicurezza
