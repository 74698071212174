import React from 'react'
import styles from './VideoEmbed.module.sass'

interface Props {
  embedId: string
  className?: string
}

const VideoEmbed = (props: Props) => {
  const { embedId = '', className = '' } = props

  return (
    <div className={`${styles.videoEmbed} ${className ? ` ${className}` : ''}`}>
      <iframe
        width="1110"
        height="904"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        title="Embedded youtube"
      />
    </div>
  )
}

export default VideoEmbed
