import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Check({ className }: Props) {
  return (
    <IconBase name="check" w={24} className={className} fill="none">
      <path d="M3.5 11.5L9.5 17.5L20.5 6.5" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor"/>
    </IconBase>
  )
}

export default Check
