/* eslint-disable import/prefer-default-export */
import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import createRootReducer from './root-reducer';

const middleware = composeWithDevTools(applyMiddleware(promise, thunk));

const makeStore = () => createStore(createRootReducer, middleware);

export const wrapper = createWrapper(makeStore, { debug: false });

export default makeStore();
