import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Unlock({ className }: Props) {
  return (
    <IconBase name="unlock" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17 8H10V6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6C14 6.55229 14.4477 7 15 7C15.5523 7 16 6.55229 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V8H7C5.34315 8 4 9.34315 4 11V19C4 20.6569 5.34315 22 7 22H17C18.6568 22 20 20.6569 20 19V11C20 9.34315 18.6568 8 17 8ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V11C6 10.4477 6.44772 10 7 10H17C17.5523 10 18 10.4477 18 11V19ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z" stroke="none"/>
    </IconBase>
  )
}

export default Unlock
