import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Banconote({ idVariant = '', className }: Props) {
  return (
    <IconBase name="banconote" w={32} className={className}>
      <path d="M25.7001 9.13H10.26C9.17757 9.13 8.30005 10.0075 8.30005 11.09V16.99C8.30005 18.0725 9.17757 18.95 10.26 18.95H25.7001C26.7825 18.95 27.66 18.0725 27.66 16.99V11.09C27.66 10.0075 26.7825 9.13 25.7001 9.13Z" fill={`url(#paint0_linear_3746_46169_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.56 11.15H8.12003C7.03756 11.15 6.16003 12.0275 6.16003 13.11V19.01C6.16003 20.0925 7.03756 20.97 8.12003 20.97H23.56C24.6425 20.97 25.52 20.0925 25.52 19.01V13.11C25.52 12.0275 24.6425 11.15 23.56 11.15Z" fill={`url(#paint1_linear_3746_46169_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.74 13.05H6.30003C5.21755 13.05 4.34003 13.9276 4.34003 15.01V20.91C4.34003 21.9925 5.21755 22.87 6.30003 22.87H21.74C22.8225 22.87 23.7 21.9925 23.7 20.91V15.01C23.7 13.9276 22.8225 13.05 21.74 13.05Z" fill={`url(#paint2_linear_3746_46169_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5401 19.85C16.1464 20.2438 15.6454 20.5128 15.0996 20.6232C14.5539 20.7336 13.9877 20.6806 13.4719 20.4708C12.9562 20.2609 12.5138 19.9036 12.2001 19.4435C11.8865 18.9835 11.7155 18.4411 11.7087 17.8843C11.7018 17.3276 11.8593 16.7812 12.1615 16.3135C12.4637 15.8458 12.8971 15.4777 13.4075 15.2552C13.918 15.0327 14.4827 14.9657 15.031 15.0626C15.5793 15.1595 16.0868 15.416 16.4901 15.8" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.7401 17.23H15.0501" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.7401 18.47H15.0501" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46169_${idVariant}`} x1="6.97402" y1="9.572" x2="17.4708" y2="26.1415" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3746_46169_${idVariant}`} x1="4.83401" y1="11.592" x2="15.3308" y2="28.1615" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_3746_46169_${idVariant}`} x1="3.014" y1="13.492" x2="13.5108" y2="30.0615" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Banconote
