import React from 'react'
import { Loader as LoaderIcon } from '@next-nx/shared-ui/icons'
import styles from './Loader.module.sass'


interface Props {
  className?: string
}

const Loader = (props: Props) => {

  const { 
    className = '',
  } = props

  return (
    <div className={`${styles.loader} ${className ? ` ${className}` : ''}`}>
      <LoaderIcon />
    </div>
  )
}

export default Loader
