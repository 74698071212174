import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Prodotti({ idVariant = '', className }: Props) {
  return (
    <IconBase name="prodotti" w={32} className={className}>
      <path d="M23.63 16H8.36001C7.09527 16 6.07001 17.0253 6.07001 18.29V24.34C6.07001 25.6047 7.09527 26.63 8.36001 26.63H23.63C24.8947 26.63 25.92 25.6047 25.92 24.34V18.29C25.92 17.0253 24.8947 16 23.63 16Z" fill={`url(#paint0_linear_3746_46160_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.7 13.71V7.65999C16.7 6.39526 15.6747 5.37 14.41 5.37L8.36001 5.37C7.09528 5.37 6.07001 6.39526 6.07001 7.65999V13.71C6.07001 14.9747 7.09528 16 8.36001 16H14.41C15.6747 16 16.7 14.9747 16.7 13.71Z" fill={`url(#paint1_linear_3746_46160_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.9813 15.6186L25.6158 11.984C25.9985 11.6013 25.9985 10.9808 25.6158 10.5981L21.9813 6.96358C21.5986 6.58087 20.9781 6.58087 20.5954 6.96358L16.9608 10.5981C16.5781 10.9808 16.5781 11.6013 16.9608 11.984L20.5954 15.6186C20.9781 16.0013 21.5986 16.0013 21.9813 15.6186Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46160_${idVariant}`} x1="4.71042" y1="16.4785" x2="16.3253" y2="33.8445" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3746_46160_${idVariant}`} x1="16.2216" y1="4.64191" x2="6.40285" y2="16.9049" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Prodotti
