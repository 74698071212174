import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Scudo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="scudo" w={32} className={className}>
      <path d="M26.6998 8.6C23.0998 7.4 19.7998 5.9 16.2998 4.5C13.6998 5.9 8.9998 7.5 6.2998 8.4C6.2998 9 6.2998 9.7 6.2998 10.3C6.2998 10.8 6.2998 11.3 6.2998 11.9C6.2998 17.7 7.2998 23.6 16.2998 27.6C26.9998 23.3 26.6998 17.6 26.6998 11.8C26.6998 11.3 26.6998 10.8 26.6998 10.3C26.6998 9.7 26.6998 9.2 26.6998 8.6Z" fill={`url(#scudo_gradient_${idVariant})`} stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M28.9 7C24.5 5.5 20.5 3.8 16.2 2C13.1 3.7 7.4 5.7 4 6.8C4 7.6 4 8.3 4 9.1C4 9.7 4 10.4 4 11C4 18.1 5.2 25.2 16.2 30.1C29.2 24.9 28.9 17.9 28.9 10.9C28.9 10.3 28.9 9.7 28.9 9.1C28.9 8.3 28.9 7.7 28.9 7Z" stroke="#009597" strokeMiterlimit="10" fill="none"/>
      <defs>
      <linearGradient id={`scudo_gradient_${idVariant}`} x1="25.2434" y1="4.5" x2="7.06172" y2="25.4118" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B2DBDD" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Scudo
