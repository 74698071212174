import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Baffo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="baffo" w={103} h={90} className={className}>
      <g filter={`url(#filter0_d_1475:15798_${idVariant})`}>
      <path d="M79 40.5422C79 40.5422 47.1579 58.251 39.5592 61.0845C32.6842 63.5637 30.1513 60.3761 29.4276 58.9594C27.6184 56.126 24.7237 47.2716 24 45.8549C24 45.8549 25.8092 46.209 30.1513 45.1465C34.8553 44.084 79 20 79 20V40.5422Z" fill="#ED1B2F" stroke="none"/>
      </g>
      <defs>
      <filter id={`filter0_d_1475:15798_${idVariant}`} x="0" y="0" width="103" height="90" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" stroke="none" fill="none"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" stroke="none" fill="none"/>
      <feOffset dy="4" stroke="none" fill="none"/>
      <feGaussianBlur stdDeviation="12" stroke="none" fill="none"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.301961 0 0 0 0 0.345098 0 0 0 0.12 0" stroke="none" fill="none"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1475:15798" stroke="none" fill="none"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1475:15798" result="shape" stroke="none" fill="none"/>
      </filter>
      </defs>
    </IconBase>
  )
}

export default Baffo
