import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Car({ idVariant = '', className }: Props) {
  return (
    <IconBase name="car" w={32} className={className}>
      <path d="M3.5 19.7C3.2 19.7 2.9 19.6 2.7 19.3C2.3 18.8 2 18.3 2 17.6C2 17.5 2.1 15.7 2.3 13.9C3.8 12.1 10.1 10 13.4 10C17.1 10 20.4 11.7 22.9 14C23.3 14.3 23.7 14.6 24.2 14.7C27 15.3 29.2 16.4 29.8 19C29.9 19.4 29.6 19.8 29.2 19.8L3.5 19.7Z" fill={`url(#paint0_linear_1044:14099_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M19.6998 13.8H7.2998C9.0998 12.7 11.8998 11.5 13.7998 11.5C15.9998 11.6 18.3998 12.9 19.6998 13.8Z" fill="white" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 22.5C9.15685 22.5 10.5 21.1569 10.5 19.5C10.5 17.8431 9.15685 16.5 7.5 16.5C5.84315 16.5 4.5 17.8431 4.5 19.5C4.5 21.1569 5.84315 22.5 7.5 22.5Z" fill="#ED1B2F" stroke="white"/>
      <path d="M24.2 22.7999C25.9673 22.7999 27.4 21.3672 27.4 19.5999C27.4 17.8326 25.9673 16.3999 24.2 16.3999C22.4327 16.3999 21 17.8326 21 19.5999C21 21.3672 22.4327 22.7999 24.2 22.7999Z" fill="#ED1B2F" stroke="white"/>
      <defs>
      <linearGradient id={`paint0_linear_1044:14099_${idVariant}`} x1="27.8323" y1="10" x2="23.9058" y2="24.516" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B2DBDD" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Car
