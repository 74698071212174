import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Foglia({ idVariant = '', className }: Props) {
  return (
    <IconBase name="foglia" w={32} className={className}>
      <path d="M5.1 3C3.6 5.4 3 8 3 10.7C3 23.7 13.7 29.6 18.8 29.6C21.7 29.6 22.8 27.1 22.8 24.4C22.8 15.1 11.2 21.6 5.1 3Z" fill={`url(#paint0_linear_1044:14174_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M10.5 20C14.5 24 16 26 29 29" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_1044:14174_${idVariant}`} x1="21.3857" y1="3" x2="0.254179" y2="23.4849" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B2DBDD" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Foglia
