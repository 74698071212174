import React from 'react'
import { Baffo } from '../Icons'
import styles from './IntroTitle.module.sass'

interface Props {
  label?: string
  className?: string
  withIcon?: boolean
}

const IntroTitle = (props: Props) => {
  const { label = '', className = '', withIcon = false } = props

  return label ? (
    <p className={`${styles.introTitle} ${className}`}>
      {!!withIcon && (
        <span className={styles.baffo}>
          <Baffo />
        </span>
      )}
      <span className={styles.label}>{label}</span>
    </p>
  ) : (
    <></>
  )
}

export default IntroTitle
