export function site(
  state = {
    site: process.env.SITE,
  },
  // @ts-ignore
  action
) {
  switch (action.type) {
    case 'SET_SITE': {
      return {
        ...state,
        site: action.payload.data?.site,
      }
    }
    default: {
      return state
    }
  }
}
