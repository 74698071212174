import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Incendio({ idVariant = '', className }: Props) {
  return (
    <IconBase name="incendio" w={32} className={className}>
      <path d="M12.06 20.92C12.06 20.92 4.77998 17.21 10.18 9.91998C10.18 9.91998 9.68998 13.7 11.55 13.78C13.41 13.86 18.33 8.07998 15.38 3.99998C13.53 2.12998 23.19 5.25998 21.27 13.3C19.74 17.3 24.04 16.58 23.63 14.94C23.63 14.94 26.38 18.24 21.57 21.69C16.76 25.14 12.06 20.92 12.06 20.92Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.78 21.36H23.26C23.2882 21.3641 23.317 21.3588 23.3419 21.3449C23.3668 21.331 23.3864 21.3093 23.3978 21.2832C23.4091 21.257 23.4116 21.2279 23.4048 21.2002C23.3979 21.1725 23.3822 21.1479 23.36 21.13L16.69 14.45C16.6778 14.4353 16.6625 14.4235 16.6452 14.4154C16.6279 14.4073 16.6091 14.4031 16.59 14.4031C16.5709 14.4031 16.552 14.4073 16.5347 14.4154C16.5175 14.4235 16.5022 14.4353 16.49 14.45L9.80998 21.12C9.78999 21.1396 9.77632 21.1647 9.77075 21.1921C9.76518 21.2196 9.76795 21.248 9.77871 21.2739C9.78948 21.2997 9.80774 21.3217 9.83114 21.3371C9.85453 21.3524 9.882 21.3604 9.90998 21.36H11.4V27.92C11.4 28.1852 11.5053 28.4396 11.6929 28.6271C11.8804 28.8147 12.1348 28.92 12.4 28.92H20.7C20.9652 28.92 21.2195 28.8147 21.4071 28.6271C21.5946 28.4396 21.7 28.1852 21.7 27.92V21.36H21.78Z" fill={`url(#paint0_linear_3932_47576_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47576_${idVariant}`} x1="8.83366" y1="15.0565" x2="25.324" y2="27.4629" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Incendio
