import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Bin({ idVariant = '', className }: Props) {
  return (
    <IconBase name="bin" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.82339 3.36754C8.09562 2.55086 8.8599 2 9.72076 2H14.2792C15.1401 2 15.9044 2.55086 16.1766 3.36754L16.7208 5H19H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H19.9356L19.1845 18.2661C19.0444 20.3673 17.2992 22 15.1933 22H8.80666C6.7008 22 4.9556 20.3673 4.81552 18.2661L4.06445 7H3C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5H5H7.27924L7.82339 3.36754ZM9.38743 5H14.6126L14.2792 4L9.72076 4L9.38743 5ZM6.06889 7L6.81109 18.133C6.88113 19.1836 7.75373 20 8.80666 20H15.1933C16.2463 20 17.1189 19.1836 17.1889 18.133L17.9311 7H16H8H6.06889Z" fill="#E71E74" stroke="none"/>
    </IconBase>
  )
}

export default Bin
