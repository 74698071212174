import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Family({ idVariant = '', className }: Props) {
  return (
    <IconBase name="family" w={32} className={className}>
      <path d="M21.71 20.82C22.4244 20.2719 23.2778 19.9344 24.1739 19.8457C25.0699 19.7569 25.973 19.9204 26.781 20.3177C27.5891 20.7149 28.27 21.3302 28.7469 22.0939C29.2238 22.8577 29.4778 23.7396 29.48 24.64H22.75" fill={`url(#paint0_linear_3743_45704_${idVariant})`} stroke="none"/>
      <path d="M21.71 20.82C22.4244 20.2719 23.2778 19.9344 24.1739 19.8457C25.0699 19.7569 25.973 19.9204 26.781 20.3177C27.5891 20.7149 28.27 21.3302 28.7469 22.0939C29.2238 22.8577 29.4778 23.7396 29.48 24.64H22.75" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M16.02 16.03C18.4114 16.03 20.35 14.0914 20.35 11.7C20.35 9.3086 18.4114 7.37 16.02 7.37C13.6286 7.37 11.69 9.3086 11.69 11.7C11.69 14.0914 13.6286 16.03 16.02 16.03Z" fill={`url(#paint1_linear_3743_45704_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <path d="M24.74 17.71C26.3913 17.71 27.73 16.3713 27.73 14.72C27.73 13.0686 26.3913 11.73 24.74 11.73C23.0887 11.73 21.75 13.0686 21.75 14.72C21.75 16.3713 23.0887 17.71 24.74 17.71Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M7.33003 17.68C8.98136 17.68 10.32 16.3413 10.32 14.69C10.32 13.0386 8.98136 11.7 7.33003 11.7C5.6787 11.7 4.34003 13.0386 4.34003 14.69C4.34003 16.3413 5.6787 17.68 7.33003 17.68Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinejoin="round"/>
      <path d="M9.29002 24.64H2.52002C2.51972 23.7353 2.77404 22.8487 3.2539 22.0818C3.73376 21.3148 4.41978 20.6984 5.2335 20.303C6.04722 19.9077 6.9558 19.7493 7.85531 19.846C8.75482 19.9427 9.60895 20.2906 10.32 20.85" fill={`url(#paint2_linear_3743_45704_${idVariant})`} stroke="none"/>
      <path d="M9.29002 24.64H2.52002C2.51972 23.7353 2.77404 22.8487 3.2539 22.0818C3.73376 21.3148 4.41978 20.6984 5.2335 20.303C6.04722 19.9077 6.9558 19.7493 7.85531 19.846C8.75482 19.9427 9.60895 20.2906 10.32 20.85" stroke="#009597" strokeLinejoin="round" fill="none"/>
      <path d="M22.75 24.64H9.29004C9.29004 22.8551 9.99909 21.1433 11.2612 19.8812C12.5233 18.6191 14.2351 17.91 16.02 17.91C17.8049 17.91 19.5167 18.6191 20.7789 19.8812C22.041 21.1433 22.75 22.8551 22.75 24.64V24.64Z" fill={`url(#paint3_linear_3743_45704_${idVariant})`} stroke="#009597" strokeLinejoin="round"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45704_${idVariant}`} x1="21.1778" y1="20.039" x2="26.6925" y2="27.1601" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3743_45704_${idVariant}`} x1="11.0969" y1="7.75978" x2="21.0872" y2="15.7588" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint2_linear_3743_45704_${idVariant}`} x1="1.98577" y1="20.0354" x2="7.50047" y2="27.1784" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint3_linear_3743_45704_${idVariant}`} x1="8.36812" y1="18.2129" x2="15.5174" y2="29.6614" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Family
