import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Promo({ idVariant = '', className }: Props) {
  return (
    <IconBase name="promo" w={24} className={className}>
      <g clipPath={`url(#clip0_700_152989_${idVariant})`}>
      <path d="M22.41 12.58L13.41 3.58C13.05 3.22 12.55 3 12 3H5C3.9 3 3 3.9 3 5V12C3 12.55 3.22 13.05 3.59 13.42L12.59 22.42C12.95 22.78 13.45 23 14 23C14.55 23 15.05 22.78 15.41 22.41L22.41 15.41C22.78 15.05 23 14.55 23 14C23 13.45 22.77 12.94 22.41 12.58ZM14 21.01L5 12V5H12V4.99L21 13.99L14 21.01Z" fill="#1B2127" stroke="none"/>
      <path d="M7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z" fill="#1B2127" stroke="none"/>
      </g>
      <defs>
      <clipPath id={`clip0_700_152989_${idVariant}`}>
      <rect width="24" height="24" fill="white" stroke="none"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Promo
