import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Sconti({ idVariant = '', className }: Props) {
  return (
    <IconBase name="sconti" w={32} className={className}>
      <path d="M26.38 13.2601V21.4901C26.38 22.2619 26.0734 23.002 25.5277 23.5478C24.982 24.0935 24.2418 24.4001 23.47 24.4001H13C12.2544 24.3954 11.5388 24.1056 11 23.5901L6.75001 19.4701C6.46843 19.1986 6.24447 18.8732 6.0915 18.5133C5.93852 18.1533 5.85968 17.7662 5.85968 17.3751C5.85968 16.984 5.93852 16.5969 6.0915 16.2369C6.24447 15.877 6.46843 15.5515 6.75001 15.2801L11 11.1701C11.5355 10.6484 12.2524 10.3544 13 10.3501H23.47C24.2418 10.3501 24.982 10.6567 25.5277 11.2024C26.0734 11.7481 26.38 12.4883 26.38 13.2601V13.2601Z" fill={`url(#paint0_linear_3746_46167_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M8.16002 17.06C6.95641 16.1807 6.09989 14.9066 5.74002 13.46C5.45903 12.2908 5.632 11.0584 6.22404 10.0117C6.81608 8.96493 7.78312 8.18171 8.93002 7.82005C10.15 7.51668 11.4327 7.58288 12.615 8.01022C13.7973 8.43756 14.8259 9.20675 15.57 10.22" stroke="#009597" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M22.72 13.16L15.01 21.58" stroke="#ED1B2F" strokeMiterlimit="10" strokeLinecap="round" fill="none"/>
      <path d="M15.66 16.28C16.4498 16.28 17.09 15.5816 17.09 14.72C17.09 13.8585 16.4498 13.16 15.66 13.16C14.8703 13.16 14.23 13.8585 14.23 14.72C14.23 15.5816 14.8703 16.28 15.66 16.28Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M22.05 21.5801C22.8398 21.5801 23.48 20.8816 23.48 20.0201C23.48 19.1585 22.8398 18.4601 22.05 18.4601C21.2602 18.4601 20.62 19.1585 20.62 20.0201C20.62 20.8816 21.2602 21.5801 22.05 21.5801Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M8.27005 17.9001C8.56276 17.9001 8.80005 17.6628 8.80005 17.3701C8.80005 17.0774 8.56276 16.8401 8.27005 16.8401C7.97734 16.8401 7.74005 17.0774 7.74005 17.3701C7.74005 17.6628 7.97734 17.9001 8.27005 17.9001Z" stroke="#009597" strokeMiterlimit="10" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46167_${idVariant}`} x1="4.45418" y1="10.9825" x2="20.8633" y2="30.1715" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Sconti
