import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function AnimaleDomestico({ idVariant = '', className }: Props) {
  return (
    <IconBase name="animale-domestico" w={32} className={className}>
      <path d="M16 26.6301C21.8708 26.6301 26.63 21.8709 26.63 16.0001C26.63 10.1293 21.8708 5.37012 16 5.37012C10.1292 5.37012 5.37 10.1293 5.37 16.0001C5.37 21.8709 10.1292 26.6301 16 26.6301Z" fill={`url(#paint0_linear_3932_47577_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M14.2314 14.3155C15.013 14.2023 15.51 13.1668 15.3414 12.0026C15.1728 10.8383 14.4025 9.98631 13.6209 10.0995C12.8393 10.2127 12.3423 11.2482 12.5109 12.4125C12.6795 13.5767 13.4498 14.4287 14.2314 14.3155Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeMiterlimit="10"/>
      <path d="M11.644 17.313C12.3086 17.0721 12.5805 16.1403 12.2512 15.2316C11.9219 14.3229 11.1162 13.7815 10.4515 14.0224C9.7869 14.2632 9.51505 15.1951 9.84435 16.1038C10.1736 17.0125 10.9794 17.5538 11.644 17.313Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeMiterlimit="10"/>
      <path d="M19.4789 12.4115C19.6475 11.2473 19.1506 10.2117 18.369 10.0985C17.5874 9.98535 16.8171 10.8374 16.6485 12.0016C16.4799 13.1658 16.9768 14.2014 17.7584 14.3146C18.5401 14.4278 19.3104 13.5757 19.4789 12.4115Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeMiterlimit="10"/>
      <path d="M22.1537 16.1085C22.483 15.1998 22.2112 14.268 21.5465 14.0271C20.8819 13.7863 20.0762 14.3276 19.7469 15.2363C19.4176 16.145 19.6894 17.0768 20.3541 17.3177C21.0187 17.5586 21.8244 17.0172 22.1537 16.1085Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeMiterlimit="10"/>
      <path d="M16 21.0902C19.13 23.1902 19.89 21.3602 19.89 20.7002C19.89 20.0402 18.89 16.2002 16 16.2002C13.11 16.2002 12.14 20.0002 12.14 20.7002C12.14 21.4002 12.9 23.1902 16 21.0902Z" fill="#ED1B2F" stroke="#ED1B2F" strokeWidth="0.75" strokeMiterlimit="10"/>
      <defs>
      <linearGradient id={`paint0_linear_3932_47577_${idVariant}`} x1="3.91383" y1="6.32702" x2="28.4398" y2="25.9644" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default AnimaleDomestico
