import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Innovazione({ idVariant = '', className }: Props) {
  return (
    <IconBase name="innovazione" w={32} className={className}>
      <path d="M19.29 19.78V21.78C19.2874 22.2195 19.1117 22.6402 18.8009 22.9509C18.4902 23.2617 18.0695 23.4374 17.63 23.44H14.37C13.9306 23.4374 13.5099 23.2617 13.1991 22.9509C12.8884 22.6402 12.7126 22.2195 12.71 21.78V19.78C11.4173 19.1909 10.3071 18.2646 9.49599 17.0983C8.6849 15.932 8.20288 14.5687 8.10056 13.1518C7.99825 11.7349 8.27941 10.3166 8.91452 9.04582C9.54963 7.77508 10.5152 6.69883 11.7099 5.93014C12.9046 5.16144 14.2842 4.72868 15.7039 4.6773C17.1236 4.62592 18.5309 4.95782 19.7781 5.63814C21.0252 6.31846 22.0661 7.32209 22.7914 8.5436C23.5167 9.76511 23.8996 11.1594 23.9 12.58C23.8761 14.0899 23.4321 15.5632 22.6178 16.8349C21.8036 18.1066 20.6513 19.1264 19.29 19.78V19.78Z" fill={`url(#paint0_linear_3746_46172_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.62 25.37H19.38" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.66 27.3201H18.34" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.07 15.68C17.5557 15.68 18.76 14.4757 18.76 12.99C18.76 11.5044 17.5557 10.3 16.07 10.3C14.5844 10.3 13.38 11.5044 13.38 12.99C13.38 14.4757 14.5844 15.68 16.07 15.68Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.07 9.34998V10.3" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M16.07 15.6801V16.7501" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M19.69 12.99H18.76" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.38 12.99H12.31" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.93 10.81L13.4 10.28" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M17.94 10.81L18.52 10.23" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M17.94 15.17L18.6 15.83" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.93 15.17L13.32 15.78" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46172_${idVariant}`} x1="6.99646" y1="5.51686" x2="27.5736" y2="19.4047" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Innovazione
