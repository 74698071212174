import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Search({ className }: Props) {
  return (
    <IconBase name="search" w={24} className={className}>
      <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M21 21L17 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </IconBase>
  )
}

export default Search
