import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Altro({ idVariant = '', className }: Props) {
  return (
    <IconBase name="altro" w={32} className={className}>
      <path d="M26.47 10.62V25.09C26.47 25.7265 26.2172 26.337 25.7671 26.7871C25.317 27.2372 24.7065 27.49 24.07 27.49H7.93003C7.29351 27.49 6.68306 27.2372 6.23297 26.7871C5.78289 26.337 5.53003 25.7265 5.53003 25.09V6.91001C5.53003 6.27349 5.78289 5.66304 6.23297 5.21295C6.68306 4.76287 7.29351 4.51001 7.93003 4.51001H19.93C20.5199 4.51156 21.0889 4.72848 21.53 5.12001L25.67 8.84001C25.9221 9.06291 26.1238 9.33693 26.2617 9.64385C26.3997 9.95076 26.4707 10.2835 26.47 10.62V10.62Z" fill={`url(#paint0_linear_3746_46162_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.41 19.0001H22.09" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.41 16.5601H22.09" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.41 14.11H22.09" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.41 21.45H22.09" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.41 11.67H22.09" stroke="#009597" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.1 11.87C10.2105 11.87 10.3 11.7805 10.3 11.67C10.3 11.5596 10.2105 11.47 10.1 11.47C9.98957 11.47 9.90002 11.5596 9.90002 11.67C9.90002 11.7805 9.98957 11.87 10.1 11.87Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.1 14.31C10.2105 14.31 10.3 14.2205 10.3 14.11C10.3 13.9996 10.2105 13.91 10.1 13.91C9.98957 13.91 9.90002 13.9996 9.90002 14.11C9.90002 14.2205 9.98957 14.31 10.1 14.31Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.1 16.76C10.2105 16.76 10.3 16.6705 10.3 16.56C10.3 16.4496 10.2105 16.36 10.1 16.36C9.98957 16.36 9.90002 16.4496 9.90002 16.56C9.90002 16.6705 9.98957 16.76 10.1 16.76Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.1 19.2001C10.2105 19.2001 10.3 19.1105 10.3 19C10.3 18.8896 10.2105 18.8 10.1 18.8C9.98957 18.8 9.90002 18.8896 9.90002 19C9.90002 19.1105 9.98957 19.2001 10.1 19.2001Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M10.1 21.6501C10.2105 21.6501 10.3 21.5605 10.3 21.4501C10.3 21.3396 10.2105 21.2501 10.1 21.2501C9.98957 21.2501 9.90002 21.3396 9.90002 21.4501C9.90002 21.5605 9.98957 21.6501 10.1 21.6501Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <defs>
      <linearGradient id={`paint0_linear_3746_46162_${idVariant}`} x1="4.09578" y1="5.54433" x2="29.9673" y2="24.4202" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Altro
