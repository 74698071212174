import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '@next-nx/utils'
import { TRADUZIONI } from '@next-nx/utils/endpoints'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export default function useTrans() {

  const { traduzioni } = useSelector((state: any) => state.traduzioni)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
  }, [traduzioni])

  useEffect(() => {
    mounted.current = true
  }, [])

  const replaceSostituzioni = (value: string, sostituzioni?: string[]) => {
    let result = value
    if (sostituzioni?.length) {
      result = Object.values(sostituzioni).reduce(
        (prev, sost, i) => prev.replace(`{${i}}`, sost),
        value
      )
    }
    return result
  }

  const getTraduzione = (chiave: string, sostituzioni?: string[]) => {

    try {
      // @ts-ignore
      const { valore } = traduzioni.find((t) => t.chiave === chiave)
      return replaceSostituzioni(valore, sostituzioni)
    } catch (error) {
      api
        .post(TRADUZIONI, { chiave })
        .catch((error) => console.log(error))
      return replaceSostituzioni(chiave, sostituzioni)
    }

  }


  return getTraduzione


}
