import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Shield({ className }: Props) {
  return (
    <IconBase name="shield" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21.85C11.6505 21.8555 11.3057 21.7693 11 21.6L10.7 21.43H10.7C5.94271 18.7385 3.00118 13.6959 2.99998 8.23V8.09L2.99998 8.09C2.99352 7.36906 3.37559 6.70045 3.99998 6.34L11 2.4V2.4C11.6188 2.04273 12.3812 2.04273 13 2.4L20 6.33999V6.34C20.6244 6.70045 21.0064 7.36906 21 8.09V8.23V8.23018C20.9946 13.7002 18.0448 18.7438 13.2798 21.4301L12.9798 21.6001C12.68 21.766 12.3425 21.8521 11.9998 21.8501L12 21.85ZM12 4.15L4.99998 8.09V8.23L4.99998 8.22958C5.00325 12.9757 7.56374 17.3517 11.6996 19.6798L11.9996 19.8498L12.2996 19.6798C16.4356 17.352 18.9964 12.9761 19 8.22998V8.08998L12 4.15Z" stroke="none"/>
    </IconBase>
  )
}

export default Shield
