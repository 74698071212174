import React, { useEffect, useRef, useState } from 'react'
import styles from './ProgressBar.module.sass'

export interface Props {
  variant?: 'primary'
  duration: number
  className?: string
}

const ProgressBar = (props: Props) => {
  const { variant = 'primary', duration = 60, className = '' } = props

  const [completed, setCompleted] = useState(0)
  const countRef = useRef<number>(completed)

  useEffect(() => {
    const timer = setInterval(() => {
      if (countRef.current >= 100) {
        clearInterval(timer)
      } else {
        setCompleted((completed) => {
          const newValue = completed >= 100 ? completed : completed + 100 / duration
          countRef.current = newValue
          return newValue
        })
      }
    }, 1000) // 100% in {duration} secondi

    return () => clearInterval(timer)
  }, [])

  const classeNames = `
  ${styles.progressBar}
  ${styles[`progressBar--${variant}`]}
  ${className ? ` ${className}` : ''}`

  return (
    <div className={classeNames}>
      <div className={styles.filler} style={{ width: `${completed}%` }}></div>
    </div>
  )
}

export default ProgressBar
