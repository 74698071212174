import React, { useState } from 'react';
import { FormError } from '../';
import { Check, Error, EyeHide, EyeShow } from '../Icons'
import styles from './FormInputPassword.module.sass'


interface Props {
  placeholder?: string
  name?: string
  value?: string
  size?: 'lg' | 'md'
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error';
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  togglePassword: boolean
}

const FormInputPassword = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = '',
    size = 'lg',
    leftIcon = undefined,
    rightIcon = undefined,
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => undefined,
    onFocus = () => undefined,
    onChange = () => undefined,
    togglePassword = false,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const localRightIcon = togglePassword && !disabled ? undefined : status === 'error' ? <Error /> : status === 'success' ? <Check /> : rightIcon

  const classNames = `
    ${styles.formInputPassword}
    ${styles[`formInputPassword--${size}`]}
    ${status ? styles[`formInputPassword--${status}`] : ''}
    ${togglePassword ? styles["formInputPassword--with-toggler"] : ''}
    ${className || ''}`;

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {!!leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {!!localRightIcon && <span className={styles.rightIcon}>{localRightIcon}</span>}
        {togglePassword && !disabled && (
          <button
            type="button"
            className={styles.toggler}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeHide /> : <EyeShow />}
          </button>
        )}
        <input
          type={showPassword ? 'text' : 'password'}
          name="fakepasswordremembered"
          readOnly={true}
          autoComplete="off"
          className="fakeclasspassword"
        />
        <input
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          autoComplete="off"
        />
      </div>
      <FormError message={errorMessage} visible={status === 'error'} className={styles.error} />
    </div>
  );
};

export default FormInputPassword;
