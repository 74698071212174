import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Modello({ idVariant = '', className }: Props) {
  return (
    <IconBase name="modello" w={32} className={className}>
      <path d="M14.42 25.16C12.6704 21.735 9.95506 18.898 6.60996 17C4.60996 15.15 4.53996 13.66 7.34996 12.33C10.0481 11.011 13.0117 10.3254 16.015 10.3254C19.0182 10.3254 21.9818 11.011 24.68 12.33C27.42 13.84 28.11 15.33 25.51 16.97C22.1043 18.8946 19.3502 21.7915 17.6 25.29C16.18 28.38 15.26 27.18 14.42 25.16Z" fill={`url(#paint0_linear_4139_56565_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M16 18.1099C17.1653 18.1099 18.11 17.1652 18.11 15.9999C18.11 14.8346 17.1653 13.8899 16 13.8899C14.8346 13.8899 13.89 14.8346 13.89 15.9999C13.89 17.1652 14.8346 18.1099 16 18.1099Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M16 4.0001C13.6146 4.00012 11.2829 4.70812 9.30019 6.03442C7.3175 7.36071 5.77307 9.24563 4.86252 11.4504C3.95197 13.6552 3.71628 16.0806 4.1853 18.4194C4.65432 20.7582 5.80695 22.9052 7.49717 24.5884C9.1874 26.2717 11.3392 27.4153 13.6799 27.8746C16.0207 28.3339 18.4451 28.0881 20.6461 27.1684C22.847 26.2487 24.7255 24.6965 26.0435 22.7083C27.3616 20.7201 28.0599 18.3855 28.05 16.0001C28.05 14.42 27.7379 12.8555 27.1318 11.3963C26.5256 9.93715 25.6372 8.61206 24.5176 7.4971C23.398 6.38214 22.0692 5.49927 20.6075 4.89916C19.1458 4.29905 17.58 3.99352 16 4.0001ZM16 25.2101C14.1904 25.2101 12.4214 24.6741 10.9162 23.6698C9.41094 22.6655 8.23687 21.2378 7.54213 19.5669C6.84738 17.8961 6.66309 16.0569 7.0125 14.2814C7.3619 12.5059 8.22936 10.8737 9.50541 9.59066C10.7815 8.30764 12.4089 7.43132 14.1825 7.07225C15.9561 6.71318 17.7962 6.88746 19.4708 7.5731C21.1455 8.25874 22.5795 9.42501 23.592 10.9248C24.6045 12.4245 25.1501 14.1906 25.16 16.0001C25.16 18.4295 24.1949 20.7594 22.4771 22.4772C20.7592 24.195 18.4294 25.1601 16 25.1601V25.2101Z" fill={`url(#paint1_linear_4139_56565_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M29 21.9247V28.8512C29 29.1559 28.8792 29.4481 28.6643 29.6635C28.4494 29.879 28.1578 30 27.8539 30H20.1461C19.8422 30 19.5506 29.879 19.3357 29.6635C19.1208 29.4481 19 29.1559 19 28.8512V20.1488C19 19.8441 19.1208 19.5519 19.3357 19.3365C19.5506 19.121 19.8422 19 20.1461 19H25.8768C26.1585 19.0007 26.4302 19.1046 26.6409 19.292L28.618 21.0727C28.7383 21.1794 28.8347 21.3105 28.9005 21.4575C28.9664 21.6044 29.0003 21.7636 29 21.9247Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.3924 20.6902C23.5636 20.9069 23.5266 21.2213 23.3099 21.3924L22.0434 22.3924C21.8618 22.5359 21.6054 22.5359 21.4237 22.3924L20.6902 21.8132C20.4734 21.6421 20.4365 21.3277 20.6076 21.1109C20.7787 20.8942 21.0932 20.8572 21.3099 21.0284L21.7336 21.3629L22.6902 20.6076C22.9069 20.4365 23.2213 20.4734 23.3924 20.6902ZM23.3924 23.6902C23.5636 23.9069 23.5266 24.2213 23.3099 24.3924L22.0434 25.3924C21.8618 25.5359 21.6054 25.5359 21.4237 25.3924L20.6902 24.8132C20.4734 24.6421 20.4365 24.3277 20.6076 24.1109C20.7787 23.8942 21.0932 23.8572 21.3099 24.0284L21.7336 24.3629L22.6902 23.6076C22.9069 23.4365 23.2213 23.4734 23.3924 23.6902ZM23.3099 27.3924C23.5266 27.2213 23.5636 26.9069 23.3924 26.6902C23.2213 26.4734 22.9069 26.4365 22.6902 26.6076L21.7336 27.3629L21.3099 27.0284C21.0932 26.8572 20.7787 26.8942 20.6076 27.1109C20.4365 27.3277 20.4734 27.6421 20.6902 27.8132L21.4237 28.3924C21.6054 28.5359 21.8618 28.5359 22.0434 28.3924L23.3099 27.3924Z" fill="white" stroke="none"/>
      <defs>
      <linearGradient id={`paint0_linear_4139_56565_${idVariant}`} x1="3.66261" y1="11.0837" x2="23.5617" y2="31.8612" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_4139_56565_${idVariant}`} x1="2.29939" y1="5.08474" x2="30.1017" y2="27.3454" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Modello
