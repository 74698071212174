import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Veicoli({ idVariant = '', className }: Props) {
  return (
    <IconBase name="veicoli" w={32} className={className}>
      <path d="M14.42 25.16C12.6704 21.735 9.95506 18.898 6.60996 17C4.60996 15.15 4.53996 13.66 7.34996 12.33C10.0481 11.011 13.0117 10.3254 16.015 10.3254C19.0182 10.3254 21.9818 11.011 24.68 12.33C27.42 13.84 28.11 15.33 25.51 16.97C22.1043 18.8946 19.3502 21.7915 17.6 25.29C16.18 28.38 15.26 27.18 14.42 25.16Z" fill={`url(#paint0_linear_3743_45711_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M16 18.11C17.1653 18.11 18.11 17.1653 18.11 16C18.11 14.8347 17.1653 13.89 16 13.89C14.8346 13.89 13.89 14.8347 13.89 16C13.89 17.1653 14.8346 18.11 16 18.11Z" fill="#ED1B2F" stroke="#ED1B2F" strokeMiterlimit="10"/>
      <path d="M16 3.99998C13.6146 4 11.2829 4.708 9.30019 6.0343C7.3175 7.36059 5.77307 9.24551 4.86252 11.4503C3.95197 13.655 3.71628 16.0804 4.1853 18.4193C4.65432 20.7581 5.80695 22.9051 7.49717 24.5883C9.1874 26.2715 11.3392 27.4152 13.6799 27.8745C16.0207 28.3338 18.4451 28.088 20.6461 27.1683C22.847 26.2486 24.7255 24.6964 26.0435 22.7082C27.3616 20.72 28.0599 18.3854 28.05 16C28.05 14.4199 27.7379 12.8554 27.1318 11.3962C26.5256 9.93703 25.6372 8.61193 24.5176 7.49697C23.398 6.38202 22.0692 5.49915 20.6075 4.89904C19.1458 4.29893 17.58 3.9934 16 3.99998V3.99998ZM16 25.21C14.1904 25.21 12.4214 24.674 10.9162 23.6696C9.41094 22.6653 8.23687 21.2377 7.54213 19.5668C6.84738 17.896 6.66309 16.0568 7.0125 14.2813C7.3619 12.5058 8.22936 10.8736 9.50541 9.59054C10.7815 8.30752 12.4089 7.43119 14.1825 7.07213C15.9561 6.71306 17.7962 6.88734 19.4708 7.57298C21.1455 8.25862 22.5795 9.42489 23.592 10.9247C24.6045 12.4244 25.1501 14.1905 25.16 16C25.16 18.4294 24.1949 20.7592 22.4771 22.4771C20.7592 24.1949 18.4294 25.16 16 25.16V25.21Z" fill={`url(#paint1_linear_3743_45711_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <defs>
      <linearGradient id={`paint0_linear_3743_45711_${idVariant}`} x1="3.66261" y1="11.0837" x2="23.5617" y2="31.8612" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_3743_45711_${idVariant}`} x1="2.29939" y1="5.08462" x2="30.1017" y2="27.3453" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stroke="none" fill="none"/>
      <stop offset="0.21" stopColor="#F6FBFB" stroke="none" fill="none"/>
      <stop offset="0.56" stopColor="#DCEFF0" stroke="none" fill="none"/>
      <stop offset="1" stopColor="#B4DCDE" stroke="none" fill="none"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Veicoli
