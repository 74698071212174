import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function Filters({ className }: Props) {
  return (
    <IconBase name="filters" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.18 7H3C2.44772 7 2 6.55228 2 6C2 5.44771 2.44772 5 3 5H4.18C4.48319 4.16465 5.14113 3.50671 5.97648 3.20352C7.53392 2.63825 9.25472 3.44256 9.82 5H21C21.5523 5 22 5.44771 22 6C22 6.55228 21.5523 7 21 7H9.82C9.51681 7.83535 8.85887 8.49329 8.02352 8.79648C6.46608 9.36175 4.74528 8.55744 4.18 7ZM8 6C8 5.44771 7.55229 5 7 5C6.44771 5 6 5.44771 6 6C6 6.55228 6.44771 7 7 7C7.55229 7 8 6.55228 8 6ZM16.18 11C16.6028 9.80426 17.7318 9.00355 19 8.99999C20.2744 8.99642 21.412 9.79842 21.8368 11C22.3891 12.5621 21.5705 14.2761 20.0084 14.8284C18.4463 15.3807 16.7323 14.5621 16.18 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H16.18ZM18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12ZM21 17H13.82C13.2547 15.4426 11.5339 14.6382 9.97648 15.2035C9.14113 15.5067 8.48319 16.1646 8.18 17H3C2.44771 17 2 17.4477 2 18C2 18.5523 2.44771 19 3 19H8.18C8.74528 20.5574 10.4661 21.3617 12.0235 20.7965C12.8589 20.4933 13.5168 19.8353 13.82 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17ZM11 19C10.4477 19 10 18.5523 10 18C10 17.4477 10.4477 17 11 17C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19Z" stroke="none"/>
    </IconBase>
  )
}

export default Filters
