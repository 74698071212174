import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronLeft({ className }: Props) {
  return (
    <IconBase name="chevron-left" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.9419 18.7682C15.3662 18.4147 15.4235 17.7841 15.07 17.3598L10.6034 12L15.07 6.64021C15.4235 6.21594 15.3662 5.58537 14.9419 5.23181C14.5176 4.87824 13.8871 4.93557 13.5335 5.35984L8.00002 12L13.5335 18.6402C13.8871 19.0645 14.5176 19.1218 14.9419 18.7682Z" stroke="none"/>
    </IconBase>
  )
}

export default ChevronLeft
