/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import { menu, pagina, site, traduzioni, utente } from '@next-nx/shared-containers'

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      };
      return nextState;

    default:
      return combineReducers({
        menu,
        pagina,
        site,
        traduzioni,
        utente,
      })(state, action)
  }
}

export default rootReducer