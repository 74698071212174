import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function LogoCaMono({ className }: Props) {
  return (
    <IconBase name="logo-ca-mono" w={409.19} h={46.16} className={className}>
      <style jsx>{`
        .st0 {
          fill: #ffffff;
        }
      `}</style>
      <g>
        <path
          className="st0"
          d="M95,14.34c3.18,0,6.48,0.97,9.19,2.91l-2.06,2.68c-2.21-1.53-4.06-2.39-6.86-2.39c-4.86,0-8.66,3.03-8.66,7.45
		s3.8,7.45,8.66,7.45c2.95,0,4.89-0.91,7.24-2.5l2.06,2.68c-2.77,1.97-6.24,3.03-9.57,3.03c-6.69,0-12.99-3.95-12.99-10.69
		C82.02,18.23,88.32,14.34,95,14.34z"
        />
        <path
          className="st0"
          d="M108.96,14.69h11.16c5.18,0,9.39,2.09,9.39,6.63c0,3.53-2.59,5.39-6.1,6.18l6.74,7.8h-5.15l-6.13-7.19h-5.51
		v7.19h-4.42V14.69z M119.8,24.91c3.12,0,5.12-1.3,5.12-3.53c0-2.18-1.91-3.39-5.15-3.39h-6.39v6.92H119.8z"
        />
        <path
          className="st0"
          d="M134.4,29.36V14.69h18.67v3.3h-14.25v4.98h13.08v3.21h-13.08v3.18c0,1.8,0.85,2.65,2.59,2.65h12.07v3.3h-13.13
		C136.58,35.31,134.4,33.1,134.4,29.36z M148.62,8.8l-3.42,3.62h-3.77l2.59-3.62H148.62z"
        />
        <path
          className="st0"
          d="M158.75,14.69h9.31c7.95,0,13.25,4.12,13.25,10.31c0,6.18-5.3,10.31-13.25,10.31h-9.31V14.69z M168.35,32.01
		c5.01,0,8.36-2.8,8.36-6.98c0-4.21-3.36-7.04-8.36-7.04h-5.18v14.02H168.35z"
        />
        <path className="st0" d="M185.99,14.69h4.42v20.62h-4.42V14.69z" />
        <path className="st0" d="M194.79,17.99v-3.3h20.26v3.3h-7.92v17.32h-4.42V17.99H194.79z" />
        <path
          className="st0"
          d="M242.17,30.33h-11.37l-2.47,4.98h-4.51l10.69-20.62h4.51l10.69,20.62h-5.07L242.17,30.33z M240.55,27.12
		l-4.06-8.19l-4.06,8.19H240.55z"
        />
        <path
          className="st0"
          d="M263.99,14.34c3.18,0,6.48,0.97,9.19,2.91l-2.06,2.68c-2.21-1.53-4.06-2.39-6.86-2.39
		c-4.86,0-8.66,2.97-8.66,7.39c0,4.36,3.39,7.51,8.51,7.51c2.27,0,4.36-0.62,5.83-1.59v-3.95h-6.21v-3.15h10.63v8.66
		c-2.47,1.77-5.98,3.24-10.42,3.24c-7.77,0-12.93-4.59-12.93-10.69C251,18.23,257.31,14.34,263.99,14.34z"
        />
        <path
          className="st0"
          d="M279.92,14.69h11.16c5.18,0,9.39,2.09,9.39,6.63c0,3.53-2.59,5.39-6.1,6.18l6.74,7.8h-5.15l-6.13-7.19h-5.51
		v7.19h-4.42V14.69z M290.76,24.91c3.12,0,5.12-1.3,5.12-3.53c0-2.18-1.91-3.39-5.15-3.39h-6.39v6.92H290.76z"
        />
        <path className="st0" d="M306.1,14.69h4.42v20.62h-4.42V14.69z" />
        <path
          className="st0"
          d="M328.48,14.34c3.18,0,6.48,0.97,9.19,2.91l-2.06,2.68c-2.21-1.53-4.06-2.39-6.86-2.39
		c-4.86,0-8.66,3.03-8.66,7.45s3.8,7.45,8.66,7.45c2.94,0,4.89-0.91,7.24-2.5l2.06,2.68c-2.77,1.97-6.24,3.03-9.57,3.03
		c-6.68,0-12.99-3.95-12.99-10.69C315.49,18.23,321.79,14.34,328.48,14.34z"
        />
        <path
          className="st0"
          d="M339.64,25c0-5.83,5.42-10.66,13.05-10.66c7.63,0,13.05,4.83,13.05,10.66c0,5.83-5.42,10.66-13.05,10.66
		C345.06,35.66,339.64,30.83,339.64,25z M344.23,25c0,4.15,3.45,7.45,8.45,7.45c5.01,0,8.45-3.3,8.45-7.45
		c0-4.15-3.45-7.45-8.45-7.45C347.68,17.55,344.23,20.85,344.23,25z"
        />
        <path className="st0" d="M369.82,14.69h4.42v17.32h13.1v3.3h-17.52V14.69z" />
        <path
          className="st0"
          d="M390.11,29.36V14.69h18.67v3.3h-14.25v4.98h13.08v3.21h-13.08v3.18c0,1.8,0.85,2.65,2.59,2.65h12.07v3.3
		h-13.13C392.29,35.31,390.11,33.1,390.11,29.36z"
        />
      </g>
      <g>
        <rect x="0.67" y="40.72" className="st0" width="60.77" height="5.44" />
        <g>
          <path
            className="st0"
            d="M39.31,11.14l4.1,10.27l7.25-3.81L44.38,3.69h-9.13L22.43,27.61c0.01,0,6.31-0.19,10.35-3.24
			C33.8,23.78,36.67,16.22,39.31,11.14L39.31,11.14L39.31,11.14z"
          />
          <path
            className="st0"
            d="M50.66,17.6l-7.25,3.81c-6.2,3.17-12.09,5.96-15.26,6.86c-8.37,2.41-18.56,3.85-22.22,0.61
			c-3.44-3.06,4.51-15.85,19.54-18.7c2.44-0.54,3.86-0.4,3.86-0.39c0-0.02-1.03-4.38-1.83-5.29c-1.12-1.37-2.22-1.65-3.86-1.45
			C20.4,3.24,6.55,6.93,1.09,18.47c-3.05,6.55,1.01,13.17,3.63,15.09c1.22,0.89,9.08,7.94,27.26,0.81
			c17.92-6.95,33.96-17.9,33.98-17.9V9.15C65.95,9.14,58.87,13.2,50.66,17.6z"
          />
          <path
            className="st0"
            d="M45.96,28.08c0.67,1.57,2.6,6.16,3.09,7.48C49.69,37,51.29,37,51.29,37h8.36l-6.22-12.9
			C51.15,25.37,48.63,26.72,45.96,28.08z"
          />
        </g>
        <path
          className="st0"
          d="M65.95,6.3c-0.01,0-9.56,5.48-11.8,6.32c-2.06,0.81-2.83-0.26-3.08-0.64c-0.55-0.84-1.46-3.63-1.59-4.05
		c0.06,0.01,0.58,0.12,1.8-0.19C52.75,7.37,65.94,0,65.95,0V6.3L65.95,6.3z"
        />
      </g>
    </IconBase>
  )
}

export default LogoCaMono
