import React from 'react'
import styles from './MainDescription.module.sass'


interface Props {
  html?: string
  className?: string
}

const MainDescription = (props: Props) => {

  const { 
    html = '',
    className = '',
  } = props

  return html ? (
    <div className={`${styles.mainDescription} ${className ? ` ${className}` : ''}`} dangerouslySetInnerHTML={{ __html: html }} />
  ) : <></>
}

export default MainDescription
