import React from 'react'
import Link from 'next/link'
import styles from './AssistenzaButton.module.sass'
import { ArrowRight } from '@next-nx/shared-ui/icons'

interface Props {
  label: string
  icon?: React.ReactElement | null
  newPage?: boolean
  url?: string
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>
  ) => void
  className?: string
}

const AssistenzaButton = (props: Props) => {
  const {
    url = '',
    label = '',
    icon = undefined,
    newPage = false,
    onClick = undefined,
    className = '',
  } = props

  const content = (
    <>
      {!!icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
      <span className={styles.arrow}>
        <ArrowRight />
      </span>
    </>
  )

  return !url && onClick ? (
    <button type="button" className={`${styles.assistenzaButton} ${className}`} onClick={onClick}>
      {content}
    </button>
  ) : (
    <Link legacyBehavior href={url}>
      <a
        className={`${styles.assistenzaButton} ${className}`}
        rel="noopener noreferrer"
        target={newPage ? '_blank' : undefined}
        onClick={onClick}
      >
        {content}
      </a>
    </Link>
  )
}

export default AssistenzaButton
