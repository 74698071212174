import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronRight({ className }: Props) {
  return (
    <IconBase name="chevron-right" w={20} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.25751 16.1826C6.90394 15.8879 6.85617 15.3625 7.15081 15.0089L10.8729 10.5424L7.15081 6.07588C6.85617 5.72231 6.90394 5.19684 7.25751 4.90221C7.61107 4.60757 8.13654 4.65534 8.43118 5.00891L13.0424 10.5424L8.43118 16.0759C8.13654 16.4294 7.61107 16.4772 7.25751 16.1826Z" stroke="none"/>
    </IconBase>
  )
}

export default ChevronRight
