import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function FurtoIncendio({ idVariant = '', className }: Props) {
  return (
    <IconBase name="furto-incendio" w={32} className={className}>
      <path d="M18.16 14.3699C18.16 14.3699 13.48 11.9799 16.95 7.32988C16.95 7.32988 16.63 9.75988 17.83 9.81988C19.03 9.87988 22.19 6.11988 20.29 3.46988C19.1 2.25988 25.31 4.27988 24.08 9.44988C23.1 12.0099 25.86 11.5599 25.6 10.5099C25.6 10.5099 27.37 12.6299 24.27 14.8499C21.18 17.0699 18.15 14.3799 18.15 14.3799L18.16 14.3699Z" fill="#ED1B2F" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.41 14.6499H25.36C25.44 14.6499 25.48 14.5499 25.42 14.4899L21.13 10.1999C21.13 10.1999 21.04 10.1699 21 10.1999L16.7 14.4999C16.64 14.5599 16.68 14.6499 16.76 14.6499H17.72V14.6799C17.72 14.6799 17.72 14.7099 17.72 14.7199V18.8699C17.72 19.2399 18.02 19.5399 18.39 19.5399H23.73C24.1 19.5399 24.4 19.2399 24.4 18.8699V14.6499H24.41Z" fill={`url(#paint0_linear_4967_100278_${idVariant})`} stroke="#009597" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.04 28.9699C18.3313 28.9699 21.81 25.4912 21.81 21.1999C21.81 16.9087 18.3313 13.4299 14.04 13.4299C9.74877 13.4299 6.27002 16.9087 6.27002 21.1999C6.27002 25.4912 9.74877 28.9699 14.04 28.9699Z" fill={`url(#paint1_linear_4967_100278_${idVariant})`} stroke="#009597" strokeMiterlimit="10"/>
      <path d="M19.77 19.5398C19.77 19.5398 20.08 22.0198 18.3 23.7898L14.05 21.9298L19.77 19.5298V19.5398Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.15 21.9398L8.42999 19.5398C8.42999 19.5398 8.11999 22.0198 9.89999 23.7898L14.15 21.9298V21.9398Z" stroke="#ED1B2F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear_4967_100278" x1="16.0699" y1="10.6075" x2="26.6911" y2="18.5883" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      <linearGradient id={`paint1_linear_4967_100278_${idVariant}`} x1="5.20564" y1="14.1294" x2="23.1329" y2="28.4834" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F6FBFB"/>
      <stop offset="0.56" stopColor="#DCEFF0"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default FurtoIncendio
