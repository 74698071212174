import React from 'react'
import IconBase from '../IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Catastrofe({ idVariant = '', className }: Props) {
  return (
    <IconBase name="catastrofe" w={32} className={className}>
      <path d="M12.68 26.6699V28.9699" stroke="#F01526" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M19.89 26.6699V28.9699" stroke="#F01526" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M9.41998 21.3301V23.6301" stroke="#F01526" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.29 21.3301V23.6301" stroke="#F01526" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M23.16 21.3301V23.6301" stroke="#F01526" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M7.73997 17.43C5.65997 17.43 3.96997 15.74 3.96997 13.66C3.96997 11.58 5.51997 10.02 7.50997 9.90003C7.64997 9.90003 7.78997 9.82003 7.87997 9.71003C7.96997 9.60003 7.99997 9.45003 7.96997 9.31003C7.90997 8.99003 7.86997 8.67003 7.86997 8.35003C7.86997 5.69003 10.03 3.53003 12.69 3.53003C14.53 3.53003 16.24 4.61003 17.04 6.27003C17.1 6.39003 17.2 6.48003 17.33 6.53003C17.38 6.55003 17.44 6.56003 17.49 6.56003C17.57 6.56003 17.64 6.54003 17.71 6.51003C18.38 6.18003 19.09 6.01003 19.84 6.01003C22.02 6.01003 23.93 7.48003 24.49 9.59003C24.54 9.78003 24.7 9.92003 24.89 9.95003C26.68 10.25 28.02 11.85 28.02 13.66C28.02 15.74 26.33 17.43 24.25 17.43H7.73997Z" fill={`url(#paint0_linear_4885_96060_${idVariant})`} />
      <path d="M12.69 4.03001C14.34 4.03001 15.87 4.99001 16.59 6.49001C16.71 6.73001 16.91 6.92001 17.17 7.00001C17.28 7.04001 17.39 7.05001 17.5 7.05001C17.65 7.05001 17.8 7.02001 17.94 6.95001C18.54 6.65001 19.18 6.50001 19.85 6.50001C21.8 6.50001 23.52 7.82001 24.02 9.70001C24.12 10.08 24.43 10.36 24.82 10.43C26.37 10.7 27.54 12.08 27.54 13.65C27.54 15.45 26.07 16.92 24.27 16.92H7.73997C5.93997 16.92 4.46997 15.45 4.46997 13.65C4.46997 11.85 5.81997 10.49 7.53997 10.39C7.82997 10.37 8.09997 10.23 8.26997 10C8.44997 9.77001 8.50997 9.48001 8.45997 9.19001C8.39997 8.91001 8.36997 8.62001 8.36997 8.33001C8.36997 5.95001 10.31 4.01001 12.69 4.01001M12.69 3.01001C9.74997 3.01001 7.36997 5.39001 7.36997 8.33001C7.36997 8.69001 7.40997 9.04001 7.47997 9.39001C5.24997 9.53001 3.46997 11.39 3.46997 13.65C3.46997 15.91 5.38997 17.92 7.73997 17.92H24.26C26.61 17.92 28.53 16 28.53 13.65C28.53 11.55 26.99 9.79001 24.98 9.44001C24.38 7.17001 22.31 5.49001 19.84 5.49001C18.99 5.49001 18.2 5.69001 17.49 6.04001C16.63 4.25001 14.81 3.01001 12.69 3.01001Z" fill="#009598"/>
      <defs>
      <linearGradient id={`paint0_linear_4885_96060_${idVariant}`} x1="4.49997" y1="6.40003" x2="26.23" y2="18.65" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="0.21" stopColor="#F5FAFA"/>
      <stop offset="0.56" stopColor="#DCEEEF"/>
      <stop offset="1" stopColor="#B4DCDE"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default Catastrofe
