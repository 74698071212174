import React from 'react';
import IconBase from '../IconBase/IconBase';

interface Props {
  className?: string;
}

function ArrowRight({ className }: Props) {
  return (
    <IconBase name="arrow-right" w={24} className={className}>
      <line
        x1="5"
        y1="11.5"
        x2="19"
        y2="11.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 4L20 11.5L12.5 19"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
}

export default ArrowRight;
